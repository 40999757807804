import * as React from "react";
import { SimpleShowLayout,TextField } from "react-admin";
import CurrencyField from "../../../components/CurrencyField";
import PercentField from "../../../components/PercentField";

const Price = (props) =>{

    return(
        <SimpleShowLayout {...props}>
            <CurrencyField source="vitalDrugsPrice" label="Цена ЖНВЛП без НДС" />
            <PercentField source="vatAmount" label="% НДС" />
            <CurrencyField source="purchasingPriceWithMarkupRatio" label="Закупочная цена с НДС (до вычета КН)" />
            <CurrencyField source="purchasingPriceWithoutMarkupRatio" label="Закупочная цена с НДС (после вычета КН)" />
            <TextField source="markupRatio" label="Размер КН, %" />
            <CurrencyField source="planningSellingPrice" label="Плановая продажная цена с НДС" />
            <PercentField source="planningProfitability" label="Плановая рентабельность, %" />
            <CurrencyField source="minPrice" label="Мин. цена дна с НДС" />
            <PercentField source="minPriceProfitability" label="Плановая рентабель-ность при min цене, %" />
        </SimpleShowLayout>
    );
};

export default Price;