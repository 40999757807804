const baseUrl = "/api";
const authUrl = baseUrl + "/account/signin";
const logoutUrl = baseUrl + "/account/signout";

export const config = {
  baseUrl: baseUrl,
  authUrl: authUrl,
  logoutUrl: logoutUrl,
  captchaKey: '6LcIRT8fAAAAAPKxdD5hHf-GnUZvlk-QjNe91-rG',
}

export const entities = {
  areasOfResponsibility: "areasOfResponsibility",
  areasOfResponsibilityCustomers: "areasOfResponsibilityCustomers",
  areasOfResponsibilityRegions: "areasOfResponsibilityRegions",
  areasOfResponsibilityUsers: "areasOfResponsibilityUsers",
  nomenclatures: "nomenclatures",
  nomenclatureSkuMaps: "nomenclatureSkuMaps",
  skus: "skus",
  skuPriceAdditionalContracts: "skuPriceAdditionalContracts",
  regions: "regions",
  federalDistricts: "federalDistricts",
  customers: "customers",
  users: "users",
  inns: "inns",
  suppliers: "suppliers",
  lots: "lots",
  orders: "purchaseOrders",
  ordersOnApprove: "purchaseOrdersApproval",
  skuPrices: "skuPrices",
  logisticsPrice: "logisticsPrice",
  skuCustomersRelevance: "skuCustomersRelevance",
  skuRegionsRelevance: "skuRegionsRelevance",
  maxWholesaleMarkups: "maxWholesaleMarkups",
  producers: "producers",
  lotPriceApprovals: "lotPriceApprovals",
  supplierEmails: "supplierEmails",
  commercialOffers: "commercialOffers",
  commercialOfferLotsPrices: "commercialOfferLotsPrices",
  pricesApprovals: "pricesApprovals",
  stockBalance: "stockBalance",
  skuActualityPeriod: "skuActualityPeriod",
  historyOperation: "historyOperation",
  historyChanges: "historyChanges",
  accountingSuppliers: "accountingSuppliers",
  accountingProducers: "accountingProducers",
  persistentLog: "persistentLog",
  dictionaryImport : "dictionaryImport",
  xlsConfigs : "xlsConfigs",
  skuProducers: "skuProducers",
  reports: "reports",
  reportVersionsQuery: "reportVersions/query",
  reportVersions: "reportVersions",
};
