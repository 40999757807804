import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const WrapperTooltip = ({text, label}) =>{
    return(
      <div>
        <Tooltip title={text} placement="top-start">
          <div>{label}</div>
        </Tooltip>
      </div>
    );
};  

export default WrapperTooltip;