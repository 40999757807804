import * as React from 'react';
import { TextField as RaTextField } from 'react-admin';
import LabeledField from './LabeledField';

const TextField = (props) => {    
    return  <LabeledField {...props}>
                <RaTextField />
            </LabeledField>;
};

export default TextField;
