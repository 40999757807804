import {
    DosageUnit,
    PurchaseOrderPriceApprovalStatus,
    PurchaseOrderFinalStatus as PurchaseOrderResultStatus,
    MaxMarkupType,
    LotPriceApprovalStatus,
    PurchaseOrderInitialStatus as PurchaseOrderProcessingStatus,
    NomenclatureState,
    MappedAreaStatus,
    PurchaseOrderLotType,
    PurchaseOrderPriceApprovalCommonStatus,
    IsActualStatus,
    LotResultStatus,
    LotType,
    YesOrNoStatus,
    RolesNumber,
    PurchaseOrderPriceApprovalStatusHistoryChange,
    PurchaseOrderStatusHistoryChange,
    CommercialOfferType,
    OrderManagerStatus
} from "./enums";

export const RolesChoices = [
    { id: RolesNumber.Admin, name: "Администратор" },
    { id: RolesNumber.Manager, name: "КАМ" },
    { id: RolesNumber.FederalManager, name: "Федеральный менеджер" },
    { id: RolesNumber.Observer, name: "Офис" },
];

export const IsVeDrugChoices = [
    { id: true, name: "ЖНВЛП" }, 
    { id: false, name: "Не ЖНВЛП" }
];

export const LotTypeChoices = [
    { id: LotType.MonoLot, name: "Монолот" },
    { id: LotType.Polylot, name: "Полилот" }
];

export const MaxMarkupTypeChoices = [
    { id: MaxMarkupType.LessOrEqual50MaxMarkup, name: "≤ 50р" },
    { id: MaxMarkupType.Between50And500MaxMarkup, name: "> 50р ≤ 500р" },
    { id: MaxMarkupType.Greater500MaxMarkup, name: "> 500р" }
];

export const PurchaseOrderLotTypeChoices = [
    { id: PurchaseOrderLotType.None, name: "Не задано" },
    { id: PurchaseOrderLotType.Mono, name: "Монолот" },
    { id: PurchaseOrderLotType.Collection, name: "Полилот" }
];

export const MappedAreaStatusChoices = [
    { id: MappedAreaStatus.NotMapped, name: "Несопоставленные" },
    { id: MappedAreaStatus.Mapped, name: "Сопоставленные" },
];

export const IsActualStatusChoices = [
    { id: IsActualStatus.Actual, name: "Актуальные" },
    { id: IsActualStatus.NotActual, name: "Неактуальные" },
];

export const YesOrNoChoices = [
    { id: YesOrNoStatus.Yes, name: "Да" },
    { id: YesOrNoStatus.No, name: "Нет" },
];

export const NomenclatureStateChoices = [
    { id: NomenclatureState.NotMapped, name: "Несопоставленные" },
    { id: NomenclatureState.Mapped, name: "Сопоставленные" },
    { id: NomenclatureState.Ignored, name: "Игнорируемые" }
];

export const LotPriceApprovalStatusChoices = [
    { id: LotPriceApprovalStatus.None, name: "Не требуется" },
    { id: LotPriceApprovalStatus.Required, name: "Требуется" },
    { id: LotPriceApprovalStatus.OnApproval, name: "На согласовании" },
    { id: LotPriceApprovalStatus.Declined, name: "Отклонена" },
    { id: LotPriceApprovalStatus.Approved, name: "Согласована" },
];

export const DosageUnitChoices = [
    { id: DosageUnit.Mg, name: "Миллиграммы" },
    { id: DosageUnit.Ml, name: "Миллилитры" },
];

export const LotResultStatusChoices = [
    { id: LotResultStatus.Won, name: 'Выиграно'},
    { id: LotResultStatus.Lost, name: 'Проиграли'},
    { id: LotResultStatus.RefusedParticipateNotPassByMinimumPrice, name: 'Проиграли – Не прошли по НМЦ'},
    { id: LotResultStatus.RefusedParticipateNotPassByTz, name: 'Проиграли – Не прошли по ТЗ'},
    { id: LotResultStatus.RefusedParticipateNotPassByDateSubmission, name: 'Проиграли – Не успеваем по дате подачи'},
    { id: LotResultStatus.RefusedParticipateNotPassByProductStatus, name: 'Проиграли – Не прошли по статусу товара'},
    { id: LotResultStatus.LostRejectedApplication, name: 'Проиграли - Отклонили заявку'},
    { id: LotResultStatus.Undefined, name: 'Неопределено'}
]


export const PurchaseOrderProcessingStatusChoices = [
    { id: PurchaseOrderProcessingStatus.New, name: 'Новый'},
    { id: PurchaseOrderProcessingStatus.Viewed, name: 'Просмотренный'},
    { id: PurchaseOrderProcessingStatus.Won, name: 'Выиграно'},
    { id: PurchaseOrderProcessingStatus.Lost, name: 'Проиграно'},
    { id: PurchaseOrderProcessingStatus.BeingPrepared, name: 'Готовится'},
    { id: PurchaseOrderProcessingStatus.Rejected, name: 'Отклонили'},
    { id: PurchaseOrderProcessingStatus.RefusedParticipateNotPassByMinimumPrice, name: 'Отказались – не прошли по НМЦ'},
    { id: PurchaseOrderProcessingStatus.RefusedParticipateNotPassByTz, name: 'Отказались – не прошли по ТЗ'},
    { id: PurchaseOrderProcessingStatus.RefusedParticipateNotPassByDateSubmission, name: 'Отказались – не успеваем по дате подачи'},
    { id: PurchaseOrderProcessingStatus.RefusedParticipateNotPassByProductStatus, name: 'Отказались – по статусу товара'},
    { id: PurchaseOrderProcessingStatus.InProgress, name: "В работе" },
    { id: PurchaseOrderProcessingStatus.Completed, name: "Завершен" }
];

export const PurchaseOrderProcessingManagerStatusChoices = [
    { id: OrderManagerStatus.InWork, name: 'В работе' },
    { id: OrderManagerStatus.PartipishiantChoise, name: 'Идет выбор ДБ - участника' },
    { id: OrderManagerStatus.BadNMC, name: 'Не прошли по НМЦ' },
    { id: OrderManagerStatus.BadTZ, name: 'Не прошли по ТЗ' },
    { id: OrderManagerStatus.NoTime, name: 'Не успеваем по дате подачи' },
    { id: OrderManagerStatus.BadLotStatus, name: 'Не прошли по статусу товара' },
    { id: OrderManagerStatus.Defect, name: 'Отклонено по причине дефектурной позиции' },
    { id: OrderManagerStatus.NoGuaranteedDelivery, name: 'Отклонено по причине - нет гарантии поставки' },
    { id: OrderManagerStatus.Rejected, name: 'Отклонили заявку' },
]

export const PurchaseOrderResultStatusChoices = [
    { id: PurchaseOrderResultStatus.NotSet, name: 'Не задано'},
    { id: PurchaseOrderResultStatus.NoApplications, name: 'Не было заявок'},
    { id: PurchaseOrderResultStatus.Cancelled, name: 'Отменен'},
    { id: PurchaseOrderResultStatus.NoContract, name: 'Не вывешен ГК'},
    { id: PurchaseOrderResultStatus.NoProducerInContract, name: 'Нет производителя в ГК'},
    { id: PurchaseOrderResultStatus.PurchaseFromSingleSupplier, name: 'Закупка у единственного поставщика'},
    { id: PurchaseOrderResultStatus.PurchaseFromSingleSupplierNoContract, name: 'Закупка у единственного поставщика (не вывешен ГК)'},
    { id: PurchaseOrderResultStatus.Won, name: 'Выиграли'},
    { id: PurchaseOrderResultStatus.Lost, name: 'Проиграли'},
    { id: PurchaseOrderResultStatus.RefusedParticipateNotPassByMinimumPrice, name: 'Проиграли – не прошли по НМЦ'},
    { id: PurchaseOrderResultStatus.RefusedParticipateNotPassByTz, name: 'Проиграли – не прошли по ТЗ'},
    { id: PurchaseOrderResultStatus.RefusedParticipateNotPassByDateSubmission, name: 'Проиграли – не успеваем по дате подачи'},
    { id: PurchaseOrderResultStatus.RefusedParticipateNotPassByProductStatus, name: 'Проиграли –  не прошли по статусу товара'},
    { id: PurchaseOrderResultStatus.LostRejectedApplication, name: 'Проиграли - Отклонили заявку'},
    { id: PurchaseOrderResultStatus.Undefined, name: "Неопределено"}

];


export const PurchaseOrderPriceApprovalChoices = [
    { id: PurchaseOrderPriceApprovalStatus.NoNeed, name: 'Согласование не требуется'},
    { id: PurchaseOrderPriceApprovalStatus.NeedApproval, name: 'Требуется согласование'},
    { id: PurchaseOrderPriceApprovalStatus.OnApproval, name: 'На согласовании'},
    { id: PurchaseOrderPriceApprovalStatus.Approved, name: 'Согласовано'},
    { id: PurchaseOrderPriceApprovalStatus.PartiallyApproved, name: 'Частично согласовано'},
    { id: PurchaseOrderPriceApprovalStatus.Rejected, name: 'Отклонено'},
];

export const PurchaseOrderPriceApprovalCommonChoices = [
    { id: PurchaseOrderPriceApprovalCommonStatus.NoNeed, name: 'Согласование не требуется'},
    { id: PurchaseOrderPriceApprovalCommonStatus.NeedApproval, name: 'Требуется согласование'},
    { id: PurchaseOrderPriceApprovalCommonStatus.OnApproval, name: 'На согласовании'},
    { id: PurchaseOrderPriceApprovalCommonStatus.FirstCommercialOfferApproved, name: 'Согласовано по КП1'},
    { id: PurchaseOrderPriceApprovalCommonStatus.SecondCommercialOfferApproved, name: 'Согласовано по КП2'},
    { id: PurchaseOrderPriceApprovalCommonStatus.Approved, name: 'Полностью согласовано'},
    { id: PurchaseOrderPriceApprovalCommonStatus.PartiallyApproved, name: 'Частично согласовано'},
    { id: PurchaseOrderPriceApprovalCommonStatus.Rejected, name: 'Отклонено'},
];

export const PurchaseOrderPriceApprovalStatusHistoryChangeChoices = [
    { id: PurchaseOrderPriceApprovalStatusHistoryChange.NoNeed, name: 'Согласование не требуется'},
    { id: PurchaseOrderPriceApprovalStatusHistoryChange.NeedApproval, name: 'Требуется согласование'},
    { id: PurchaseOrderPriceApprovalStatusHistoryChange.OnApproval, name: 'На согласовании'},
    { id: PurchaseOrderPriceApprovalStatusHistoryChange.Approved, name: 'Согласовано'},
    { id: PurchaseOrderPriceApprovalStatusHistoryChange.PartiallyApproved, name: 'Частично согласовано'},
    { id: PurchaseOrderPriceApprovalStatusHistoryChange.Declined, name: 'Отклонено'},
];

export const LotPriceApprovalStatusHistoryChangeChoices = [
    { id: LotPriceApprovalStatus.None, name: 'Согласование не требуется'},
    { id: LotPriceApprovalStatus.Required, name: 'Требуется'},
    { id: LotPriceApprovalStatus.OnApproval, name: 'На согласовании'},
    { id: LotPriceApprovalStatus.Approved, name: 'Согласовано'},
    { id: LotPriceApprovalStatus.Declined, name: 'Отклонено'},
];

export const CommercialOfferTypeChoices = [
    { id: CommercialOfferType.First, name: 'Первое'},
    { id: CommercialOfferType.Second, name: 'Второе'},
]