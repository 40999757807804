import * as React from 'react';
import SuccessFailureTextField from './SuccessFailureTextField';

const OrderRelevance = (props) => {
    const { isActual } = props.record;
    if (isActual == null)
        return null;

    const text = isActual ? 'Актуально' : 'Неактуально';
    return <SuccessFailureTextField record={{text}} source="text" success={isActual} />;
};

export default OrderRelevance;
