import React from "react";
import { BooleanField, Datagrid, TextField } from "react-admin";
import Pagination from "../../components/DefaultPagination";
import Filter from '../../components/GphcUIKit/Filter';
import List from "../../components/List";
import { AListActions } from '../../components/ListActions';
import { FactoryComponents } from '../../common/enums';

const SuppliersList = props => {
    return (
        <List
            {...props}
            filters={<Filter
                searchComponentOptions={[
                    { type: FactoryComponents.Search, source: "q", alwaysOn: true }
                ]}
                filtersComponentsOptions={[
                    { type: FactoryComponents.Select, source: "isSpecial", label: "Избранный", choices: [{ id: true, name: "Да" }, { id: false, name: "Нет" }], alwaysOn: true, isMultiple: false },
                ]}
                defaultFilters={{ isSpecial: true }}
                {...props}
            />}
            perPage={25}
            pagination={<Pagination />}
            title={props.options.label}
            actions={<AListActions {...props} />}
            bulkActionButtons={false}
        >
            <Datagrid >
                <TextField source="name" label="Название" />
                <BooleanField source="isSpecial" label="Избранный" />
            </Datagrid>
        </List>
    );
}


export default {
    list: SuppliersList,
    // create: CustomerCreate
}