import * as React from 'react';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent,
    DialogContentText , DialogTitle, makeStyles
} from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
    button: {
        background :'rgba(0, 155, 195, 0.12)',
        borderRadius: '8px',
        padding: '7px 24px'
    }
}));


const MapBulkButtonLayout = (
    {
        handleClick, 
        header, 
        cancelButtonOptions,
        cancelButtonText,  
        acceptButtonOptions,
        acceptButtonText,
        additionalButtons,
        dialogContentText,
        dialogTitle,
        dialogContent,
    } : MapBulkButtonLayoutProps ) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMap = async () => {
        await handleClick();
        setOpen(false);
    };

    return (
      <div>
        <Button variant="text" className={classes.button} color="primary"onClick={handleClickOpen}>{header}</Button>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                    {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                        {dialogContentText}
                </DialogContentText>
                { dialogContent }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleMap} color="primary" {...acceptButtonOptions}>
                    {acceptButtonText}
                </Button>
                <Button onClick={handleClose} color="primary" {...cancelButtonOptions}>
                    {cancelButtonText}
                </Button>
                {additionalButtons}
            </DialogActions>
        </Dialog>
      </div>
    );
}

type MapBulkButtonLayoutProps = {
    handleClick : () => Promise<any>,
    header : string,
    cancelButtonOptions ?: any,
    cancelButtonText : string,
    acceptButtonOptions ?: any,   
    acceptButtonText : string,
    additionalButtons ?: React.ReactNodeArray,
    dialogContentText : string,
    dialogTitle : string,
    dialogContent ?: React.ReactNode | React.ReactNodeArray,
  }


export default MapBulkButtonLayout;