import {
  FiltersHeightState,
  FiltersHeightActionsTypes,
  CHANGE_FILTERS_HEIGHT_STATE
} from './types'

const initialState:FiltersHeightState = {
    purchaseOrders: 0,
    purchaseOrdersApproval: 0,
    commercialOffers: 0,
    skuPriceAdditionalContracts: 0,
    skuPrices: 0,
    skus: 0,
    logisticsPrice: 0,
    nomenclatures: 0,
    regions: 0,
    customers: 0,
    skuRegionsRelevance: 0,
    skuCustomersRelevance: 0,
    maxWholesaleMarkups: 0,
    stockBalance: 0,
    skuProducers: 0,
    suppliers: 0,
    persistentLog: 0,
    dictionaryImport: 0,
    inns: 0
}

export default function togglerReducer(
  state = initialState,
  action: FiltersHeightActionsTypes
): FiltersHeightState {
  switch (action.type) {
    case CHANGE_FILTERS_HEIGHT_STATE:
      state[action.payload.key] = action.payload.height
      return { 
          ...state
      };
    default:
      return state;
  }
}