import React from 'react'
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useRefresh, fetchStart, fetchEnd, useNotify } from 'react-admin';
import api from '../../dataProvider/api';

export const IgnoreButton = ({addLabel, label, record, ignoreCommentValue, ...buttonProps}) => {
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const notify = useNotify();


    const submit = async () => {
        dispatch(fetchStart());
        try {
            const result = await api.ignoreNomenclature(record.id, ignoreCommentValue);
    
            if (result.succeeded) { 
                refresh();
                notify("Номенклатура теперь игнорируется");
            }
            else {
                refresh();
                notify("Не удалось сделать номенклатуру игнорируемой", "warning");
            }
        } finally {
            dispatch(fetchEnd());
        }
    };
    if (!buttonProps)
        return (
            <Button variant="contained" color="secondary" size="small" onClick={submit} {...buttonProps}>
                {label}
            </Button>
        );
    
    return (
        <Button onClick={submit} {...buttonProps}>
            {label}
        </Button>
    );
};

