import * as React from "react";
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Portal, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useNotify, useRefresh, usePermissions, fetchStart, fetchEnd } from 'react-admin';
import api from "../../dataProvider/api";
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import HtmlEditor from "../../components/HtmlEditor";
import { useCommercialOfferStyles } from "./styles";
import PriceApprovalResponseShortDescription from "../../components/PriceApprovalResponseShortDescription";
import { SecondaryButton, PrimaryButton } from "../../components/GphcUIKit/Button";
import { isFederalManager, isManager } from "../../common/helpers";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const isAllowedToStartPriceApproval = (permissions) => isManager(permissions) || isFederalManager(permissions);

export const StartPriceApprovalButton = (props) => {
    const innerProps = { 
        showButtonPredicate: (order) => order.canCreatePricesApproval,
        getMessageDefaultSubject: (order, isRepeat) => `Запрос на ${isRepeat ? "повторное " : ""}согласование цены по аукциону №${order.orderNumber}`,
        getShortDescription: async (orderId) => api.getPriceApprovalJustificationShort(orderId),
        getMessageBody: async (orderId, justification) => api.getPriceApprovalJustification(orderId, justification),
        shortDescriptionComponent: (model) => <JustificationShortDescription model={model} />,
        submit: async (orderId, justification, messageSubject, messageBody) => 
            api.startOrderPricesApproval(orderId, justification, messageSubject, messageBody),
        mainButtonText: 'Отправить на согласование',
        justificationButtonText: 'Запросить',
        successMessage: 'Заказ отправлен на согласование цены',
        permissionsPredicate: isAllowedToStartPriceApproval,
        collapseAllLots: props.collapseAllLots
    };

    return <PriceApprovalButton {...innerProps} {...props} />;
}

export const FinishPriceApprovalButton = (props) => {
    const innerProps = { 
        showButtonPredicate: (order) => order.canFinishPricesApproval,
        getMessageDefaultSubject: (order) => `Согласованы цены по аукциону №${order.orderNumber}`, 
        getShortDescription: async (orderId) => api.getPriceApprovalResponseShort(orderId),       
        getMessageBody: async (orderId, justification) => api.getPriceApprovalResponse(orderId, justification),
        shortDescriptionComponent: (model) => <ResponseShortDescription model={model} />,
        submit: async (orderId, justification, messageSubject, messageBody) => 
            api.finishOrderPricesApproval(orderId, justification, messageSubject, messageBody),
        mainButtonText: 'Завершить согласование',
        justificationButtonText: 'Согласовать',
        successMessage: 'Цена согласована',
        permissionsPredicate: isFederalManager,
        collapseAllLots: props.collapseAllLots
    };

    return <PriceApprovalButton {...innerProps} {...props} />;    
}

const PriceApprovalButton = (props) => {
    const { 
        showButtonPredicate,
        getMessageDefaultSubject,
        getShortDescription,
        getMessageBody,
        shortDescriptionComponent,
        submit: submitFunc,
        mainButtonText,
        justificationButtonText,
        successMessage,
        permissionsPredicate,
        collapseAllLots
    } = props;

    const classes = useCommercialOfferStyles();

    const [justification, setJustification] = useState("");
    const [justificationShort, setJustificationShort] = useState(null);    
    const [messageSubject, setMessageSubject] = useState("");
    const [messageBody, setMessageBody] = useState("");    
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const [justificationDialogOpen, setJustificationDialogOpen] = useState(false);
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);

    const [errorBarOpen, setErrorBarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const handleErrorClose = (event) => setErrorBarOpen(false);

    const dispatch = useDispatch();
    const notify = useNotify();
    const refresh = useRefresh();

    const order = props.record;
    const { permissions } = usePermissions();

    if (order == null || !showButtonPredicate(order) || !permissionsPredicate(permissions))
        return null;

    const closeMessageDialog = () => setMessageDialogOpen(false);
    const openMessageDialog = async () => {
        dispatch(fetchStart());
        try {
            const result = await getMessageBody(order.id, justification);
            
            if (result == null) {
                notify('Произошла ошибка', 'warning');
                return;
            }

            setMessageSubject(getMessageDefaultSubject(order, (justificationShort as any).isRepeat));
            setMessageBody(result);

            setMessageDialogOpen(true);
        }   
        finally {
            dispatch(fetchEnd());
        }             
    }

    const submitMessage = async () => {
        dispatch(fetchStart());
        try {
            setLoadingSubmit(true);
            const result = await submitFunc(order.id, justification, messageSubject, messageBody);            
            setLoadingSubmit(false);

            if (result == null) {
                notify('Произошла ошибка', 'warning');
                return;
            }
            
            if (result.succeeded) {
                notify(successMessage);
                closeJustificationDialog();
                refresh();
            } else {
                if (result.errorMessage != null) {
                    setErrorBarOpen(true);
                    setErrorMessage('Ошибка: ' + result.errorMessage);
                }
                else
                    notify('Произошла ошибка', 'warning');
            }
        } finally {
            dispatch(fetchEnd());
        }                
    }

    const closeJustificationDialog = () => setJustificationDialogOpen(false);
    const openJustificationDialog = async () => {
        collapseAllLots();
        if (justificationShort != null) {
            setJustificationDialogOpen(true)
            return;
        }            

        dispatch(fetchStart());
        const result = await getShortDescription(order.id);
        dispatch(fetchEnd());

        if (result == null || result.succeeded === false) {
            if (result?.errorMessage != null)
                notify('Произошла ошибка: ' + result.errorMessage, 'warning');
            else 
                notify('Произошла ошибка', 'warning');

            return;
        }

        setJustificationShort(result);
        setJustificationDialogOpen(true);
    }

    const submit = async () => {        
        closeJustificationDialog();
        await openMessageDialog();
    };

    const canSubmitMessage = () => messageSubject != '' && messageBody != '' && !loadingSubmit;

    return (
        <div className={classes.btnIcon}>
            <Portal>
                <Snackbar onClose={handleErrorClose} open={errorBarOpen} autoHideDuration={6000}>                
                    <MuiAlert onClose={handleErrorClose} elevation={6} variant="filled" severity="error">{errorMessage}</MuiAlert>
                </Snackbar>
            </Portal>
            <PrimaryButton className={classes.COButton} onClick={openJustificationDialog} endIcon={<MailOutlineRoundedIcon />}>
                {mainButtonText}
            </PrimaryButton>
            <Dialog className={classes.dialog} open={justificationDialogOpen} onClose={closeJustificationDialog} maxWidth="lg" fullWidth={true}>                
                <DialogTitle>
                    Согласование цены по аукциону
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {shortDescriptionComponent(justificationShort)}
                    </DialogContentText>
                    <TextField value={justification} onChange={(e) => setJustification(e.target.value)} autoFocus 
                        label="Обоснование" fullWidth={true} multiline rows={10} />
                </DialogContent>
                <DialogActions>
                    <SecondaryButton onClick={closeJustificationDialog}>Отмена</SecondaryButton>
                    <PrimaryButton onClick={submit} color="primary" >{justificationButtonText}</PrimaryButton>
                </DialogActions>
            </Dialog>
            <Dialog className={classes.dialog} open={messageDialogOpen} onClose={closeMessageDialog} maxWidth="lg" fullWidth={true}>                
                <DialogTitle>
                    Отправка уведомления
                </DialogTitle>
                <DialogContent>                    
                    <TextField value={messageSubject} onChange={(e) => setMessageSubject(e.target.value)} 
                        label="Тема" fullWidth={true} autoFocus />
                    <HtmlEditor
                        value={messageBody}
                        onEditorChange={(value) => setMessageBody(value)}
                    />
                </DialogContent>
                <DialogActions>
                    <SecondaryButton onClick={closeMessageDialog}>Отмена</SecondaryButton>
                    <PrimaryButton disabled={!canSubmitMessage()} onClick={submitMessage} endIcon={loadingSubmit ? <CircularProgress color="secondary" size={20} /> : null}>Отправить</PrimaryButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const JustificationShortDescription = (props) => {
    const { model: { orderNumber, director, isRepeat } } = props;        

    const title = <span>Отправить на {isRepeat ? "повторное " : ""}согласование Федеральному Менеджеру {director} следующие позиции аукциона {orderNumber}:</span>;
    return <PriceApprovalResponseShortDescription title={title} {...props} />
}

const ResponseShortDescription = (props) => {
    const { model: { orderNumber, manager } } = props;

    const title = <span>В ответ на запрос о согласовании цен аукциона {orderNumber} КАМ {manager}:</span>;
    return <PriceApprovalResponseShortDescription title={title} {...props} />
}
