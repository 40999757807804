import * as React from "react";
import {
    Datagrid, TextField
} from "react-admin";
import Pagination from "../../components/DefaultPagination";
import { AListActions } from '../../components/ListActions';
import List from "../../components/List";
import ReportCreate from "./ReportCreate";
import { ReportShow } from "./ReportShow";

const ReportList = props => (
    <List
        {...props}
        pagination={<Pagination />}
        perPage={25}
        title={props.options.label}
        actions={<AListActions {...props} />}
        bulkActionButtons={false}
    >
        <Datagrid rowClick="show">
            <TextField label="Название" source="name" />
        </Datagrid>
    </List>
);


export default {
    list: ReportList,
    show: ReportShow,
    create: ReportCreate
}