import {
  BubblesState,
  BubblesActionsTypes,
  PUSH_BUBBLE_OBJECT
} from './types';

const initialState: BubblesState = {
  bubbles: {
    purchaseOrders: [],
    purchaseOrdersApproval: [],
    commercialOffers: [],
    skuPriceAdditionalContracts: [],
    skuPrices: [],
    skus: [],
    logisticsPrice: [],
    nomenclatures: [],
    regions: [],
    customers: [],
    skuRegionsRelevance: [],
    skuCustomersRelevance: [],
    maxWholesaleMarkups: [],
    stockBalance: [],
    skuProducers: [],
    suppliers: [],
    persistentLog: [],
    dictionaryImport: [],
    inns: [],
  }
}

export default function bubblesReducer(
  state = initialState,
  action: BubblesActionsTypes
): BubblesState {
  switch (action.type) {
    case PUSH_BUBBLE_OBJECT:
      const { payload: { choices, key } } = action;
      const bubblesChoices = state.bubbles[key].find(b => b.source == choices.source);
      if (bubblesChoices == null)
        state.bubbles[key].push(choices);
      else {
        if (bubblesChoices.choices == null)
          bubblesChoices.choices = choices.choices;
        else {
          let unique = [];
          choices.choices.forEach(element => {
            if(!bubblesChoices.choices.includes(element))
              //@ts-ignore
              unique.push(element);
          });
          bubblesChoices.choices.push(...unique);
        }
      }

      return {
        bubbles: {
          ...state.bubbles
        }
      };
    default:
      return state;
  }
}