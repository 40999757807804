import React from 'react';
import { Avatar, Chip } from '@material-ui/core';
import { ISupplierContact } from './MakeCommercialOfferEntities';

const BubbleContacts = ({ supplierContacts, setSupplierContacts }: 
    {
        supplierContacts: ISupplierContact[],
        setSupplierContacts: React.Dispatch<React.SetStateAction<ISupplierContact[]>>
    }) => {
    const firstLetters = (firstName: string, lastName: string) => {
        return firstName.substring(0, 1) + lastName.substring(0, 1);
    }

    return (
        <div>
            {supplierContacts.map(({ firstName, lastName, email }, i) => (
                <Chip
                    style={{marginRight: 3}}
                    key={i}
                    color="primary"
                    avatar={<Avatar style={{backgroundColor: "#1db8dd"}}>{firstLetters(firstName, lastName)}</Avatar>}
                    label={`${firstName} ${lastName} <${email}>`}
                    onDelete={_ => setSupplierContacts(prev => {
                        const newSc: ISupplierContact[] = [];
                        let count = 0;
                        prev.forEach((p, i) => {
                            if (p.email !== email) {
                                newSc.push({ id: count, email: p.email, firstName: p.firstName, lastName: p.lastName });
                                count++;
                            }
                        });
                        return newSc;
                    })}
                />
            ))}
        </div>
    );
}

export default BubbleContacts;