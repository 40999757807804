import { CircularProgress, DialogActions, DialogTitle, Portal } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import download from 'downloadjs';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { FC } from 'react';
import { FormDataConsumer, ReferenceInput, SimpleForm, useNotify, useRefresh } from 'react-admin';
import { NameDictionaryImport } from '../../common/helpers';
import { FlatButton, PrimaryButton } from '../../components/GphcUIKit/Button';
import SelectInputSingle from "../../components/GphcUIKit/SelectInputSingle";
import { entities } from '../../configuration';
import api from '../../dataProvider/api';
import { canApplyImport } from '../../entities/dictionaryImport/canApplyImport';
import { renderEntityImportStat } from '../../entities/dictionaryImport/renderImportStat';


const useStyles = makeStyles({
    // fileInDropzone => 
    dialog: fileInDropzone => ({
        '& .MuiDialog-paper': {
            minWidth: '880px',
            minHeight: '632px',
            fontFamily: 'Clarika Office Geometric',
            fontWeight: '500',
            lineHeight: '1.6'
        },
        '& .MuiDialog-paper .MuiDialogTitle-root .MuiTypography-root': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'center'
        },
        '& .MuiDialog-paper .MuiDialogTitle-root .MuiTypography-root .close-icon': {
            display: 'flex'
        },
        '& .MuiDialog-paper .MuiDialogContent-root': {
            paddingLeft: '40px',
            paddingRight: '40px'
        },
        '& .MuiDialog-paper .MuiDialogActions-root': {
            paddingLeft: '40px',
            paddingRight: '40px'
        },
        '& .MuiDialog-paper .MuiDialogContent-root .simple-form': {
            minHeight: 'calc(632px - 64px - 52px)'
        },
        '& .MuiDialog-paper .MuiDialogContent-root .simple-form .MuiCardContent-root .ra-input:nth-child(2)': {
            minHeight: '388px'
        },
        '& .MuiDialog-paper .MuiDialogContent-root .simple-form .MuiCardContent-root .ra-input:nth-child(1) .MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiDialog-paper .MuiDialogContent-root .simple-form .MuiCardContent-root .ra-input:nth-child(2) .MuiDropzoneArea-root': {
            minHeight: '388px',
            borderRadius: '20px',
            color: '#000',
            backgroundColor: !fileInDropzone ? '#FFFFFF' : 'rgba(251, 230, 233)',
        },
        '& .MuiDialog-paper .MuiDialogContent-root .simple-form .MuiCardContent-root .ra-input:nth-child(2) .MuiDropzoneArea-root .MuiDropzoneArea-textContainer': {
            display: 'flex',
            flexDirection: 'column-reverse',
            justifyContent: 'center',
            minHeight: '388px'
        },
        '& .MuiDialog-paper .MuiDialogContent-root .simple-form .MuiCardContent-root .ra-input:nth-child(2) .MuiDropzoneArea-root .MuiDropzoneArea-textContainer .MuiSvgIcon-root': {
            width: '100%',
            color: '#000',
        },
        '& .MuiDialogActions-root': {
            padding: '1px 24px 40px 24px'
        },
        '& .MuiCardContent-root': {
            paddingLeft: 0,
            paddingRight: 0
        },
        '& .imported-content-data': {
            minHeight: 'calc(632px - 64px - 52px)',
            overflowY: 'auto',
            padding: '20px',
            border: '1px solid #DCDCE1',
            borderRadius: '4px'
        },
        // какой-то непонятный компонент с удалением файла или перевыбором файла, я так понимаю при одиночной загрузке файла он не нужен, поэтому я скрыл его
        // если нужно будет сделать мультивыбор, необходимо удалить этот стиль
        '& .MuiDropzonePreviewList-image': {
            display: 'none'
        },
        '& .action-button:disabled': {
            opacity: '0.32'
        }
    }),
    circularStyles: {
        '&': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(632px - 64px - 52px)'
        }
    },
});

export function ImportDialog() {
    const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
    const [confirmUploadDialogOpen, setConfirmUploadDialogOpen] = React.useState(false);
    const [configChoice, setConfigChoice] = React.useState<number | undefined>(undefined);
    const [passportConfigId, setPassportConfigId] = React.useState<number | undefined>(undefined);
    const [file, setFile] = React.useState<File>();
    const [responseState, setResponseState] = React.useState<any>();
    const [isUploading, setIsUploading] = React.useState(false);
    const [isImporting, setIsImporting] = React.useState(false);
    const [token, setToken] = React.useState("");
    const notify = useNotify();
    const refresh = useRefresh();
    const classes = useStyles(!file);

    const [errorBarOpen, setErrorBarOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const handleErrorClose = () => setErrorBarOpen(false);

    const handleClickOpen = () => {
        setIsUploading(false)
        setUploadDialogOpen(true);
        setResponseState(null);
        setConfigChoice(undefined);
        setToken("");
    };

    const handleClose = () => {
        setUploadDialogOpen(false);
    };

    const handleChange = async (file) => {
        setFile(file[0]);
    }    

    const closeConfirmUploadDialog = () => setConfirmUploadDialogOpen(false);

    const convertBase64ToFile = function (image) {
        const byteString = atob(image);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
          ia[i] = byteString.charCodeAt(i);
        }
        const newBlob = new Blob([ab], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        return newBlob;
      };


    const uploadFile = async () => {
        if (!configChoice)
            return;

        try {
            setIsUploading(true);
            if (configChoice === passportConfigId) {
                const responseFile = await api.manualUpdate(file, configChoice);

                const result = await responseFile.json();
                if (result.succeeded !== undefined && !result.succeeded)
                {
                    notify(result?.errorMessage, "warning");
                    return;
                }

                let resFile
                if (result.file)
                    resFile = convertBase64ToFile(result.file);

                if (resFile) {
                    notify("Успешная загрузка справочника");
                    download(resFile, 'result.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                }

                setIsUploading(false);

                setResponseState({errors: result.rowErrors, success: result.rowSuccess, allRows: result.allRows, skipped: result.skipped });
                //setResponseState(result.response)
                setToken(result.token);
                setUploadDialogOpen(false);
                setConfirmUploadDialogOpen(true);
                return;
            }

            const result = await api.uploadDictionary(file, configChoice);


            if (result.succeeded) {
                const data = result.data;

                setResponseState(data.response);
                setToken(data.token);
                setUploadDialogOpen(false);
                setConfirmUploadDialogOpen(true);
            } else {
                setErrorMessage('Ошибка: ' + result.errorMessage);
                setErrorBarOpen(true);
            }
        }
        finally {
            setIsUploading(false);
        }
    }

    const confirmImport = async () => {
        setIsImporting(true);
        try {
            const result = await api.confirmFileImport(token);
            if (result.succeeded) {
                notify('Справочник успешно импортирован');
                refresh();
                setConfirmUploadDialogOpen(false);
            }
            else {
                setErrorMessage('Ошибка: ' + result.errorMessage);
                setErrorBarOpen(true);
            }
        } finally {
            setIsImporting(false);
        }
    }

    const canUploadFile = () => isUploading === false && file != null && configChoice != null && configChoice != undefined;


    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Импортировать
            </Button>
            <Portal>
                <Snackbar onClose={handleErrorClose} open={errorBarOpen} autoHideDuration={6000}>                
                    <MuiAlert onClose={handleErrorClose} elevation={6} variant="filled" severity="error">{errorMessage}</MuiAlert>
                </Snackbar>
            </Portal>
            <Dialog open={uploadDialogOpen} onClose={handleClose} className={classes.dialog}>
                <DialogTitle><span><strong>Загрузка справочника</strong></span> <span className="close-icon"><CloseIcon onClick={handleClose} /></span></DialogTitle>
                <DialogContent>
                    {
                        isUploading ? <div className={classes.circularStyles}><CircularProgress /></div> :
                            <SimpleForm submitOnEnter={false} toolbar={null}>
                                <ReferenceInput source="id" label="Конфигурация" alwaysOn reference={entities.xlsConfigs} sort={{ field: 'name', order: 'ASC' }}>
                                    <ConfigSelect 
                                        setConfigChoice={setConfigChoice}
                                        configChoice={configChoice}
                                        setPassportConfigId={setPassportConfigId}
                                    />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {(props) => {
                                        return (
                                            <DropzoneArea
                                                onChange={handleChange}
                                                filesLimit={1}
                                                useChipsForPreview={true}
                                                showFileNamesInPreview={true}

                                                maxFileSize={100000000}
                                                dropzoneText="Выберите файл для загрузки. Для импорта справочника вы можете загрузить одновременно не более 1 файла."
                                                acceptedFiles={[".xls", ".xlsx"]}
                                            />
                                        );
                                    }}
                                    
                                </FormDataConsumer>
                            </SimpleForm>
                    }
                </DialogContent>
                <DialogActions>
                    <PrimaryButton className="action-button" disabled={!canUploadFile()} onClick={uploadFile}>Загрузить</PrimaryButton>
                </DialogActions>
            </Dialog>
            <Dialog open={confirmUploadDialogOpen} onClose={closeConfirmUploadDialog} className={classes.dialog}>
                <DialogTitle><span><strong>{isImporting ? "Импорт" : "Подтверждение загрузки"}</strong></span> <span className="close-icon"><CloseIcon onClick={closeConfirmUploadDialog} /></span></DialogTitle>
                <DialogContent>
                    {isImporting ? 
                        <div className={classes.circularStyles}><CircularProgress /></div> : 
                        responseState != null && <div><h1>Успешная загрузка справочника</h1><h3>Протокол загрузки:</h3><div className="imported-content-data">{configChoice === passportConfigId ? renderEntityImportStat(responseState, configChoice, passportConfigId) : responseState.map(s => renderEntityImportStat(s, undefined, undefined))}</div></div>}
                </DialogContent>
                <DialogActions>
                    <FlatButton onClick={closeConfirmUploadDialog}>Отменить</FlatButton>
                    <PrimaryButton className="action-button" disabled={configChoice === passportConfigId ? false : !canApplyImport(isImporting, responseState)} onClick={confirmImport}>Импортировать</PrimaryButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

type ConfigSelectProps = {
    setConfigChoice: (newValue: number) => void,
    configChoice: number | undefined,
    setPassportConfigId: (newValue: number) => void,
}

const ConfigSelect: FC<ConfigSelectProps> = (props: any) => (
    <SelectInputSingle 
        {...props}
        placeholder="Тип справочника для загрузки" 
        isNotDefaultColor 
        inputProps={{
            onChange: (e) => {
                props.setConfigChoice(e.target.value)
                props.setPassportConfigId(props.choices.find(c => c.name === NameDictionaryImport)?.id)
            },
            value: props.configChoice,
        }} 
        choices={[
            ...props.choices,
        ]}
    />
);
