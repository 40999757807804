import React, { useState } from "react";
import { Grid, Button, makeStyles } from '@material-ui/core';
import { useGetList, useNotify } from 'react-admin';
import { entities } from "../../../configuration";
import AutocompleteContactField from './AutocompleteContactField';
import { ISupplierContact } from './MakeCommercialOfferEntities';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles({
    icon: {
        margin: 0
    },
    addButton: {
        marginLeft: 24,
        height: "100%",
        alignSelf: "center"
    },
    hoverRemoveButton: {
        marginTop: 25,
        "&:hover": {
            background: "rgba(0, 0, 0, 0.04) !important"
        }
    }
});

export default ({ supplierContacts, setSupplierContacts, supplierId } : {
        supplierContacts: ISupplierContact[],
        setSupplierContacts:  React.Dispatch<React.SetStateAction<ISupplierContact[]>>,
        supplierId
    }) => {

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<string | null>(null);


    const onChange = (e, customerProps) => {
        if (!(e.key !== 13 && typeof(customerProps) == 'string') && customerProps) {
            firstNameChanged(customerProps.firstName);
            lastNameChanged(customerProps.lastName);
            emailChanged(customerProps.email);
        }
        else {
            // setFirstName("");
            // setLastName("");
            // setEmail("");
        }
    }

    const validateEmail = (email : string) => {
        const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
        const regexValidate = !re.test(String(email.trimStart().trimEnd()).toLowerCase());
        if (regexValidate)
            return "Email некорректен";

        if (supplierContacts.some(s => s.email === email))
            return "Такой e-mail уже присутствует в списке.";

        return null;
    }

    const firstNameChanged = (name) => {
        if (name || name  === "")
            setFirstName(name);
    }

    const lastNameChanged = (name) => {
        if (name || name  === "")
            setLastName(name);
    }

    const emailChanged = (em) => {
        const resultValidate = validateEmail(em)
        setEmailError(resultValidate);

        if (email || email === "") {
            setEmail(em);
        }
    }

    const { data } = useGetList(entities.supplierEmails, { page: 1, perPage: 1000 },
        { field: '', order: '' }, { supplierId });

    const options: any[] = [];
    for (let id1 in data)
        options.push(data[id1]);

    return (
        <div style={{width: "100%", display: "flex"}}>
            <Grid container spacing={3} style={{height: "calc(116px)"}}>
                <Grid item xs={4} >
                    <AutocompleteContactField value={firstName} onChange={onChange} options={options} label="Имя" error={false} helperText={""}
                        setValue={(val) => {
                            firstNameChanged(val);
                        }} />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteContactField value={lastName} onChange={onChange} options={options} label="Фамилия" error={false} helperText={""}
                        setValue={(val) => {
                            lastNameChanged(val);
                        }} />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteContactField value={email} onChange={onChange} options={options} label="Email" error={emailError !== null} helperText={emailError}
                        setValue={(val) => {
                            const email = val.trimStart().trimEnd();
                            emailChanged(email);
                        }} />
                </Grid>
            </Grid>
            <AddNewSupplierContact
                setSupplierContacts={setSupplierContacts} 
                firstName={firstName} 
                lastName={lastName} 
                email={email} 
                emailError={emailError}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail} 
            />
        </div>
    );

};


const AddNewSupplierContact = (
    { 
        setSupplierContacts, 
        firstName, lastName, email, 
        emailError,
        setFirstName, setLastName, setEmail 
    } :
    { 
        setSupplierContacts: React.Dispatch<React.SetStateAction<ISupplierContact[]>>,
        firstName, lastName, email, 
        emailError,
        setFirstName, setLastName, setEmail
    }) => {
    const classes = useStyles();
    const notify = useNotify();


    return (
        <Button
            className={classes.addButton}
            variant="contained"
            color="secondary"
            classes={{ startIcon: classes.icon }}
            startIcon={<Add />}
            disabled={emailError !== null || firstName === "" || lastName === "" || email === ""}
            onClick={e => setSupplierContacts(prev => {
                if (prev.length < 16) {
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    
                    return [...prev, { id: prev.length, email: email, firstName: firstName, lastName: lastName }];
                }

                notify("Достигнут лимит получателей");
                return prev;
            })}
        >
            Добавить
        </Button>
    );
}