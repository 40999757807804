import React, { useEffect, useState } from 'react';
import {
    Toolbar, TopToolbar,
    Edit, SimpleForm, FormDataConsumer,
    TextInput, TextField, required
} from 'react-admin';
import { QueryBuilder } from '../../AQB';
import api from '../../dataProvider/api';
import { 
    makeStyles
} from '@material-ui/core';
import BackTo from '../../components/BackTo';
import SaveButton from './SaveButton';

const getTokenForQueryBuilder = (id) => {
    return "ReactEdit" + id;
}

const useStyles = makeStyles({
    addLabel: {
        '& .MuiInputLabel-filled':{
            display: "block"
        },
    },
});

const ReportVersionEdit = (props) => {
    const classes = useStyles();
    const [reportName, setReportName] = useState("");
    const [query, setQuery] = useState(null);
    const [clientQuery, setClientQuery] = useState(null)
    const [startQuery, setStartQuery] = useState(null);
    const [queryBuilder, setQueryBuilder] = useState(null);
    const buttonName = "Назад к запросу";

    const queryProps = {
        reportName,
        query,
        clientQuery,
        startQuery,
        buttonName
    }

    return (
        <Edit actions={<ReportVersionEditTopToolbar {...queryProps} />} {...props}>
            <SimpleForm toolbar={<ReportVersionEditToolbar queryBuilder={queryBuilder} {...queryProps} />} redirect={(basePath, id, data) => `/reports/${data.reportId}/show`} >
                <TextInput label="Название" source="reportName" validate={[required()]} className={classes.addLabel}/>
                <QueryBuilderWrap setQuery={setQuery} setClientQuery={setClientQuery} setStartQuery={setStartQuery} setQueryBuilder={setQueryBuilder}/>
                <FormDataConsumer>
                {({ formData, ...rest }) => {
                    setReportName(formData.reportName);
                    return null;
                }}
                </FormDataConsumer>
                <HiddenToken />
            </SimpleForm>
        </Edit>
    );
};

const HiddenToken = (props) => {
    let newProps = props;
    newProps.record.token = getTokenForQueryBuilder(props.record.id);
    return (
        <TextInput source="token" {...newProps} style={{contentVisibility: "hidden"}}/>
    );
}

const QueryBuilderWrap = (props) => {
    const {record, setQuery, setClientQuery, setStartQuery, setQueryBuilder} = props;

    useEffect(() => {
        (async () => await api.setSqlToAQB(record.id, getTokenForQueryBuilder(record.id)))();
    }, [])
    
    const sqlTextHandler = (clientSQL, SQL) => {
        setClientQuery(clientSQL);
        setQuery(SQL);
    }

    const updateHandler = (clientSQL, SQL) => {
        setClientQuery(clientSQL);
        setQuery(SQL);
    }

    return (
        <div>
            <QueryBuilder 
                name={getTokenForQueryBuilder(props.record.id)} 
                createQB={true} 
                sqlTextHandler={sqlTextHandler} 
                updateHandler={updateHandler}
                setStartQuery={setStartQuery}
                setQueryBuilder={setQueryBuilder}
            />
        </div>
    );
};

const ReportVersionEditToolbar = props => {
    const { record, reportName, query, clientQuery, startQuery, buttonName, queryBuilder } = props;
    const isHidden = isAlertHidden(query, clientQuery, reportName, startQuery, record);

    return (
        <Toolbar {...props} >
            <SaveButton 
                query={query} 
                clientQuery={clientQuery}  
                queryBuilder={queryBuilder}
            />
            <BackTo 
                isAlertHidden={isHidden} 
                buttonName={buttonName} 
                url={record?.reportId ? `/reports/${record.reportId}/show` : `/reports`}
            />
        </Toolbar>
    );
};


const ReportVersionEditTopToolbar = props => {
    const { data, reportName, query, clientQuery, startQuery, buttonName } = props;
    const isHidden = isAlertHidden(query, clientQuery, reportName, startQuery, data);

    return (
        <TopToolbar {...props} >
            <BackTo 
                isAlertHidden={isHidden}
                buttonName={buttonName} 
                url={data?.reportId ? `/reports/${data.reportId}/show` : `/reports`}
            />
        </TopToolbar>
    );
};

const isAlertHidden = (query, clientQuery, reportName, startQuery, record) => {
    return (record?.query && query === null && clientQuery === null) ||
    (record && record.reportName === reportName && query === startQuery && query === clientQuery)
};


export default {
    edit: ReportVersionEdit
}
