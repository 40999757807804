import { makeStyles } from '@material-ui/core';

export const formatDateTime = (dateTime: string): string => {
    if (dateTime == null || dateTime === '')
        return '';

    try {
        const parsed = new Date(dateTime);
        return `${getWithLeadingZero(parsed.getDate())}.${getWithLeadingZero(parsed.getMonth() + 1)}.${parsed.getFullYear()} в ${parsed.getHours()}:${getWithLeadingZero(parsed.getMinutes())}`;
    }
    catch {
        return '';
    }
}

const getWithLeadingZero = (val) => (val < 10 ? '0' : '') + val;

export const isAdmin = (permissions) => containsRole(permissions, 'Admin');
export const isManager = (permissions) => containsRole(permissions, 'Manager');
export const isFederalManager = (permissions) => containsRole(permissions, 'FederalManager');
export const isObserver = (permissions) => containsRole(permissions, 'Observer');

const containsRole = (permissions, role): boolean => {
    if (typeof permissions != 'string')
        return false;

    return permissions.split(',').map(r => r.trim()).includes(role);
}

export enum Roles {
    Admin = 'Admin',
    Manager = 'Manager',
    FederalManager = 'FederalManager',
    Observer = 'Observer'
};

export const createOnChangeWrapper = (props) => {
    const { record, source, onChange } = props;

    return (e) => {
        const value = e?.target?.value != null ? e.target.value : e;
        record[source] = value;

        if (onChange)
            onChange(e);
    }
}

export const useGridFixedColumnStyles = (columnIndex): any => {
    const styles = {
        dataGrid: {
            '&': {
                borderCollapse: 'separate'
            }
        }
    };

    styles.dataGrid[`& tbody tr td:nth-child(${columnIndex})`] = {
        position: 'sticky',
        left: '0',
        zIndex: '1',
        backgroundColor: '#ffffff'
    };

    styles.dataGrid[`& thead tr th:nth-child(${columnIndex})`] = {
        position: 'sticky',
        left: '0',
        zIndex: '3',
    };

    return makeStyles(styles)();
};

export const NameDictionaryImport = "Обновление информации в паспортах аукционов";
