import React from "react";
import { 
    Button, Dialog, DialogTitle,
    DialogContent, DialogContentText, DialogActions
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    buttonsShadow: {
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    }
});

const BackTo = props => {
    const classes = useStyles();
    const { isAlertHidden, url, buttonName } = props;
    const history = useHistory();

    const backTo = () => {
        history.push(url);
    };

    return (
        <div>
            {isAlertHidden ? 
                <Button onClick={backTo} className={classes.buttonsShadow}>
                    {buttonName}
                </Button>
            :
                <CheckOnBackTo buttonName={buttonName} backTo={backTo} classes={classes}/>
            }
        </div>
    );
};

const CheckOnBackTo = (props) => {
    const { backTo, buttonName } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleOk = () => {
        backTo();
        handleClose();
    }

    const handleReject = () => {
        handleClose();
    }


    return (
        <div>
            <Button onClick={handleClickOpen} className={props.classes.buttonsShadow}>
                {buttonName}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Вы точно хотите выйти?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Все изменения будут утеряны
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReject} color="primary">
                        Нет
                    </Button>
                    <Button onClick={handleOk} color="primary">
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default BackTo;