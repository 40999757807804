import { makeStyles } from "@material-ui/core/styles";

const inputStyles = {
    '& .MuiInputBase-input': {
        textDecoration: 'underline',
        color: '#009BC3',

        '&::placeholder': {
            textDecoration: 'underline',
            opacity: 1
        }
    },
    '& .MuiInputBase-input.Mui-disabled': {
        color: 'gray'
    },
    '& .MuiFilledInput-root.Mui-disabled': {
        backgroundColor: 'initial'
    }
};

export const useLotStyles = makeStyles(({
    iconSearch: {
        marginLeft: 8,
        // minHeight: 64,
        '&.MuiButton-containedPrimary': {
            backgroundColor: 'transparent',
            color: '#7B7F86',
            minWidth: 16,
            width: 16,
            padding: 0
        },

        '& .MuiSvgIcon-root': {
            width: 16,
            height: 16,
        }
    },
    totalUnitQuantityCell: {
        '& > span > div': {
            justifyContent: "flex-end"
        }  
    },
    grid: {
        overflow: "auto",
    },
    skuField: {
        display: 'flex',
        fontSize: 16,
        lineHeight: '16px',
        fontFamily: 'Clarika Office Geometric',
        alignItems: 'center',
        width: 'auto',
        flexDirection: "row-reverse",
        '& .MuiCardContent-root': {
            display: 'flex',
            padding: 0
        },
        '& .MuiFormControl-root': {
            // width: 76,
            maxWidth: '180px',
            margin: 0,
            minWidth: 0,
        },

        // '& .MuiFormControl-root': {
        //     width: '100%'
        // },

        '& .MuiSelect-root': {
            padding: 0,
            textDecoration: 'underline',
            color: '#009BC3'

        },


        '& > span': {
            minWidth: '133px',
            color: '#7B7F86',
        },
        '& input': {
            minWidth: '133px',
            color: '#7B7F86',
        },

        '& .MuiFormHelperText-contained': {
            display: 'none',
        },



        '& .MuiSelect-iconFilled': {
            display: 'none'
        },

        '& .MuiInputBase-root:before': {
            display: 'none'
        },
        '& .MuiInputBase-root:after': {
            display: 'none'
        },
        // second col inputs
        '& .MuiFilledInput-inputMarginDense': {
            padding: 0
        },
        ...inputStyles,
    },
    lotSkuActualIcon: {
        width: '100%',
        color: '#FE7C8F'
    },
    status: {
        "& :first-child": {
            maxWidth: 150,
        },
    },
    gridItem: {
        minWidth: 80,
        maxWidth: 300,
        // height: 115,
        textAlign: "right",
        '&:hover': {
            position: "initial"
        }
    },
    producer: {
        width: 180
    },

    lotInput: {
        minWidth: 120,
        display: 'flex',
        alignItems: 'center',
        '& .MuiInputBase-input + fieldset': {
            border: 'none'
        },
        ...inputStyles
    },
    expand: {
        maxWidth: '100%',

    },
    expandLotsContainer: {
        maxWidth: '1200px',

        '& .MuiCardContent-root': {
            padding: 0
        },
        // '& .MuiFormControl-root': {
        //     display: 'flex'
        // }


        '& .MuiFormControl-root': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start !important',
            alignItems: 'center',
            margin: 0,
            marginBottom: 12,

        },
        '& .MuiFormControl-root label': {
            color: '#7B7F86',
            marginRight: 16,
            maxWidth: 160,
            width: '100%',
            whiteSpace: 'normal'

        },
        '& .MuiFormControl-root label + div': {
            width: 'auto',
            padding: 0,
        },
        '& .MuiTypography-subtitle1': {
            fontSize: '14px',
            lineHeight: '16px',
            marginBottom: 12,
            color: '#7B7F86 !important',
            marginRight: 12
        },
        '& .MuiFormLabel-root': {
            fontSize: '14px',
            lineHeight: '16px',
        },
        '& .MuiInputLabel-shrink': {
            transform: 'none'
        },
    },
    expandLots: {

    },

    innerItem: {
        width: '100%',
        maxWidth: '420px',
        borderRight: '1px solid #E9E9ED',
        padding: '0 27px',

        '&:nth-child(1)': {
            '& .ra-field > div > span': {
                minWidth: '160px',
            },
        },
        '&:nth-child(2)': {
            '& .ra-field > div > span': {
                minWidth: '220px',
            },
            '& .lotPriceRelevance span': {
                whiteSpace: 'nowrap'
            }
        },

        '&:first-child': {
            paddingLeft: '20px',



        },

        '&:last-child': {
            maxWidth: '520px',
            border: 'none',
            paddingLeft: '60px',

            '& .MuiFormControl-root label': {
                color: '#7B7F86',
                marginRight: 16,
                maxWidth: 200,
                width: '100%',

            },
        },

        '& .MuiCardContent-root': {
            padding: 0,

        },
        '& .MuiFormControl-root': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 0,
            marginBottom: 12,

        },
        '& .MuiFormControl-root label': {
            color: '#7B7F86',
            marginRight: 12
        },
        '& .MuiFormControl-root label + div': {
            width: 'auto',
            padding: 0,
        },
        '& .MuiTypography-subtitle1': {
            fontSize: '14px',
            lineHeight: '16px',
            marginBottom: 12,
            color: '#7B7F86 !important',
            marginRight: 12
        },
        '& .MuiFormLabel-root': {
            fontSize: '14px',
            lineHeight: '16px',
        },
        '& .MuiInputLabel-shrink': {
            transform: 'none'
        },
        '& span': {
            fontSize: '14px !important',
            whiteSpace: 'break-spaces'
        },
        '& .priceDecision': {
            transform: 'translate(236px, 0)',
        },
        '& :last-child.MuiTypography-root.MuiTypography-body2': {
            overflowWrap: 'anywhere'
        }
    },

    sSelector: {
        display: 'flex',
        fontSize: 16,
        lineHeight: '16px',
        fontFamily: 'Clarika Office Geometric',
        alignItems: 'center',
        marginBottom: 14,

        '& > span': {
            minWidth: '133px',
            color: '#7B7F86',
        },

        '& .MuiFormHelperText-contained': {
            display: 'none',
        },

        '& .MuiFormControl-root': {
            margin: 0,
            minWidth: 0,
            width: '100%'
        },

        '& .MuiSelect-root': {
            padding: 0,
            textDecoration: 'underline',
            color: '#009BC3'

        },

        '& .MuiSelect-iconFilled': {
            display: 'none'
        },

        '& .MuiInputBase-root:before': {
            display: 'none'
        },
        '& .MuiInputBase-root:after': {
            display: 'none'
        },
        // second col inputs
        '& .MuiFilledInput-inputMarginDense': {
            padding: 0
        },
        ...inputStyles,
        '[type="text"] & .MuiInputBase-input::-webkit-input-placeholder': {
            opacity: '1 !important'
        }
    },

    booleanField: {
        // justifyContent: 'center',
        '&:hover .MuiSvgIcon-root': {
            color: 'inherit'
        },
        '& .MuiSvgIcon-root[title="Нет"]': {
            color: '#FE7C8F'
        },
        '& .MuiSvgIcon-root[title="Да"]': {
            color: '#49C088'
        }
    },

    comment: {
        marginBottom: 20,

        '& .MuiInputBase-root': {
            maxWidth: '480px !important',
            width: '100% !important',
        },

        '& label': {
            display: 'block'
        },

        '& .MuiFilledInput-input': {
            padding: '21px 12px 10px 0px'
        },

        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'none'
        }
    },
    static:{
        minWidth: 230
    }
}));