import React, { useState, useCallback, Dispatch } from "react";
import {
    TopToolbar, EditButton, 
    Show, SimpleShowLayout,
    TextField, useNotify
} from "react-admin";
import VersionSelector from "./VersionSelector";
import { makeStyles } from '@material-ui/core/styles';
import { 
    InputLabel, MenuItem, FormControl, Select, Button
} from '@material-ui/core'; 
import { Route, RouteChildrenProps, useHistory } from 'react-router-dom';
import { ParamType } from "../../../common/enums"; 
import { Parameters } from "./Parameters";
import ResultGrid from "./ResultGrid";
import { QueryBuilder } from "../../../AQB/index";
import { Report, Version, Parameter } from "./dtoInterfaces";
import api from "../../../dataProvider/api";
import * as downloadFile from "downloadjs";
import { getFileName } from "../../../common/fileHelper";
import { isAdmin } from "../../../common/helpers";

const Title = (props) => {
    return (<div></div>)
};

const GetTokenForQueryBuilder = (id) => {
    return "ReactShow" + id;
};

const useStyles = makeStyles({
    marginLeft: {
        marginLeft: 8
    }
})

const ReportActions = (props) => {
    const { reportVersionId, reportId, parameters, permissions } = props;
    const history = useHistory();
    const handleClose = (e) => {
        history.push(`/reportVersions/${reportVersionId}`);
    };
    const notify = useNotify();

    const exportData = async (e) => {
        if(!parameters?.every(p => p.value !== undefined && p.value !== ""))
            return;

        const token = GetTokenForQueryBuilder(reportId);
        const result = await api.exportReport(reportVersionId, token, parameters);
        if (result.ok) {
            downloadFile(await result.blob(), getFileName(result), 'text/xlsx');
        }
        else {
            var data = await result.json();
            notify(data.errorMessage ? data.errorMessage : 'Ошибка при загрузке файла', 'warning');
        }
    }

    return (
        <TopToolbar >
            {isAdmin(permissions) && <Button
                onClick={handleClose}
            >
                Редактировать
            </Button>
            }
            <Button 
                onClick={exportData}
            >
                Экспорт
            </Button>
        </TopToolbar>
    );
}

export const ReportShow = (props) => {
    const [reportVersionId, setReportVersionId] = useState<number>();
    const [reportId, setReportId] = useState<number>();
    const [value, setValue] = useState<Version | null>(null);
    const [versions, setVersions] = useState<Array<Version>>([]);
    const [parameters, setParameters] = useState<Array<Parameter> | null>(null);
    const [canExecuteQuery, setCanExecuteQuery] = useState<boolean>(false);
    const classes = useStyles();

    return (
        <Show 
            {...props} 
            actions={
                <ReportActions 
                    reportVersionId={reportVersionId} 
                    parameters={parameters} 
                    reportId={reportId}
                    permissions={props.permissions}
                />
            } 
            title={<Title />} >
            <SimpleShowLayout>
                <TextField label="Название" source="name" className={classes.marginLeft}/>
                {/*@ts-ignore*/}
                <Executer 
                    setReportVersionId={setReportVersionId} 
                    value={value}
                    setValue={setValue}
                    versions={versions}
                    setVersions={setVersions}
                    parameters={parameters} 
                    setParameters={setParameters}
                    canExecuteQuery={canExecuteQuery}
                    setCanExecuteQuery={setCanExecuteQuery}
                    setReportId={setReportId} />
            </SimpleShowLayout>
        </Show>
    );
}

const Executer = ({
        record, 
        setReportVersionId, 
        value, 
        setValue, 
        versions, 
        setVersions, 
        parameters, 
        setParameters, 
        canExecuteQuery, 
        setCanExecuteQuery, 
        setReportId 
    } : 
    {
        record : Report, 
        setReportVersionId : Dispatch<React.SetStateAction<number | undefined>>, 
        value : Version | null,
        setValue : Dispatch<React.SetStateAction<Version | null>>, 
        versions :  Version[], 
        setVersions : Dispatch<React.SetStateAction<Version[]>>, 
        parameters : Parameter[] | null, 
        setParameters : Dispatch<React.SetStateAction<Parameter[] | null>>, 
        canExecuteQuery : boolean, 
        setCanExecuteQuery : Dispatch<React.SetStateAction<boolean>>,
        setReportId : Dispatch<React.SetStateAction<number | undefined>>
    }) => {
        const newestVersion = getNewestVersion(record.reportVersions);

        const setCurrentVersion = (id : number) => {
            const version = versions.find(v => v.id === id);
            if (version !== undefined) {
                setReportVersionId(version.id);
                setCanExecuteQuery(false);
                setValue(version);
                setParameters(prev => {
                    const newParams: Parameter[] = unique(version.parameters);
                    newParams.forEach(element => {
                        const old = prev?.find(p => p.name === element.name);
                        if (old)
                            element.value = old.value;
                    });
                    return newParams;
                });
                
            }
        };

        const unique = (parameters: Parameter[]) => {
            const newParams: Parameter[] = [];
            parameters.forEach(p => {
                if (!newParams.map(n => n.name).includes(p.name)) {
                    newParams.push(p);
                }
            });

            return newParams;
        }

        if (!newestVersion)
            return null;

        if (value === null || record.reportVersions.length !== versions.length) {
            setVersions(record.reportVersions);
            
            setValue(newestVersion);
            setParameters(unique(newestVersion?.parameters));
            setReportVersionId(newestVersion.id);
            setReportId(record.id);
        }

        if (value === null || record === null || versions.length === 0)
            return null;


        return (
            <div>
                <div style={{width: 300}}>
                    <VersionSelector currentVersion={value} setCurrentVersion={setCurrentVersion} versions={versions} />
                    {parameters && <Parameters parameters={parameters} setCanExecuteQuery={setCanExecuteQuery} canExecuteQuery={canExecuteQuery} setParameters={setParameters}/>}
                    <div style={{marginLeft: 5}}>
                        <QueryBuilder name={GetTokenForQueryBuilder(record.id)} createCB={true}/>
                    </div>
                </div>
                <ResultGrid reportId={record.id} currentVersionIdSelected={value.id} parameters={parameters} canExecuteQuery={canExecuteQuery}/>
            </div>
        );
}

const getNewestVersion = (reportVersions : Version[]) => {
    //@ts-ignore
    const maxDate = new Date(Math.max.apply(null, reportVersions.map(r => new Date(r.dateCreate))));
    return reportVersions.find(rv => new Date(rv.dateCreate).getTime() === maxDate.getTime());
}





