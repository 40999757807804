import * as React from "react";
import {
    BooleanField, Datagrid,
    DateField,
    ExportButton, ReferenceField, Show,
    useQuery,
    Tab, TabbedShowLayout, TextField, TopToolbar
} from "react-admin";
import { FactoryComponents } from '../../common/enums';
import Filter from "../../components/GphcUIKit/Filter";
import List from "../../components/List";
import { entities } from "../../configuration";
import { ImportDialog } from '../../entities/dictionaryImport/importDialog';
import { ImportProtocol } from '../../entities/dictionaryImport/importProtocol';
import { renderEntityImportStat } from '../../entities/dictionaryImport/renderImportStat';
import { CircularProgress } from '@material-ui/core';
import { NameDictionaryImport } from "../../common/helpers";

const ImportList = (props) => (
    <List filters={<Filter
        searchComponentOptions={[
            { type: FactoryComponents.Search, source: "q", alwaysOn: true }
        ]}
        dateComponentsOptions={[
            { type: FactoryComponents.Date, source: "uploadDateFrom", label: "Дата загрузки с", alwaysOn: true },
            { type: FactoryComponents.Date, source: "uploadDateTo", label: "Дата загрузки по", alwaysOn: true },
        ]}
        filtersComponentsOptions={[
            { type: FactoryComponents.ReferenceInputSelect, source: 'importConfigId', label: 'Тип', reference: entities.xlsConfigs, inputSource: 'name', alwaysOn: true },
            {
                type: FactoryComponents.ReferenceInputSelect, source: 'userId', label: 'Пользователь', reference: entities.users, inputSource: 'fullName', alwaysOn: true,
                optionText: choice => choice?.fullName ? choice.fullName : ''
            },
            { type: FactoryComponents.Select, source: 'isImported', label: 'Статус', choices: [{ id: true, name: "Импортированные" }, { id: false, name: "Не импортированные" }], alwaysOn: true, isMultiple: false }
        ]}
        defaultFilters={{ isImported: [true] }}
        {...props}
    />}
        {...props} actions={<ListActions {...props} />} perPage={25} title="Импорт документов" bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <DateField label="Дата загрузки" source="uploadDate" showTime />
            <DateField label="Дата импорта" source="importDate" showTime />
            <ReferenceField link={false} label="Пользователь" source="userId" reference={entities.users}>
                <TextField source="fullName" />
            </ReferenceField>
            <ReferenceField link={false} label="Тип" source="importConfigId" reference={entities.xlsConfigs}>
                <TextField source="name" />
            </ReferenceField>
            <TextField label="Имя файла" source="uploadedFileName" />
            <TextField label="Ошибок" source="errorCount" />
            <BooleanField label="Ошибок нет" source="succeeded" />
            <BooleanField label="Справочник импортирован" source="imported" />
        </Datagrid>
    </List>
);

const Title = (props) => {
    return (<div>Импорт справочников</div>)
};

const ImportShow = (props) => (
    <Show {...props} title={<Title />}>
        <TabbedShowLayout>
            <Tab label="Сводка">
                <DateField label="Дата загрузки" source="uploadDate" showTime />
                <DateField label="Дата импорта" source="importDate" showTime />
                <ReferenceField link={false} label="Пользователь" source="userId" reference={entities.users}>
                    <TextField source="fullName" />
                </ReferenceField>
                <ReferenceField link={false} label="Тип" source="importConfigId" reference={entities.xlsConfigs}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField label="Имя файла" source="uploadedFileName" />
                <TextField label="Ошибок" source="errorCount" />
                <BooleanField label="Ошибок нет" source="succeeded" />
                <BooleanField label="Справочник импортирован" source="imported" />
                <ImportProtocol source="processingResult" />
            </Tab>
            <Tab label="Протокол">
                <ImportStatistic {...props} />
            </Tab>

        </TabbedShowLayout>
    </Show>
);

const ListActions = (props) => {
    const { total, resource, filterValues, currentSort, exporter } = props;
    return (
        <TopToolbar>
            <ImportDialog {...props} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                filter={filterValues}
                sort={currentSort}
                exporter={exporter}
                maxResults={10000}
            />
        </TopToolbar>
    );
};

const ImportStatistic = (props) => {
    const { record } = props;
    let elements: any = JSON.parse(record.processingResult)

    const { data, loading, error } = useQuery({ 
        type: 'getOne',
        resource: entities.xlsConfigs,
        payload: { id: props.record.importConfigId }
    });

    if (loading)
        return <CircularProgress />;

    if (data?.name === NameDictionaryImport) {
        const stat = { errors: elements.rowErrors, success: elements.rowSuccess, allRows: elements.allRows, skipped: elements.skipped };

        return renderEntityImportStat(stat, data.id, data.id);
    }
    

    if (elements[0].entityName == null)
        return <div></div>

    return (
        elements.map(e => renderEntityImportStat(e, undefined, undefined))
    )
}

export default {
    list: ImportList,
    show: ImportShow,
}