import * as React from "react";
import { useDispatch } from 'react-redux';
import { useNotify, useRefresh, fetchStart, fetchEnd } from 'react-admin';
import api from "../../dataProvider/api";
import { PrimaryButton } from "../../components/GphcUIKit/Button";
import SuccessFailureTextField from "../../components/GphcUIKit/SuccessFailureTextField";
import { useCommercialOfferStyles } from '../../entities/orders/styles';
import { Refresh} from '@material-ui/icons';

export default (props) => {
    const { record: { id, isPriceActual } } = props;
    const classes = useCommercialOfferStyles();

    if (isPriceActual == null)
        return null;

    const text = isPriceActual ? "Актуально" : "Цена прайса неактуальна";

    return <React.Fragment>
        <div className={classes.LotRelevanceWrap}>
            <span>Актуальность прайса</span>
            <div className="lotPriceRelevance">
                <div className={classes.COStatus}>
                    <SuccessFailureTextField success={isPriceActual} />
                    <span>{text}</span>
                </div>
                {!isPriceActual && <ActualizeButton id={id} className={classes.COButton} />}
            </div>
        </div>        
    </React.Fragment>;
}

const ActualizeButton = ({className, id}) => {
    const dispatch = useDispatch();
    const notify = useNotify();
    const refresh = useRefresh();

    const submit = async () => {
        dispatch(fetchStart());
        const result = await api.actualizeSkuPrice(id);
        dispatch(fetchEnd());

        if (result.succeeded) {
            notify('Прайс обновлён');
        }            
        else if (result.errorMessage)
            notify(result.errorMessage);
        else
            notify('Произошла ошибка');

        refresh();
    }

    return <PrimaryButton onClick={submit} className={className} endIcon={<Refresh />}>Актуализировать цену</PrimaryButton>;
}
