import React, { Fragment } from "react";
import {
    Create, Datagrid,
    DateField, Edit, ReferenceField,
    ReferenceInput, ReferenceManyField, Show,
    SimpleForm, SimpleShowLayout,
    Tab, TabbedShowLayout, TextField,
    TextInput, useNotify, useQuery,
    useRefresh, useUnselectAll
} from "react-admin";
import {
    MappedAreaStatusChoices
} from "../../common/choices";
import { MappedAreaStatus } from '../../common/enums';
import { isManager } from '../../common/helpers';
import Pagination from "../../components/DefaultPagination";
import AutocompleteInputSingle from "../../components/GphcUIKit/AutocompleteInputSingle";
import Filter from '../../components/GphcUIKit/Filter';
import List from "../../components/List";
import { AListActions } from "../../components/ListActions";
import { entities } from '../../configuration';
import api from '../../dataProvider/api';
import MapBulkButton from '../../layouts/bulkButtons/map/MapBulkButton';
import MapBulkWithAddAreaButton from '../../layouts/bulkButtons/map/MapBulkWithAddAreaButton';
import ExpandShow from './ExpandShow';
import { FactoryComponents } from '../../common/enums';

const CustomerList = props => {
    const { permissions, ...rest } = props;
    
    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: entities.areasOfResponsibility,
        payload: { pagination: {}, sort: {} }
    });

    const refresh = useRefresh();
    const notify = useNotify();
    const unSelect = useUnselectAll();

    const bulkCustomersMapToArea = (customerIds, areaId) => async () => {
        const result = await api.bulkCustomersMapToArea(customerIds, areaId);
        checkResult(result);
    }

    const bulkCustomersMapToNewArea = (customerIds, areaName) => async () => {
        const result = await api.bulkCustomersMapToNewArea(customerIds, areaName);
        checkResult(result);
    }

    const checkResult = (result: any) => {
        if (result.succeeded) {
            refresh();
            notify('ЛПУ сопоставлены');
            unSelect(entities.customers);
        }
        else
            notify('Ошибка: не удалось сопоставить ЛПУ', 'warning');
    }

    const ABulkActions = props => (
        <Fragment>
            <MapBulkButton data={data} handleClick={bulkCustomersMapToArea} {...props} />
            <MapBulkWithAddAreaButton data={data} handleClick={bulkCustomersMapToNewArea} {...props} />
        </Fragment>
    );

    if(isManager(permissions)){
        return (
            <List
                {...props}
                filters={
                    <Filter
                        searchComponentOptions={[
                            { type: FactoryComponents.Search, source: "q", alwaysOn: true }
                        ]} 
                        filtersComponentsOptions={[
                            { type: FactoryComponents.Region, alwaysOn: true, perPage: 300 },
                            { type: FactoryComponents.AreaOfResponsibility, alwaysOn: true },
                            { type: FactoryComponents.Select, source: "mappedAreaStatus", label: "Cопоставленные зоне", choices: MappedAreaStatusChoices, alwaysOn: true, isMultiple: false },
                        ]} 
                        defaultFilters={{ mappedAreaStatus: [MappedAreaStatus.Mapped] }}
                        {...props}
                    />
                }
                perPage={25}
                pagination={<Pagination />}
                title={props.options.label}
                actions={<AListActions {...props} />}
            >
                <Datagrid rowClick="show" >
                    <TextField source="name" label="Имя" />
                    <TextField source="inn" label="ИНН" />
                    <TextField source="kpp" label="КПП" />
                    <ReferenceField link="show" source="areaOfResponsibilityId" label="Зона ответственности" reference="areasOfResponsibility"><TextField source="name" /></ReferenceField>
                    <ReferenceField link="show" source="regionId" label="Регион" reference="regions"><TextField source="name" /></ReferenceField>
                    <TextField source="location" label="Местоположение" />
                    <TextField source="level" label="Уровень" />
                    <TextField source="type" label="Тип" />
                </Datagrid>
            </List>
        );
    }

    return (
        <List
            {...props} bulkActionButtons={<ABulkActions />}
            filters={
                <Filter
                    searchComponentOptions={[
                        { type: FactoryComponents.Search, source: "q", alwaysOn: true }
                    ]} 
                    filtersComponentsOptions={[
                        { type: FactoryComponents.Region, alwaysOn: true, perPage: 300 },
                        { type: FactoryComponents.AreaOfResponsibility, alwaysOn: true },
                        { type: FactoryComponents.Select, source: "mappedAreaStatus", label: "Cопоставленные зоне", choices: MappedAreaStatusChoices, alwaysOn: true, isMultiple: false },
                    ]} 
                    defaultFilters={{ mappedAreaStatus: [MappedAreaStatus.Mapped], hasArea: false }}
                    {...props}
                />
            }
            perPage={25}
            pagination={<Pagination />}
            title={props.options.label}
            actions={<AListActions {...props} />}
        >
            <Datagrid rowClick="show" expand={<ExpandShow data={data} />}>
                <TextField source="name" label="Имя" />
                <TextField source="inn" label="ИНН" />
                <TextField source="kpp" label="КПП" />
                <ReferenceField link="show" source="areaOfResponsibilityId" label="Зона ответственности" reference="areasOfResponsibility"><TextField source="name" /></ReferenceField>
                <ReferenceField link="show" label="Регион" source="regionId" reference="regions"><TextField source="name" /></ReferenceField>
                <TextField source="location" label="Местоположение" />
                <TextField source="level" label="Уровень" />
                <TextField source="type" label="Тип" />
            </Datagrid>
        </List>
    );
}

const Title = (props) => {
    return (<div>ЛПУ "{props.record?.name ?? ""}"</div>)
};

const CustomerShow = props => (
    <Show {...props} title={<Title />}>
        <TabbedShowLayout>
            <Tab label="ЛПУ">
                <SimpleShowLayout>
                    <TextField source="name" label="Имя" />
                    <TextField source="inn" label="ИНН" />
                    <TextField source="kpp" label="КПП" />
                    <ReferenceField link="show" source="areaOfResponsibilityId" label="Зона ответственности" reference={entities.areasOfResponsibility}><TextField source="name" /></ReferenceField>
                    <ReferenceField link="show" source="regionId" label="Регион" reference="regions"><TextField source="name" /></ReferenceField>
                    <TextField source="location" label="Местоположение" />
                    <TextField source="level" label="Уровень" />
                    <TextField source="type" label="Тип" />
                </SimpleShowLayout>
            </Tab>
            <Tab label="История сопоставлений">
                <ReferenceManyField perPage={10} pagination={<Pagination />} reference={entities.areasOfResponsibilityCustomers} target="customerId" label="История сопоставлений" sort={{ field: 'validFrom', order: 'ASC' }}>
                    <Datagrid>
                        <DateField label="Начало периода" source="validFrom" />
                        <ReferenceField link="show" label="Зона ответственности" source="areaOfResponsibilityId" reference={entities.areasOfResponsibility}>
                            <TextField source="name" />
                        </ReferenceField>
                        <DateField label="Конец периода" source="validTo" />
                        <ReferenceField link="show" label="Кто назначил" source="issuerId" reference={entities.users}>
                            <TextField source="firstName" />
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const CustomerEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput link="show" source="regionId" reference="regions">
                <AutocompleteInputSingle source="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

const CustomerCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput link="show" source="regionId" reference="regions">
                <AutocompleteInputSingle source="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);


export default {
    list: CustomerList,
    show: CustomerShow,
    edit: CustomerEdit,
    // create: CustomerCreate
}