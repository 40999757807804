import React from 'react';
import { SaveButton as RaSaveButton, useNotify } from 'react-admin';
import { 
    Button, Dialog, DialogTitle,
    DialogContent, DialogContentText, DialogActions
} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyle = makeStyles({
    startIcon: {
        marginLeft: 0
    }
});

export default ({query, clientQuery, queryBuilder, ...rest}) => {

    if (query !== clientQuery)
    {
        return (
            <DialogSaveButton queryBuilder={queryBuilder} {...rest}/>
        );
    }

    return (
        <RaSaveButton {...rest}
        />
    );
};

const DialogSaveButton = ({queryBuilder, ...rest}) => {
    const [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const classes = useStyle();
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
   
    const handleApply = () => {
        queryBuilder.refreshSql(({SQLEditorError}) => {
            if (SQLEditorError)
            {
                notify("В текстовом редакторе sql есть ошибки, невозможно применить изменения", "warning");
            }
            else
            {
                setTimeout(() => {
                    rest.handleSubmit();
                }, 3000);
            }
            handleClose();
        });
        
    };


    return (
        <div>
            <Button 
                onClick={handleClickOpen}
                startIcon={<SaveIcon style={{fontSize: 18, width: "1em", height: "1em"}} />}
                style={{
                    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                }}
                classes={{startIcon: classes.startIcon}}
            >
               Сохранить
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle> У вас есть несохраненные изменения в редакторе SQL</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Отбросить - будут отброщенны все изменения внесенные из текстового редактора SQL. 
                        <br/>
                        Принять - будет произведена попытка применить изменения из текстового редактора, с последующим сохранением.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <RaSaveButton
                        {...rest}
                        label="Отбросить" 
                    />
                    <Button onClick={handleApply} color="primary">
                        Принять
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};