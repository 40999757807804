import React from "react";
import { Chip, makeStyles } from '@material-ui/core';

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

export default ({ label, source, defaultValue }) => {
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={label} />;
};