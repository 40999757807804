import * as React from "react";
import {
    Toolbar, SimpleForm, Edit, TextInput, BooleanInput, NumberInput, SaveButton,
    required, SelectInput
} from "react-admin";

const LotEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const LotEdit = (props) => {
    return <Edit {...props}>
        <SimpleForm toolbar={<LotEditToolbar />}>
            <TextInput source="key" label="Код SKU" validate={[required()]} />
            <TextInput source="nomenclature" label="Номенклатура" validate={[required()]} />
            <TextInput source="pharmaceuticalGroup" label="Фарм. группа" validate={[required()]} />
            <BooleanInput source="isVeDrug" label="Тип ЖНВЛП" validate={[required()]} />
            <NumberInput source="dosageVolume" label="мл/мг" validate={[required()]} />
            <SelectInput source="dosageUnit" label="Единицы измерения" validate={[required()]} />
            <NumberInput source="quantity" label="Количество в упаковке" validate={[required()]} />
        </SimpleForm>
    </Edit>
}

export default {
    edit: LotEdit,
}