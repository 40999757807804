import React from 'react';
import { forwardRef, useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {useNotify, useRefresh} from 'react-admin';

const useStyles = makeStyles((theme: any) => ({
    backdrop: {
        "& div": {
            color: "#969696",
            background: "rgb(0 0 0 / 17%)",
        }
    }
}));


export default forwardRef((props: any, ref : any) => {
    const propsValue = props.record[props.source] ?? "";
    const [value, setValue] = useState(propsValue || "");
    const refresh = useRefresh();
    const notify = useNotify();

    const classes = useStyles();

    useEffect(() => {
        setValue(propsValue);

    }, [propsValue])

    const setValueToApi = async (apiFunc, textNotify) => {
        const respons = await apiFunc();
        const result = await respons?.json();
        if (result?.succeeded) {
            refresh();
            notify(textNotify);
            return true;
        }
        return false;
    }

    const addRefreshStyles = isRefresh =>{
        if(isRefresh){
            ref.current.style.background = "rgb(0 0 0 / 17%)";
            ref.current.style.color = "#969696";
        }
        else{
            ref.current.style.background = "rgb(255 255 255)";
            ref.current.style.color = "#000";
        }
    }

    const onChangeHandler = e => {
        setValue(e.target.value);
    }

    const handler = async (e) => {
        if ((!props.record[props.source] && value === "") || value === props.record[props.source])
            return;

        props.record[props.source] = value;
        
        if(!ref){
            await props.onBlurHandler(setValueToApi, value, props);
            return;
        }

        addRefreshStyles(true);
        await props.onBlurHandler(setValueToApi, value, props);
        addRefreshStyles(true);
        ref.current.focus();
        setTimeout(() => addRefreshStyles(false), 1000);
    }

    const onKeyDown = async e => {
        if(e.keyCode === 13 && !e.shiftKey){
            e.preventDefault()
            await handler(e);
        }
    }

    const onBlurHandler = async e => {
        await handler(e);
    }

    return (
        <form>
            <TextField
                inputRef={ref ?? null}
                type="text"
                id={`${props.variant}-basic-${props.source}-${props.record.id}`}
                variant={props.variant}
                label={props.hasLabel ? props.label : null}
                value={value}
                onKeyDown={onKeyDown}
                onBlur={onBlurHandler}
                onChange={onChangeHandler}
                className={props.class}
                InputLabelProps={{ shrink: true }}
                fullWidth={props.fullWidth}
                multiline={props.multiline}
                rows={props.rows}
                disabled={props.disabled}
                rowsMax={props.rowsMax}
            />
        </form>
    );
})