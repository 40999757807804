import {
    Button, Dialog,
    DialogActions, DialogContent, DialogTitle,
    Portal, Snackbar, TextField, Typography,
    makeStyles, CircularProgress
} from '@material-ui/core';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import MuiAlert from '@material-ui/lab/Alert';
import { stringify } from 'query-string';
import React, { useState } from "react";
import { fetchEnd, fetchStart, useNotify, usePermissions, useRefresh } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CommercialOfferType } from "../../../common/enums";
import { formatDateTime, isFederalManager, isManager } from "../../../common/helpers";
import { PrimaryButton } from "../../../components/GphcUIKit/Button";
import HtmlEditor from "../../../components/HtmlEditor";
import { entities } from "../../../configuration";
import api from "../../../dataProvider/api";
import { useCommercialOfferStyles } from './../styles';
import BubleContacts from './BubbleContacts';
import { ICommercialOffer, ISupplierContact } from './MakeCommercialOfferEntities';
import SupplierContactPicker from './SupplierContactPicker';
import DownloadCommercialOfferPdf from "../DownloadCommercialOfferASPdfButton";

const useDialogStyles = makeStyles({
    dialogRoot: {
        justifyContent: "space-between"
    }
})


export const MakeCommercialOfferButton = ({ record: order, offerType, collapseAllLots }:
    { record?: any, offerType: CommercialOfferType, collapseAllLots: () => void }) => {

    const dispatch = useDispatch();
    const notify = useNotify();
    const refresh = useRefresh();
    const { permissions } = usePermissions();
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [descriptionLoaded, setDescriptionLoaded] = useState(false);
    const [supplierContacts, setSupplierContacts] = useState<ISupplierContact[]>([])
    const [commercialOffer, setCommercialOffer] = useState<ICommercialOffer>({
        subject: `Коммерческое предложение по аукциону №${order.orderNumber}`,
        text: "",
    });

    const [supplier, setSupplier] = useState("");

    const [errorBarOpen, setErrorBarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const handleErrorClose = (event) => setErrorBarOpen(false);

    const classes = useCommercialOfferStyles();
    const classesDiaol = useDialogStyles();

    const close = () => setOpen(false);
    const openDialog = async () => {
        setOpen(true);
        collapseAllLots();
        if (!descriptionLoaded && order != null) {
            dispatch(fetchStart());
            
            let result = await api.getCommercialOfferDescription(order.id, offerType)
                dispatch(fetchEnd());

            if (result == null || (!result.succeeded && result.errorMessage == null)) {
                notify('Произошла ошибка', 'warning');
            } else if (result.errorMessage != null) {
                notify('Ошибка: ' + result.errorMessage, 'warning');
            } else {
                setCommercialOffer(prev => {
                    prev.text = result.data.messageBody;
                    return prev;
                });
                setSupplier(result.data.supplier);
                setDescriptionLoaded(true);
            }
        }
    }

    const submit = async () => {
        setLoading(true);
        dispatch(fetchStart());
        const result = await api.sendCommercialOffer(order.id, offerType, commercialOffer, supplierContacts);
        dispatch(fetchEnd());
        setLoading(false);
    
        if (result == null) {
            notify('Произошла ошибка', 'warning');
            return;
        }

        if (result.succeeded) {
            notify('Коммерческое предложение отправлено');
            close();
            refresh();
        } else {
            if (result.errorMessage != null) {
                setErrorBarOpen(true);
                setErrorMessage('Ошибка: ' + result.errorMessage);
            }
            else
                notify('Произошла ошибка', 'warning');
        }
    };

    const isButtonDisabled = () => {
        const isDisabled = supplierContacts.length <= 0 ||
            commercialOffer.subject === '' ||
            commercialOffer.text === '' || loading;

        return isDisabled;
    }

    const uploadButton = (text) => 
        <Button 
            startIcon={loading ? <CircularProgress size={20} /> : <div></div>} 
            disabled={isButtonDisabled()} 
            onClick={submit} 
            color="primary"
        >
            {text}
        </Button>;

    if (order == null)
        return null;

    const sendingDate = offerType === CommercialOfferType.First ? order.firstCommercialOfferSendingDate :
        order.secondCommercialOfferSendingDate;

    const sendingDateResult = renderSendingDate(sendingDate, order.orderNumber);

    if (!(isManager(permissions) || isFederalManager(permissions)))
        return sendingDateResult;

    if (offerType === CommercialOfferType.First && !order.canMakeFirstCommercialOffer)
        return sendingDateResult;

    if (offerType === CommercialOfferType.Second && !order.canMakeSecondCommercialOffer)
        return sendingDateResult;

    const supplierId = offerType === CommercialOfferType.First ? order.firstParticipantId : order.secondParticipantId;

    return <div className={classes.btnIcon}>
        <Portal>
            <Snackbar onClose={handleErrorClose} open={errorBarOpen} autoHideDuration={6000}>
                <MuiAlert onClose={handleErrorClose} elevation={6} variant="filled" severity="error">{errorMessage}</MuiAlert>
            </Snackbar>
        </Portal>
        <PrimaryButton className={classes.COButton} onClick={() => openDialog()} endIcon={<MailOutlineRoundedIcon />}>Отправить КП</PrimaryButton>
        {sendingDateResult}
        <Dialog className={classes.dialog} open={open} onClose={close} maxWidth="lg" fullWidth={true}>
            <DialogTitle>
                {supplier ? "Отправка КП для " + supplier : ""}
            </DialogTitle>
            <DialogContent>
                <BubleContacts supplierContacts={supplierContacts} setSupplierContacts={setSupplierContacts} />
                <div style={{ display: "flex", alignItems: "center" }}>
                    <SupplierContactPicker
                        supplierContacts={supplierContacts}
                        setSupplierContacts={setSupplierContacts}
                        supplierId={supplierId}
                    />
                </div>
                <TextField
                    onChange={(e) => {
                        const value = e.target?.value;
                        if (value) {
                            setCommercialOffer(prev => ({
                                    ...prev,
                                    subject: value
                                }))
                        }
                    }}
                    autoFocus
                    value={commercialOffer.subject} label="Тема" fullWidth={true}
                />
                <HtmlEditor
                    value={commercialOffer.text}
                    onEditorChange={(value) => {
                        setCommercialOffer(prev => {
                            prev.text = value
                            return prev;
                        });
                    }}
                />
            </DialogContent>
            <DialogActions classes={{root: classesDiaol.dialogRoot}}>
                <DownloadCommercialOfferPdf
                    orderId={order.id}
                    commercialOfferType={offerType}
                />
                <div>
                    <Button onClick={close} color="primary" >
                        Отмена
                    </Button>
                    {loading ? uploadButton("Отправка") : uploadButton("Отправить")}
                </div>
            </DialogActions>
        </Dialog>
    </div>;
}

const renderSendingDate = (sendingDate, orderNumber) => {
    if (sendingDate == null)
        return null;

    const text = "Отправлено " + formatDateTime(sendingDate);
    return <Typography display="block" variant="body2">
        <Link to={{
            pathname: "/" + entities.commercialOffers,
            search: stringify({
                page: 1,
                perPage: 25,
                filter: JSON.stringify({ q: orderNumber }),
            }),
        }}
        >{text}</Link>
    </Typography>;
}