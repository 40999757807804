import { config } from "../configuration";
import { CommercialOfferType } from "../common/enums";
import { Parameter } from "../entities/reports/ReportShow/dtoInterfaces";
import { getToken } from "../utils";
import { ICommercialOffer, ISupplierContact } from '../entities/orders/makeCommercialOffer/MakeCommercialOfferEntities';

const customFetch = async (relativeUrl: string, body: any, method: string, isFile = false) =>
  fetch(config.baseUrl + relativeUrl, {
    method,
    headers: getHeaders(isFile),
    body: body == null ? null : (isFile ? body : JSON.stringify(body)),
  });

const getHeaders = (isFile) => {  
  const headers = { };
  
  const token = getToken();
  if (token != null)
    headers["Authorization"] = `Bearer ${token}`;

  if (!isFile)
    headers["Content-Type"] = "application/json";

  return headers;
}

const fetchPost = (relativeUrl: string, body?: object, isFile: boolean = false) => 
  customFetch(relativeUrl, body, 'POST', isFile);
const fetchPut = (relativeUrl: string, body: object) => customFetch(relativeUrl, body, 'PUT');
const fetchGet = (relativeUrl: string) => customFetch(relativeUrl, null, 'GET');

const bulkSkuRegionRelevanceChangeActuality = async ( ids: number[], actual: boolean ) => {
  return sendPutRequest(`/skuRegionsRelevance/bulkAdd`, { ids, actual });
};

const bulkSkuCustomersRelevanceChangeActuality = async ( ids: number[], actual: boolean ) => {
  return sendPutRequest(`/skuCustomersRelevance/bulkAdd`, { ids, actual });
};

const bulkRegionsMapToArea = async ( regionIds: number[], areaId: number ) => {
  return sendPostRequest(`/areasOfResponsibility/addRegions`, { regionIds, areaId });
};

const bulkRegionsMapToNewArea = async ( regionIds: number[], areaName: number ) => {
  return sendPostRequest(`/areasOfResponsibility/createWithRegions`, { regionIds, areaName });
};

const bulkCustomersMapToArea = async ( customerIds: number[], areaId: number ) => {
  return sendPostRequest(`/areasOfResponsibility/addCustomers`, { customerIds, areaId });
};

const bulkCustomersMapToNewArea = async ( customerIds: number[], areaName: number ) => {
  return sendPostRequest(`/areasOfResponsibility/createWithCustomers`, { customerIds, areaName });
};

const addAreaToUser = async (userId: number, areaId: number) => {
  return sendPostRequest(`/users/addArea`, { userId, areaId });
};

const removeAreaFromUser = async (userId: number, areaId: number) => {
  try {
    const response = await fetchPost("/users/removeArea", { userId, areaId });
    return response;
  } catch {
    return null;
  }
};

const setSkuToLot = async (skuId: number, lotId: number) => {
  try {
    const response = await fetchPut("/lots/setSku", { skuId, lotId });
    return response;
  } catch {
    return null;
  }
};

const setStatusToLot = async (status: number, lotId: number) => {
  return sendPutRequest(`/lots/${lotId}/setStatus`, { status });
};


const getByLotSkuInfo = async (lotId: number, regionId) => {
  try {
    const response = await fetchGet(`/lots/info?lotId=${lotId}&regionId=${regionId}`);
    return response;
  } catch {
    return null;
  }
};

const getOneSkuLogisticsPriceBySkuId = async (skuId: number, regionId: number) => {
  try {
    const response = await fetchGet(`/logisticsPrice?skuId=${skuId}&regionId=${regionId}`);
    return response;
  } catch {
    return null;
  }
};

const getOneProductNomenclature = async (productNomenclatureId: number) => {
  try {
    const response = await fetchGet(`/nomenclatures/${productNomenclatureId}`);
    return response;
  } catch {
    return null;
  }
};

const setCommercialOffer = async (lotId: number, commercialOffer: number) =>{
  try {
    const response = await fetchPut(`/lots/${lotId}/setCommercialOffer`, {commercialOffer});
    return response;
  } catch {
    return null;
  }
}

const setSecondCommercialOffer = async (lotId: number, commercialOffer: number) =>{
  try {
    const response = await fetchPut(`/lots/${lotId}/setSecondCommercialOffer`, {commercialOffer});
    return response;
  } catch {
    return null;
  }
}

const setGovernmentContractPrice = async (lotId: number, price: number) =>{
  try {
    const response = await fetchPut(`/lots/${lotId}/setGovernmentContractPrice`, {price});
    return response;
  } catch {
    return null;
  }
}

const setInvoicePrice = async (lotId: number, price: number) =>{
  try {
    const response = await fetchPut(`/lots/${lotId}/setInvoicePrice`, {price});
    return response;
  } catch {
    return null;
  }
}

const setPurchaseOrderInitialStatus = async (orderId: number, status: number) =>{
  try {
    const response = await fetchPut(`/purchaseOrders/${orderId}/setPurchaseOrderInitialStatus`, {status});
    return response;
  } catch {
    return null;
  }
}

const setPurchaseOrderManagerInitialStatus = async (orderId: number, status: number) =>{
  try {
    const response = await fetchPost(`/purchaseOrders/${orderId}/setManagerStatus`, { managerStatus: status });
    return response;
  } catch {
    return null;
  }
}

const setPurchaseOrderFinalStatus = async (orderId: number, status: number) =>{
  try {
    const response = await fetchPut(`/purchaseOrders/${orderId}/setPurchaseOrderFinalStatus`, {status});
    return response;
  } catch {
    return null;
  }
}

const setFirstParticipant = async (orderId: number, firstParticipantId: number) =>{
  try {
    const response = await fetchPut(`/purchaseOrders/${orderId}/setFirstParticipant`, {firstParticipantId});
    return response;
  } catch {
    return null;
  }
}

const setSecondParticipant = async (orderId: number, SecondParticipantId: number) =>{
  try {
    const response = await fetchPut(`/purchaseOrders/${orderId}/setSecondParticipant`, {SecondParticipantId});
    return response;
  } catch {
    return null;
  }
}

const setComments = async (orderId: number, comments: string) =>{
  try {
    const response = await fetchPut(`/purchaseOrders/${orderId}/setComments`, {comments});
    return response;
  } catch {
    return null;
  }
}

const setParticipantDistributorLogistician = async (orderId: number, participantDistributorLogisticianId: number) =>{
  try {
    const response = await fetchPut(`/purchaseOrders/${orderId}/setParticipantDistributorLogistician`, {participantDistributorLogisticianId});
    return response;
  } catch {
    return null;
  }
}


const setParticipantWinner = async (orderId: number, participantWinnerId: number) =>{
  try {
    const response = await fetchPut(`/purchaseOrders/${orderId}/setParticipantWinner`, {participantWinnerId});
    return response;
  } catch {
    return null;
  }
}

const setManager = async (orderId: number, managerId: number) =>{
  try {
    const response = await fetchPut(`/purchaseOrders/${orderId}/setManager`, { managerId });
    return response;
  } catch {
    return null;
  }
}

const setLotComments = async (lotId: number, comments: number) =>{
  try {
    const response = await fetchPut(`/lots/${lotId}/setComments`, { comments });
    return response;
  } catch {
    return null;
  }
}

const setProducer = async (lotId: number, producerId: number) =>{
  try {
    const response = await fetchPut(`/lots/${lotId}/setProducer`, { producerId });
    return response;
  } catch {
    return null;
  }
}

const setManulTotalQuantity = async (lotId: number, quantity: number) => {
  try {
    const response = await fetchPost(`/lots/${lotId}/setManualTotalQuantity`, { quantity });
    return response;
  } catch {
    return null;
  }
}

const setAutomaticTotalQuantity = async (lotId: number) => {
  try {
    const response = await fetchPost(`/lots/${lotId}/setAutomaticTotalQuantity`);
    return response;
  } catch {
    return null;
  }
}

const startOrderPricesApproval = async (orderId: number, justification: string, justificationSubject: string, 
  justificationBody: string) => {
  return sendPostRequest(`/purchaseOrders/${orderId}/startPricesApproval`, { justification, justificationSubject, justificationBody });
}

const finishOrderPricesApproval = async (orderId: number, response: string, responseSubject: string, responseBody: string) => {
  return sendPutRequest(`/purchaseOrders/${orderId}/finishPricesApproval`, { response, responseSubject, responseBody });
}

const approvePrice = async (lotId: number, commercialOfferType: CommercialOfferType, approve: boolean) => {
  return sendPutRequest(`/lots/${lotId}/approvePrice`, { commercialOfferType, approve })
}

const actualizeSkuPrice = async (lotId: number) => {
  return sendPutRequest(`/lots/${lotId}/actualizeSkuPrice`);
}

const setSqlToAQB = async (id: number, token: string) => {
  return sendPutRequest(`/reportVersions/setQuery/${id}`, { token })
}

const getTypeColumns = async (id: number, token: string, parameters: string) => {
  const result = await fetchGet(`/reportVersions/getTypeColumns/${id}?token=${token}&parameters=${parameters}`);
  try {
    const data = await result.json();
    return data.data;
  }
  catch {
    return null;
  }
}


const sendCommercialOffer = async (orderId: number, commercialOfferType: CommercialOfferType, 
  commercialOffer: ICommercialOffer, supplierContacts: ISupplierContact[]) => {
    return sendPostRequest(`/purchaseOrders/${orderId}/sendCommercialOffer`, 
      { offerType: commercialOfferType, mailSubject: commercialOffer.subject, mailText: commercialOffer.text, supplierContacts });
}

const getCommercialOfferDescription = async (orderId: number, type: CommercialOfferType) => {
  return (await fetchGet(`/purchaseOrders/${orderId}/getCommercialOfferDescription/${type}`)).json();
}

const confirmFileImport = async (token: string) => {
  return (await fetchPost(`/dictionaryImport/importFile/${token}`)).json();
}

const uploadDictionary = async (file: any, configId: number) => {  
  const formData = new FormData();
  formData.append('file', file as Blob);
  formData.append('configId', configId.toString());

  return (await fetchPost('/dictionaryImport/uploadFile/', formData, true)).json();
}

const manualUpdate = async (file: any, configId: number) => {  
  const formData = new FormData();
  formData.append('file', file as Blob);
  formData.append('configId', configId.toString())
  return (await fetchPost('/dictionaryImport/update', formData, true));
}

const getPriceApprovalJustificationShort = async (orderId: number) => {
  return (await fetchGet(`/purchaseOrders/${orderId}/getPriceApprovalJustificationShort`)).json();
}

const getPriceApprovalJustification = async (orderId: number, justification: string) => {
  return (await fetchGet(`/purchaseOrders/${orderId}/getPriceApprovalJustification?justification=${justification}`)).text();
}

const getPriceApprovalResponseShort = async (orderId: number) => {
  return (await fetchGet(`/purchaseOrders/${orderId}/getPriceApprovalResponseShort`)).json();
}

const getPriceApprovalDescription = async (approvalId: number) => {
  return (await fetchGet(`/pricesApprovals/${approvalId}/description`)).json();
}

const getPriceApprovalResponse = async (orderId: number, response: string) => {
  return (await fetchGet(`/purchaseOrders/${orderId}/getPriceApprovalResponse?response=${response}`)).text();
}

const getPersistentLogFilterValues = async () => {
  return (await fetchGet('/persistentLog/filterValues')).json();
}

const getStockBalanceFilterValues = async () => {
  return (await fetchGet('/stockBalance/filterValues')).json();
}

const getSkuPriceFilterValues = async () => {
  return (await fetchGet('/skuPrices/filterValues')).json();
}

const getLogisticsPriceFilterValues = async () => {
  return (await fetchGet('/logisticsPrice/filterValues')).json();
}

const getInnFilterValues = async () => {
  return (await fetchGet('/inns/filterValues')).json();
}

const getMaxWholesaleMarkupFilterValues = async () => {
  return (await fetchGet('/maxWholesaleMarkups/filterValues')).json();
}

const getSkuCustomerRelevanceFilterValues = async () => {
  return (await fetchGet('/skuCustomersRelevance/filterValues')).json();
}

const getSkuRegionRelevanceFilterValues = async () => {
  return (await fetchGet('/skuRegionsRelevance/filterValues')).json();
}

const downloadImportLog = async (logId) => {
  return fetchGet('/persistentLog/downloadImportLog/' + logId);
}

const getReportVersions = async (reportId : number) => {
  return fetchGet('/reportVersions?reportId=' + reportId);
}

const actualizeSku = async (skuId: number) => {
  return await sendPutRequest(`/skus/${skuId}/actualizeSku`);
}

const deactualizeSku = async (skuId: number) => {
  return await sendPutRequest(`/skus/${skuId}/deactualizeSku`);
}

const getDataFromReportQuery = async (token: string, reportVersionId: number, parameters: Array<Parameter> | null, start: number, end: number) => {
  if (parameters !== null && parameters.length !== 0) {
    return fetchPost(`/reportVersions/${reportVersionId}/executeQuery/${token}?start=${start}&end=${end}`, parameters);
  }
  else {
    return fetchPost(`/reportVersions/${reportVersionId}/executeQuery/${token}?start=${start}&end=${end}`);
  }
}

const ignoreNomenclature = async (productNomenclatureId: any, ignoreComment: any) => {
  return sendPostRequest('/nomenclatures/ignore', { productNomenclatureId, ignoreComment });
}

const mapNomenclature = async (productNomenclatureId: any, ampuleVolume: any, skuId: any) => {
  return sendPostRequest('/nomenclatures/map', { productNomenclatureId, ampuleVolume, skuId });
}

const sendResetPasswordUrl = async (userId: string) => {
  return sendPostRequest('/account/sendResetPasswordUrl/' + userId);
}

const activateLots = async (orderId: string, activeLotsIds: any[]) => {
  return sendPostRequest(`/purchaseOrders/${orderId}/activateLots`, { activeLotsIds });
}

const forgotPassword = async (email: string, captchaResult: string) => {
  return sendPostRequest('/account/forgotPassword/', { email, captchaResult });
}

const exportReport = async (reportVersionId: number, token: string, parameters?: Parameter[]) => {
  return await fetchPost(`/reportVersions/${reportVersionId}/export?token=${token}`, parameters);
}

const checkResetPasswordToken = async (token: string, userId: number): Promise<boolean> => {
  const response = await sendPostRequest('/account/checkResetPasswordToken', { token, userId });
  return response.valid;
}

const resetPassword = async (token: string, userId: number, newPassword: string) => {
  return sendPostRequest('/account/resetPassword', { token, userId, newPassword });
}

const сreateQueryBuilder = async (name: string) => {
  return sendPostRequest(`/queryBuilder/createQueryBuilder?name=${name}`);
}

const downloadCommercialOfferPdf = async (orderId: number, commercialOfferType: CommercialOfferType) => {
  return fetchPost(`/purchaseOrders/${orderId}/downloadCommercialOfferPdf`, { offerType: commercialOfferType })
}

const sendPutRequest = async (path: string, payload?: any) => {
  return executeRequest(() => fetchPut(path, payload));
}

const sendPostRequest = async (path: string, payload?: any) => {
  return executeRequest(() => fetchPost(path, payload));
}

const executeRequest = async (request: () => Promise<any>) => {
  try {
    return (await request()).json();
  } catch {
    return null;
  }
}

interface ActionOutput {
  Succeeded: boolean,
  ErrorCode: ErrorCode 
  Data: any
  ErrorMessage: string
}

enum ErrorCode {
  GenericError,
  NotFound,
  Unauthorized,
  Forbidden,
}


export default {
  bulkRegionsMapToArea,
  bulkCustomersMapToArea,
  bulkRegionsMapToNewArea,
  bulkCustomersMapToNewArea,
  addAreaToUser,
  removeAreaFromUser,
  setSkuToLot,
  setCommercialOffer,
  setSecondCommercialOffer,
  getByLotSkuInfo,
  getOneSkuLogisticsPriceBySkuId,
  getOneProductNomenclature,
  setGovernmentContractPrice,
  setInvoicePrice,
  setPurchaseOrderInitialStatus,
  setPurchaseOrderFinalStatus,
  setFirstParticipant,
  setSecondParticipant,
  setComments,
  setParticipantDistributorLogistician,
  setParticipantWinner,
  setManager,
  setLotComments,
  setProducer,
  startOrderPricesApproval,
  finishOrderPricesApproval,
  approvePrice,
  setStatusToLot,
  sendCommercialOffer,
  getCommercialOfferDescription,
  getPriceApprovalJustificationShort,
  getPriceApprovalJustification,
  getPriceApprovalResponseShort,
  getPriceApprovalResponse,
  uploadDictionary,
  confirmFileImport,
  mapNomenclature,
  ignoreNomenclature,
  getPriceApprovalDescription,
  actualizeSkuPrice,
  bulkSkuRegionRelevanceChangeActuality,
  bulkSkuCustomersRelevanceChangeActuality,
  forgotPassword,
  checkResetPasswordToken,
  resetPassword,
  sendResetPasswordUrl,
  activateLots,
  getPersistentLogFilterValues,
  getStockBalanceFilterValues,
  getSkuPriceFilterValues,
  getLogisticsPriceFilterValues,
  getMaxWholesaleMarkupFilterValues,
  getSkuCustomerRelevanceFilterValues,
  getSkuRegionRelevanceFilterValues,
  downloadImportLog,
  setSqlToAQB,
  сreateQueryBuilder,
  getReportVersions,
  getDataFromReportQuery,
  exportReport,
  getTypeColumns,
  setManulTotalQuantity,
  setAutomaticTotalQuantity,
  actualizeSku,
  deactualizeSku,
  downloadCommercialOfferPdf,
  setPurchaseOrderManagerInitialStatus,
  manualUpdate,
  getInnFilterValues,
};
