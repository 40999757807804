import * as React from 'react';
import TextFieldWithIcon from './TextFieldWithIcon';

import { CheckCircle, Warning } from '@material-ui/icons';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

const SuccessFailureTextField = (props) => {
    const icon = props.success == null ?
        null : props.success ?
            <CheckCircle style={{color: '#44b35e'}} /> : <ErrorRoundedIcon style={{color: '#FFA250'}} />;
    return <TextFieldWithIcon icon={icon} {...props} />;
};

export default SuccessFailureTextField;
