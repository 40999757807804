import * as React from 'react';
import { NullableBooleanInput } from 'react-admin';
import { makeStyles } from '@material-ui/styles';

export default (props) => {
    const { options, placeholder, useLabelAsPlaceholder = false, isNotDefaultColor = false, ...rest } = props;
    const color = isNotDefaultColor ? {inputProps: { style: { color: "#aaa" }}} : null;
    return <NullableBooleanInput
                options={{ placeholder: useLabelAsPlaceholder && props.label != null ? props.label : placeholder ?? 'Выбрать', ...color }} 
                {...rest} 
        />
};
