import React, { useEffect } from 'react';
import { AutocompleteArrayInput as AutocompleteInputAdmin } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { PUSH_BUBBLE_OBJECT } from '../store/modules/bubbles/types';

const useStyles =  makeStyles((theme) => ({
    inputRoot: {
        paddingLeft: '0px',
        color: 'black'
    },
    chipContainerFilled: {
        paddingLeft: '0px',
        display: 'none'
    }
}))


const getChoicesForBubbles = (bubbles, entity, source, choices) => {
    if (bubbles == null || entity == null || source == null)
        return [];

    const bubble = bubbles[entity];
    if (bubble == null)
        return false;
    
    const bubblesChoices = bubble.find(bc => bc.source == source);
    if (bubblesChoices == null)
        return choices;

    return choices.filter(c => bubblesChoices.choices.find(bc => bc.id == c.id) == null);
}

const AutocompleteInput = (props) => {
    const classes = useStyles()
    const { options, placeholder, useLabelAsPlaceholder = false, isNotDefaultColor = false, ...rest } = props;
    const { rootState } = props;
    const { rootState: { bubbles: { bubbles } } } = props;
    const { router } = rootState;
    const pathname = router.location.pathname.split('/')[1]
    const { addChoicesToBubbles, resource, source, choices } = props;

    // useEffect(() => {
    //     addChoicesToBubbles({
    //         key: pathname,
    //         choices: { source: props.source, label: props.label, choices: props.choices }
    //     })
    // }, [])

    useEffect(() => {
        const choicesToAdd = getChoicesForBubbles(bubbles, resource, source, choices);
        if (choicesToAdd.length != 0 && rest.input?.value?.length !== 0) {
            addChoicesToBubbles({
                key: resource,
                choices: { source: source, label: props.label, choices: choicesToAdd }
            });
        }
    }, [choices])

    const notSelectedValue = rest.input?.value?.length === 0;

    const styles = {}

    if (isNotDefaultColor) {
        styles['style'] = { color: notSelectedValue ? "#aaa" : "#000" }
    }

    const innerOptions = { placeholder: useLabelAsPlaceholder && props.label !== null ? props.label : placeholder ?? 'Выбрать' };

    return <AutocompleteInputAdmin options={{...innerOptions}} {...rest} classes={classes} />;
}

function mapDispatchToProps(dispatch) {
    return {
        addChoicesToBubbles: (payload) => dispatch({ type: PUSH_BUBBLE_OBJECT, payload: payload }),
    }
}

export default connect(state => ({ rootState: state }), mapDispatchToProps)(AutocompleteInput);