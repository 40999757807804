import * as React from 'react';
import { useState } from 'react';
import { SelectInput, SimpleForm } from 'react-admin';
import MapBulkButtonLayout from './MapBulkButtonLayout'


const MapBulkButton = ({selectedIds, data, handleClick} : MapBulkButtonProps ) => {
    const [areaId, setAreaId] = useState(-1);

    const handle = handleClick(selectedIds, areaId);
    const header = "Добавить в зону ответственности";
    const cancelButtonText = "Отмена";
    const acceptButtonOptions = { autoFocus: true, disabled: areaId === -1 };
    const acceptButtonText = "Сопоставить";
    const dialogContent = <CustomSelect data={data} setAreaId={setAreaId} />;
    const dialogContentText = "Выберите зону ответственности";
    const dialogTitle = "Добавить в зону ответственности";


    return(
        <MapBulkButtonLayout
            handleClick={handle}
            header = {header}
            cancelButtonText={cancelButtonText}
            acceptButtonOptions={acceptButtonOptions}
            acceptButtonText={acceptButtonText}
            dialogContentText={dialogContentText}
            dialogTitle={dialogTitle}
            dialogContent={dialogContent}
        />
    );
}


const CustomSelect = ({data, setAreaId}) =>{
    return(
    <SimpleForm toolbar={null} basePath="">
        <SelectInput
            fullWidth
            allowEmpty
            label="Зона ответственности"
            optionValue="id"
            source="areaId"
            onChange={value =>setAreaId(value.target.value === "" ? -1 : value.target.value)}
            choices={
                data.map((elm)=> {
                    return {
                        id: elm['id'], 
                        name: elm['name'] 
                    } 
                })} />
    </SimpleForm>
    );
};

type MapBulkButtonProps = {
    selectedIds : number[], 
    data : object[],
    handleClick : (selectedIds : number[], areaId: number)=>()=>Promise<void>;
  }

export default MapBulkButton;