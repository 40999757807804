import React from 'react';
import { DateField, SelectField, TextField, ReferenceField, NumberField, BooleanField } from 'react-admin';
import { 
    HistoryFieldType, LotPriceApprovalStatus, LotResultStatus, PurchaseOrderLotType, 
    PurchaseOrderPriceApprovalCommonStatus, PurchaseOrderInitialStatus, DosageUnit,
    PurchaseOrderFinalStatus, PurchaseOrderPriceApprovalStatus, CommercialOfferType
}  from "../../common/enums"
import CurrencyField from '../CurrencyField';
import PercentField from '../PercentField';
import { 
    LotPriceApprovalStatusHistoryChangeChoices, LotResultStatusChoices, 
    PurchaseOrderLotTypeChoices, PurchaseOrderPriceApprovalCommonChoices, 
    PurchaseOrderProcessingStatusChoices, PurchaseOrderResultStatusChoices,
    PurchaseOrderPriceApprovalStatusHistoryChangeChoices, CommercialOfferTypeChoices,
    DosageUnitChoices
}  from "../../common/choices";


export default (props) => {
    const { GetProps, ...rest } = props;
    const np = props.GetProps(rest);
    const reference = np?.reference;
    const type = np?.type;
    const newProps = np?.newProps;

    if (newProps?.record?.value === null) {
        return <TextField source="value" {...newProps}/>
    }


    switch (type) {
        case HistoryFieldType.Date: {
            return <TextField source="value" {...newProps} />;
        }
        case HistoryFieldType.IdDate: {
            return (
                <ReferenceField reference={props.record.reference} source="value" {...newProps} link={false}>
                    <DateField source={props.record.field} showTime/>
                </ReferenceField>
            );
        }
        case HistoryFieldType.String: {
            if (!reference)
                return <TextField source="value" {...newProps} />;
            else if (reference === "nomenclatures") {
                return (
                    <ReferenceField reference={props.record.reference} source="value" {...newProps} link={false}>
                        <InnCustomTextField />
                    </ReferenceField>
                );
            }
            else {
                return (
                    <ReferenceField reference={props.record.reference} source="value" {...newProps} link={false}>
                        <TextField source={props.record.field} />
                    </ReferenceField>
                );
            }
        }
        case HistoryFieldType.Number: {
            newProps.record.value = Number.parseFloat(newProps.record.value);
            return <NumberField source="value" {...newProps} />;
        }
        case HistoryFieldType.Boolean: {
            newProps.record.value = newProps.record.value === "True";
            return <BooleanField source="value" {...newProps} />;
        }
        case HistoryFieldType.Currency: {
            newProps.record.value = Number.parseFloat(newProps.record.value);
            return <CurrencyField source="value" {...newProps} />;
        }
        case HistoryFieldType.Percent: {
            newProps.record.value = Number.parseFloat(newProps.record.value);
            return <PercentField source="value" {...newProps} />;
        }
        case HistoryFieldType.LotPriceApprovalStatusChoices: {
            return GetChoicesField(LotPriceApprovalStatusHistoryChangeChoices, LotPriceApprovalStatus, newProps);
        }
        case HistoryFieldType.LotResultStatusChoices: {
            return GetChoicesField(LotResultStatusChoices, LotResultStatus, newProps);
        }
        case HistoryFieldType.PurchaseOrderLotTypeChoices: {
            return GetChoicesField(PurchaseOrderLotTypeChoices, PurchaseOrderLotType, newProps);
        }
        case HistoryFieldType.PurchaseOrderPriceApprovalCommonChoices: {
            return GetChoicesField(PurchaseOrderPriceApprovalCommonChoices, PurchaseOrderPriceApprovalCommonStatus, newProps);
        }
        case HistoryFieldType.PurchaseOrderProcessingStatusChoices: {
            return GetChoicesField(PurchaseOrderProcessingStatusChoices, PurchaseOrderInitialStatus, newProps);
        }
        case HistoryFieldType.PurchaseOrderResultStatusChoices: {
            return GetChoicesField(PurchaseOrderResultStatusChoices, PurchaseOrderFinalStatus, newProps);
        }
        case HistoryFieldType.PurchaseOrderPriceApprovalStatus: {
            return GetChoicesField(PurchaseOrderPriceApprovalStatusHistoryChangeChoices, PurchaseOrderPriceApprovalStatus, newProps);
        }
        case HistoryFieldType.CommercialOfferType: {
            return GetChoicesField(CommercialOfferTypeChoices, CommercialOfferType, newProps);
        }
        case HistoryFieldType.DosageUnitType: {
            return GetChoicesField(DosageUnitChoices, DosageUnit, newProps);
        }
        default:
            return <TextField source="value" {...newProps} />;
    }
};

const InnCustomTextField = (props) => {
    const { innName, dosageForm, dosage } = props.record;
    return (
        <span>МНН: {innName} ЛФ: {dosageForm} Дозировка: {dosage}</span>
    );
}

const GetChoicesField = (choices, enumType, newProps) => {
    const resultParse = Number.parseInt(newProps.record.value);

    if (!Number.isNaN(resultParse)) {
        newProps.record.value = resultParse;
        return <SelectField source="value" {...newProps} choices={choices} />;
    }
    else {
        const value = enumType[newProps.record.value];
        if (!Number.isInteger(value)) {
            return <TextField source="value" {...newProps} />;
        }
        newProps.record.value = value;
        return <SelectField source="value" {...newProps} choices={choices} />
    }   
};