import React from 'react';
import { WriteActionType } from '../../common/enums';
import { NameDictionaryImport } from '../../common/helpers';
import { getErrorCount } from '../../entities/dictionaryImport/canApplyImport'

export function renderEntityImportStat(stat, configChoice: any, passportConfigId: any) {
    if (stat == null)
        return null;

    if (configChoice && passportConfigId && configChoice === passportConfigId)
        return <React.Fragment>
                <div><strong className="table_name">{NameDictionaryImport}</strong></div>
                <div>Всего строк: {stat.allRows}</div>
                <br />
                <div>Успешно загруженных: {stat.success}</div>
                <br />
                <div>Строк без изменений: {stat.skipped}</div>
                <br />
                <div>Строки с ошибками: {stat.errors}</div>
            </React.Fragment>;

    const needRender = stat.writeActionStats.some(a => a.action != WriteActionType.Noop && a.action != WriteActionType.Find) || 
        getErrorCount(stat.writeActionStats) != 0;
    if (!needRender)
        return null;

    return <React.Fragment>
            <div><strong className="table_name">{stat.localizedEntityName}</strong></div>
            {stat.writeActionStats.map(a => renderWriteAction(a))}
            <br />
        </React.Fragment>;
}

const renderWriteAction = (action) => {
    const { count, errors, errorsList, records } = action;
    const hasErrors = errors != 0;
    const rowsProcessed = count - errors;
    const actionsPerformed = action.action == WriteActionType.Update || action.action == WriteActionType.Insert;
    const title = `${getActionTypeName(action.action)} ${rowsProcessed} строк` + (!hasErrors && actionsPerformed ? ", ошибок нет" : "");
    const actionTypeInf = getActionTypeNameInf(action.action);

    return <React.Fragment>{rowsProcessed != 0 && action.action != WriteActionType.Find && <div className="table_action_name">{title}</div>}
            {hasErrors && <span className="error_rows">Не смогли {actionTypeInf} <span style={{color: 'red'}} className="rows_success_num">{errors}</span> строк</span>}
            {hasErrors && errorsList != null && errorsList.map(e => renderError(e))}
            {records != null && records.length != 0 && 
                <React.Fragment> 
                    <span>Добавлены позиции:</span>
                    {records.map(r => renderRecord(r))}
                </React.Fragment>
            }
        </React.Fragment>;
}

const getActionTypeName = (actionType: WriteActionType) => {
    switch (actionType) {
        case WriteActionType.Skip: return "Не требует обновления";
        case WriteActionType.Update: return "Успешно обновлено";
        case WriteActionType.Insert: return "Успешно добавлено";
    }
}

const getActionTypeNameInf = (actionType: WriteActionType) => {
    switch (actionType) {
        case WriteActionType.Update: return "обновить";
        case WriteActionType.Insert: return "добавить";
        case WriteActionType.Find: return "найти";
    }
}

const renderRecord = (record: any) => {
    const values: any[] = [];

    for (let key in record)
        values.push(record[key]);

    return <div>{values.map(v => `"${v}"`).join(', ')}</div>;
}

const renderError = (error) => {
    const { message, errorRows } = error;

    return <div>
        <div className="error_rows_message">{message} в строках:
            <span> {errorRows.map((row, i) =>
                <span className="error_rows_row">
                    <span style={{fontWeight: 'bold'}} className="error_rows_line">{row.rowId}{i != errorRows.length - 1 ? "," : ""} </span>
                </span>
            )}
            </span>
        </div>
    </div>
}