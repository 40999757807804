import * as React from 'react';
import {
    useShowController, SimpleShowLayout, ReferenceManyField,Datagrid, DateField
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from "../../components/DefaultPagination";
import {entities} from "../../configuration";
import CurrencyField from "../../components/CurrencyField";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
}));

const SkuPriceShow = ({...props }) => {
    const classes = useStyles();
    const controllerProps = useShowController(props);

    if (!controllerProps.record) {
        return null;
    }
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    История
                </Typography>
                <IconButton onClick={props.onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleShowLayout
                className={classes.form}
                basePath={controllerProps.basePath}
                record={controllerProps.record}
            >
                <ReferenceManyField
                    perPage={10}
                    pagination={<Pagination />}
                    reference={entities.logisticsPrice}
                    target="skuId"
                    label=""
                    source="skuId"
                    filter={{regionId: controllerProps.record.regionId}}
                    sort={{ field: 'validFrom', order: 'ASC' }}
                >
                    <Datagrid >
                        <DateField label="Дата загрузки" source="validFrom" />
                        <CurrencyField source="price" label="Ст-ть логистики 1 м3 в регион" />
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </div>
    );
};

export default SkuPriceShow;