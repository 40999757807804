import * as React from "react";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LotResultStatusChoices } from "../../../../../common/choices";
import { LotPriceApprovalStatus } from '../../../../../common/enums';
import Texts from "../../../../../common/tooltipCalculatedTexts";
import AutocompleteInputSingle from "../../../../../components/GphcUIKit/AutocompleteInputSingle";
import {
    DateField, Datagrid, fetchEnd, fetchStart, NumberField,
    useRefresh, usePermissions,SimpleForm, SimpleShowLayout, TextField, useNotify,
    FunctionField, 
} from "react-admin";
import { Button, Grid, Tooltip as MUITooltip, IconButton } from "@material-ui/core";
import CurrencyField from "../../../../../components/CurrencyField";
import EditableNumberField from "../../../../../components/EditableNumberField";
import EditableTextField from "../../../../../components/EditableTextField";
import SelectInputSingle from "../../../../../components/GphcUIKit/SelectInputSingle";
import PercentField from "../../../../../components/PercentField";
import MyReferenceInput from "../../../../../components/ReferenceInput";
import Tooltip from '../../../../../components/WrapperTooltip';
import { entities } from "../../../../../configuration";
import StyledBooleanField from '../../../../../customTheme/StyledBooleanField.js';
import api from "../../../../../dataProvider/api";
import SkuInfo from "../../../../skus/skuInfo";
import LotPriceApproval from "../../../LotPriceApproval";
import handlers from "./../../LotsOnBlurHandlers";
import { CheckCircle, ErrorRounded, MoreHoriz, Cancel, InfoOutlined, Autorenew, Create } from '@material-ui/icons';

import RelevanceField from "../../../../../components/GphcUIKit/RelevanceField";
import LotPriceRelevance from "../../../LotPriceRelevance";
import { createOnChangeWrapper, isAdmin, isManager, isObserver, useGridFixedColumnStyles } from "../../../../../common/helpers";
import { useLotStyles } from './../lotStyle';


const StyledEditableNumberField = (props) => {
    const classes = useLotStyles(props);
    return (
        <div className={classes.lotInput}>
            <EditableNumberField {...props} />
        </div>
    )
}

const StyledCommercialOfferCurrencyField = (props) => {
    const classes = useLotStyles(props);
    const st = { width: 17, height: 17 }
    const getStatus = (status) => {
        switch (status) {
            case LotPriceApprovalStatus.Approved:
                return <MUITooltip title="Цена Согласована" ><CheckCircle style={{ color: '#44b35e', ...st }} /></MUITooltip>
            case LotPriceApprovalStatus.None:
                return null;
            case LotPriceApprovalStatus.Required:
                return <MUITooltip title="Требуется согласование" ><ErrorRounded style={{ color: '#FFA250', ...st }} /></MUITooltip>
            case LotPriceApprovalStatus.Declined:
                return <MUITooltip title="Отклонена цена" ><Cancel style={{ color: '#FE7C8F', ...st }} /></MUITooltip>
            case LotPriceApprovalStatus.OnApproval:
                return <MUITooltip title="Цена на согласовании" ><MoreHoriz style={{ color: 'gray', ...st }} /></MUITooltip>
            default:
                break;
        }
    }

    const getStatusCOPrice = (props) => {
        if (props.source === 'firstCommercialOffer') {
            return getStatus(props.record.firstCommercialOfferApprovalStatus)
        }
        if (props.source === 'secondCommercialOffer') {
            return getStatus(props.record.secondCommercialOfferApprovalStatus);
        }
        return null;
    }

    if (props.record == null)
        return null;

    const { record: { isSkuActual } } = props;
    const disabled = props.disabled || !isSkuActual;

    return (
        <div className={classes.lotInput}>
            {getStatusCOPrice(props)}
            {disabled ? <CommercialOfferCurrencyField {...props} /> : <EditableNumberField {...props} />}
        </div>
    )
}

const CommercialOfferCurrencyField = (props) => {
    const { source, record } = props;
    if (record == null || source == null)
        return <CurrencyField {...props} />;

    const innerRecord = { ...record };
    innerRecord[source] = record[source] ?? 0;

    const { record: origRecord, ...rest } = props;
    const innerProps = { record: innerRecord, ...rest };

    return <CurrencyField {...innerProps} />;
}

const StyledProducerSelector = (props) => {
    const classes = useLotStyles();
    return (
        <div className={classes.sSelector}>
            <ProducerSelector {...props} />
        </div>
    )
}

const StyledStatusSelector = (props) => {
    const classes = useLotStyles();
    return (
        <div className={classes.sSelector}>
            <StatusSelector {...props} />
        </div>
    );
}




const LotsGrid = props => {
    const classes = useLotStyles();
    const gridClasses = useGridFixedColumnStyles(2);
    const [selectionInitialized, setSelectionInitialized] = useState(false);

    const lotRowStyle = (record, index) => ({
        backgroundColor: record?.isCommercialOfferOnApproval ? 'rgba(254, 124, 143, 0.1)' : 'white',
        opacity: record?.active ? '1' : '0.5',
    });

    const getFocus = () => {
        let grid = document.getElementsByClassName("overflow-left-grid")[0];
        //@ts-ignore
        return grid.scrollLeft;
    }

    const setFocus = (scroll) => {
        //@ts-ignore
        let newGrid = document.getElementsByClassName("overflow-left-grid")[0];
        newGrid.scrollLeft = scroll;
    }

    const { record: { isFirstCommercialOfferOnApproval, isSecondCommercialOfferOnApproval, hasAdditionalLots } } = props?.ordersProps;
    const { permissions } = usePermissions();
    const { editMode, selectedIds, passSelectedIds, onSelect, data } = props;
    const isReadonly = isObserver(permissions);

    passSelectedIds(selectedIds);

    if (!selectionInitialized && editMode && data != null) {
        const ids: number[] = [];
        for (let key in data)
            if (data[key].active)
                ids.push(+key);

        onSelect(ids);
        setSelectionInitialized(true);
    }

    return (
        <Datagrid
            expand={<ExpandLots />}
            className={gridClasses.dataGrid}
            classes={{ rowCell: classes.gridItem }}
            rowStyle={lotRowStyle}
            hasBulkActions={editMode}
        >
            {editMode && <DateField showTime source="externalSourceUpdateDate" label="Дата"  />}
            <SkuSelector 
                classes={classes} 
                label="SKU" 
                setFocus={setFocus} 
                getFocus={getFocus} 
                headerClassName={classes.static}
                cellClassName={classes.static}
            />
            <StyledBooleanField source="isActual" label={"Актуальность по региону/ЛПУ"} />
            <StyledBooleanField source="isAdditionalLot" label={"Доп. лот"} />
            <TextField source="quantity" label="Кол-во уп. (ИАС)" />
            <TotalUnitQuantityField
                permissions={permissions}
                label={<Tooltip text={Texts.totalUnitQuantity} label="Кол-во уп. (расчет)" />}
                cellClassName={classes.totalUnitQuantityCell}
            />
            {/* <TextField
                source="totalUnitQuantity"
                label={<Tooltip text={Texts.totalUnitQuantity} label="Кол-во уп. (расчет)" />}
            /> */}
            <CurrencyField
                source="startingMinimalUnitPrice"
                label={<Tooltip text={Texts.maxPriceForRegion} label="НМЦ за 1 уп. (руб. с НДС) (расчёт)" />}
            />
            <CurrencyField source="vitalDrugsPrice" label="Цена реестра, руб. без НДС" />
            <CurrencyField
                source="maxSellingPriceInRegion"
                label={<Tooltip text={Texts.maxPriceForRegion} label="MAX возможная цена продажи в данном регионе, руб. с НДС" />}
            />
            <CostPrice label="Цена прайса, (руб. с НДС)" />
            <StyledCommercialOfferCurrencyField
                source="firstCommercialOffer"
                label="КП 1, руб. с НДС"
                onBlurHandler={handlers.setCommercialOffer}
                classes={classes.lotInput}
                placeholder={"0"}
                disabled={isReadonly || isFirstCommercialOfferOnApproval}
            />
            <StyledCommercialOfferCurrencyField
                source="secondCommercialOffer"
                label="КП 2, руб. с НДС"
                onBlurHandler={handlers.setSecondCommercialOffer}
                classes={classes.lotInput}
                placeholder={"0"}
                disabled={isReadonly || isSecondCommercialOfferOnApproval}
            />
            <CurrencyField source="logisticsPrice" label="Сумма логистики, руб. с НДС" />
            <PercentField source="logisticsRatioFromFirstCommercialOffer" label="Доля логистики в сумме, КП1, %" />
            <PercentField source="logisticsRatioFromSecondCommercialOffer" label="Доля логистики в сумме, КП2, %" />
            <CurrencyField
                source="firstCommercialOfferGrossMargin"
                label={<Tooltip text={Texts.grossMargin} label="Вал. маржа_1 (руб. с НДС)" />}
            />
            <CurrencyField
                source="secondCommercialOfferGrossMargin"
                label={<Tooltip text={Texts.grossMargin} label="Вал. маржа_2 (руб. с НДС)" />}
            />
            <PercentField
                source="firstCommercialOfferMarginality"
                label={<Tooltip text={Texts.marginalityLot} label="Маржинальность_1, %" />}
            />
            <PercentField
                source="secondCommercialOfferMarginality"
                label={<Tooltip text={Texts.marginalityLot} label="Маржинальность_2, %" />}
            />
            <StyledEditableNumberField
                source="invoicePrice"
                label="ЦН, руб. с НДС"
                onBlurHandler={handlers.setInvoicePrice}
                classes={classes.lotInput}
                placeholder={"0"}
                disabled={isReadonly}
            />
            <StyledEditableNumberField
                source="governmentContractPrice"
                label="ЦК, руб. с НДС"
                onBlurHandler={handlers.setGovernmentContractPrice}
                classes={classes.lotInput}
                placeholder={"0"}
                disabled={isReadonly}
            />
            <NumberField
                source="totalDrugVolume"
                label={<Tooltip text={Texts.totalDrugVolume} label="Объем препаратов - м3" />}
                options={{ maximumFractionDigits: 2 }}
            />
            <CurrencyField source="priceSum" label="Стоимость НМЦ SKU, руб. с нДС" />
            <CurrencyField
                source="startingMinimalSkuPrice"
                label={<Tooltip text={Texts.startingMinimalSkuPrice} label="Стоимость прайс-листа SKU, руб. с нДС" />}
            />
            <PercentField
                source="participantMarginShare"
                label="Доля маржи участника ЭА"
            />
            <PercentField
                source="supplierMarginShare"
                label="Доля маржи поставщика в сумме маржи"
            />
            <PercentField
                source="lotPercentageInOrder"
                label={<Tooltip text={Texts.lotPercentageInOrder} label="Доля SKU в аукционе" />}
            />
            <StyledProducerSelector label="Производитель" setFocus={setFocus} getFocus={getFocus} />
            <StyledStatusSelector classes={classes} label="Статус" setFocus={setFocus} getFocus={getFocus} />
        </Datagrid>
    );
}



const CostPrice = (props) => {
    const classes = useLotStyles();
    if (props.record == null)
        return null;

    if (!props.record.isPriceActual) {
        return (
            <div className={classes.lotInput}>
                <ErrorRounded style={{ color: '#FFA250', width: 17, height: 17 }} />
                <CurrencyField source="priceFromPriceList" {...props} />
            </div>
        );
    }
    return (
        <CurrencyField source="priceFromPriceList" {...props} />
    );
}

const ExpandLots = (props) => {
    const classes = useLotStyles();
    const [nomenclature, setNomenclature] = useState<any>({});

    useEffect(() => {
        async function getRecord() {
            const resp = await api.getOneProductNomenclature(props.record.productNomenclatureId);
            const data = await resp?.json();
            setNomenclature(data);
        }

        getRecord();
    }, [])

    const { id: ignored_id, ...restNomenclature } = nomenclature;

    const newProps = {
        basePath: props.basePath,
        id: props.id,
        record: { ...props.record, ...restNomenclature, lotId: props.id },
        resource: props.resource
    };

    const { permissions } = usePermissions();

    return (
        <Grid xs={12} container
        className={classes.expandLotsContainer}
        >
            <Grid className={classes.innerItem} item xs={4}>
                <SimpleShowLayout {...newProps} >
                    <TextField source="innName" label="МНН" />
                    <TextField source="dosageForm" label="ЛФ" />
                    <TextField source="dosageFormShort" label="ЛФ1" />
                    <TextField source="dosage" label="Дозировка" />
                    <TextField source="dosageShort" label="Дозировка1" />
                    <NumberField source="ampuleVolume" label="Мл/Мг" />
                    <TextField source="originalSpecification" label="Описание МНН/ТН из таблицы ТЗ по изв" />
                </SimpleShowLayout>
            </Grid>
            <Grid className={classes.innerItem} item xs={5}>
                <SimpleShowLayout {...newProps}>
                    {props.record.isSkuActual ? <RelevanceField source="isRegionActual" label="Актуальность региона для SKU" /> : null}
                    {props.record.isSkuActual ? <RelevanceField source="isCustomerActual" label="Актуальность ЛПУ для SKU" /> : null}
                    {props.record.isSkuActual ? <LotPriceRelevance /> : null }
                    <LotPriceApproval />
                    <EditableTextField
                        hasLabel
                        fullWidth
                        multiline
                        source="comments"
                        label="Комментарий"
                        onBlurHandler={handlers.setComments}
                        ref={props.refComments}
                        rowsMax={3}
                        rows={3}
                        className={classes.comment}
                        disabled={isObserver(permissions)}
                    />
                </SimpleShowLayout>
            </Grid>
        </Grid>
    );
}

const ProducerSelector = (props) => {
    const { record } = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const classes = useLotStyles();

    const setProducer = async (producerId) => {
        const result = await api.setProducer(record.id, producerId);
        if (result?.ok) {
            let scroll = props.getFocus();
            notify('Производитель обновлен');
            refresh();
            props.setFocus(scroll);
        }
        else {
            notify('Ошибка: не удалось установить Производителя', 'warning');
        }
    };

    const { permissions } = usePermissions();
    const disabled = isObserver(permissions);

    if (record == null)
        return null;

    return (
        <SimpleForm toolbar={null} label="Производитель"{...props} className={classes.producer}>
            <MyReferenceInput
                label="Производитель"
                source="producerId"
                filter={{ skuId: props.record.skuId, lotProducerId: props.record.producerId }}
                reference={entities.producers}
                options={{ InputProps: { disabled } }}
                optionText={(choice) => choice.name}
                onChange={createOnChangeWrapper({ record, source: props.source, onChange: setProducer })}
                perPage={100}
            >
                <AutocompleteInputSingle
                    disabled={disabled}
                    id={`producerId-${props.record.id}`}
                />
            </MyReferenceInput>
        </SimpleForm>
    );
}

const StatusSelector = (props) => {
    const { record, classes } = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const dispatch = useDispatch();

    const setStatus = async (status) => {
        dispatch(fetchStart());
        const result = await api.setStatusToLot(status, record.id);
        dispatch(fetchEnd());

        if (result.succeeded)
            notify('Статус лота установлен');
        else if (result.errorMessage)
            notify(result.errorMessage);
        else {
            notify('Ошибка: не удалось установить статус лота', 'warning');
        }
        let scroll = props.getFocus();
        refresh();
        props.setFocus(scroll);
    };

    const { permissions } = usePermissions();
    const disabled = isObserver(permissions);
    if (record == null)
        return null;

    return (
        <SimpleForm toolbar={null} label={null} className={classes.status} {...props}>
            <SelectInputSingle
                source="lotResultStatus"
                label=" "
                disabled={disabled}
                onChange={event => setStatus(event.target.value)}
                options={{ InputProps: { disabled: disabled } }}
                choices={LotResultStatusChoices}
            />
        </SimpleForm>
    );
}


const SkuSelector = (props) => {
    const { record, classes } = props;
    const notify = useNotify();
    const refresh = useRefresh();    

    const setSku = async (skuId) => {
        const result = await api.setSkuToLot(skuId, record.id);
        if (result?.ok) {
            let scroll = props.getFocus();
            notify('ТН компании добавлен');
            refresh();
            props.setFocus(scroll);
        }
        else {
            let scroll = props.getFocus();
            notify('Ошибка: не удалось установить ТН компании', 'warning');
            refresh();
            props.setFocus(scroll);
        }
    };

    const { permissions } = usePermissions();
    const disabled = props?.record?.isCommercialOfferOnApproval || isObserver(permissions);

    if (record == null)
        return null;

    return (
        <div className={classes.skuField} >
            <SimpleForm toolbar={null} label={null}  {...props}>
                {!record.isSkuActual && <StyledBooleanField source="isSkuActual" valueLabelFalse={"SKU неактуально"} className={classes.lotSkuActualIcon}  {...props} />}
                <MyReferenceInput disabled={disabled} options={{ InputProps: { disabled: disabled } }} excludedValue={(sku) => sku?.isActual === false} source="skuId" reference={entities.skus} filter={{ innId: record.innId }} onChange={event => setSku(event.target.value)}>
                    <InnerSkuPicker {...props} />
                </MyReferenceInput>
                <SkuInfoShow lotId={record.id} regionId={record.regionId} />
            </SimpleForm>
        </div>
    );
}


const InnerSkuPicker = (props) => {
    const { choices } = props;

    if (choices == null)
        return null;

    return <SelectInputSingle emptyChoicesItemText="Нет актуальных SKU" {...props} label=" " optionText="nomenclature" />;
}

const SkuInfoShow = ({ lotId, regionId }) => {
    const classes = useLotStyles();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" color="primary" className={classes.iconSearch} onClick={handleClickOpen} disableElevation>
                <InfoOutlined />
            </Button>
            {open ? <SkuInfo open={open} handleClose={handleClose} lotId={lotId} regionId={regionId} /> : null}
        </div>
    );
};

type LotRecordType = { totalUnitQuantity: string, manualInput: boolean };

const TotalUnitQuantityField: React.FC<any> = (props: any) =>{
    const { totalUnitQuantity, manualInput, id } = props?.record 
        || { totalUnitQuantity: null, manualInput: null, id: null };
    const classes = useLotStyles();
    const [fetching, setFetching] = useState(false);
    const [actualData, setActualData] = useState<LotRecordType>({ totalUnitQuantity, manualInput, });
    const [editFromAuto, setEditFromAuto] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const isReadonly = isObserver(props.permissions);
    

    const onManualSwitch = async (valueSetter, value, p) => {
        setEditFromAuto(false);
        setFetching(true);
        props.record.manualInput = true;
        props.record.totalUnitQuantity = value;
        await handlers.setManualInput(valueSetter, value, p);
        setFetching(false);
    }

    const returnToAutomatic = async () => {
        setFetching(true);
        props.record.manualInput = false;
        props.record.totalUnitQuantity = 'Loading...';
        await api.setAutomaticTotalQuantity(id);
        setFetching(false);
        notify('Ввод изменен на автоматический');
        refresh();
    };

    useEffect(() => {
        setActualData({ totalUnitQuantity, manualInput });
    }, [totalUnitQuantity, manualInput]);

    const refreshButton = () => {
        if (!isReadonly) {
            return (
                <MUITooltip title="вернуться на автоматический расчёт">
                    <IconButton onClick={returnToAutomatic}>
                        <Autorenew />
                    </IconButton>
                </MUITooltip>
            );
        }
        return <div></div>;
    }

    const manualButton = () => {
        if (!isReadonly) {
            return (
                <MUITooltip title="ручной ввод">
                    <IconButton onClick={() => setEditFromAuto(true)}>
                        <Create />
                    </IconButton>
                </MUITooltip>
            );
        }
        return <div></div>;
    }

    return (
        <FunctionField
            {...props}
            render={() => {
                if (fetching) {
                    return <div>Loading...</div>
                }

                // Manual field view/edit
                if (actualData.manualInput) {
                    return (
                        <InlineContainer 
                            rightChild={(
                                <div className={classes.lotInput}>
                                    <EditableNumberField 
                                        record={props.record}
                                        disabled={isReadonly}
                                        placeholder="0"
                                        source="totalUnitQuantity"
                                        onBlurHandler={onManualSwitch}
                                        value={actualData.totalUnitQuantity}
                                        decimalPlaces={2}
                                    />
                                </div>
                            )}
                            leftChild={(refreshButton())}
                        />
                        
                    );
                }

                // Automatic field edit
                if (editFromAuto) {
                    return (
                        <div className={classes.lotInput}>
                            <EditableNumberField
                                record={props.record}
                                disabled={false}
                                placeholder="0"
                                source="totalUnitQuantity"
                                onBlurHandler={onManualSwitch}
                                value={actualData.totalUnitQuantity}
                                onCancel={() => setEditFromAuto(false)}
                                openEditByDefault={true}
                                decimalPlaces={0}
                            />
                        </div>
                    );
                }

                // Automatic field view
                return (
                    <InlineContainer 
                        rightChild={actualData.totalUnitQuantity}
                        leftChild={(manualButton())}
                    />
                );
            }}
        />
    );
}

type InlineContainerProps = {
    leftChild: JSX.Element | string,
    rightChild: JSX.Element | string,
}

const InlineContainer: React.FC<InlineContainerProps> = (props: InlineContainerProps) => (
    <div style={{ display: 'flex' }}>
        <div style={{ alignSelf: 'center' }}>
            {props.rightChild}
        </div>
        <div style={{ alignSelf: 'center' }}>
            {props.leftChild}
        </div>
    </div>
);

export default LotsGrid;
