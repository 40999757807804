import * as React from "react";
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useNotify, useRefresh, usePermissions, fetchStart, fetchEnd, useGetList, Error } from 'react-admin';
import api from "../../dataProvider/api";
import { entities } from "../../configuration";
import { LotPriceApprovalStatusChoices } from "../../common/choices";
import { LotPriceApprovalStatus, CommercialOfferType } from "../../common/enums";
import { SecondaryButton } from "../../components/GphcUIKit/Button";
import SuccessFailureTextField from "../../components/GphcUIKit/SuccessFailureTextField";
import { useCommercialOfferStyles } from "./styles";
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import classnames from 'classnames';
import { isFederalManager } from "../../common/helpers";


const Wrapper = (props) => {
    const { record } = props;    

    if (record == null)
        return null;

    return <LotPriceApproval {...props} />;
}

const LotPriceApproval = (props) => {
    const lot = props.record;
    const { data, loading, error } = useGetList(entities.lotPriceApprovals,
        { page: 1, perPage: 1 }, { field: '', order: '' }, { lotId: lot.id, actual: true, needApproval: true });

    if (loading)
        return <CircularProgress />;

    if (error)
        return <Error />;

    const approval = data == null ? null : data[Object.keys(data)[0]];

    const showFirstApprovalButtons = approval?.needApproveFirstCommercialOffer == true;
    const showSecondApprovalButtons = approval?.needApproveSecondCommercialOffer == true;
    const firstStatus = approval?.firstCommercialOfferApprovalStatus ?? lot.firstCommercialOfferApprovalStatus;
    const secondStatus = approval?.secondCommercialOfferApprovalStatus ?? lot.secondCommercialOfferApprovalStatus;

    return <div style={{ fontSize: '1.2em' }}>
        <SinglePriceApproval lot={lot} status={firstStatus}
            price={approval?.firstCommercialOffer} commercialOfferType={CommercialOfferType.First} 
            showApprovalButtons={showFirstApprovalButtons} />
        <SinglePriceApproval lot={lot} status={secondStatus}
            price={approval?.secondCommercialOffer} commercialOfferType={CommercialOfferType.Second}
            showApprovalButtons={showSecondApprovalButtons} />
    </div>;
}

const needShowApproval = (status) => status === LotPriceApprovalStatus.OnApproval || isApprovalWasMade(status);
const isApprovalWasMade = (status) => status === LotPriceApprovalStatus.Approved ||
    status === LotPriceApprovalStatus.Declined;

const getCaption = (priceType: CommercialOfferType) => priceType === CommercialOfferType.First ? "КП1" : "КП2";

const SinglePriceApproval = (props) => {
    const { lot, status, price, commercialOfferType, showApprovalButtons } = props;

    const dispatch = useDispatch();
    const notify = useNotify();
    const refresh = useRefresh();
    const classes = useCommercialOfferStyles();
    const { permissions } = usePermissions();

    const [approvalWasMade, setApprovalWasMade] = React.useState(false);

    React.useEffect(() => {
        setApprovalWasMade(isApprovalWasMade(status))
    }, [status]);

    const submit = async (approve: boolean) => {
        dispatch(fetchStart());
        const result = await api.approvePrice(lot.id, commercialOfferType, approve);
        dispatch(fetchEnd());

        if (result.succeeded) {
            setApprovalWasMade(true);
            notify('Изменения сохранены');
        }
        else if (result.errorMessage)
            notify(result.errorMessage);
        else
            notify('Произошла ошибка');

        refresh();
    }

    const showButtons = isFederalManager(permissions) && showApprovalButtons && needShowApproval(status);
    return (
        <div>
            <div className={classes.LotRelevanceWrap}>
                <span>Статус согласования {getCaption(commercialOfferType)}</span>
                <div className={classes.LotRelevanceStatus}>
                    <ApprovalStatus status={status} />
                </div>
            </div>
            {
                showButtons && <div className={'priceDecision'}>
                    <span className={classes.LotApprovePricesInfo}>Использованная цена: {price} руб, минимальная: {lot.priceFromPriceList} руб.</span>
                    <ApprovalButtons commercialOfferType={commercialOfferType} classNameApproved={classes.LotRelevanceWrap} classNameNotApproved={classes.LotRelevanceWrap} 
                        submit={submit} approvalWasMade={approvalWasMade} setApprovalWasMade={setApprovalWasMade} />
                </div>
            }
        </div>
    );
}

const ApprovalButtons = (props) => {
    const { classNameApproved, classNameNotApproved, submit, approvalWasMade, setApprovalWasMade, commercialOfferType } = props;

    if (approvalWasMade)
        return <div className={classNameApproved}>
            <Button className="change" color="default" variant="contained" endIcon={<CreateIcon />} onClick={() => setApprovalWasMade(false)} disableElevation>Изменить статус согласования цены {getCaption(commercialOfferType)}</Button>
        </div>;

    return <div className={classNameNotApproved}>
            <Button onClick={async () => submit(true)} variant="contained" color="primary" endIcon={<DoneIcon />} disableElevation>Согласовать</Button>
            <Button onClick={async () => submit(false)} variant="contained" color="secondary" endIcon={<CloseIcon />} disableElevation>Отказать</Button>
        </div>;
}

const ApprovalStatus = ({ status }) => {
    return <>
        <SuccessFailureTextField success={isSuccessStatus(status)} />
        <span>{getStatusText(status)}</span>
    </>
}

const getStatusText = (status) => LotPriceApprovalStatusChoices.find(c => c.id === status)?.name;
const isSuccessStatus = (status) => status !== LotPriceApprovalStatus.OnApproval && status !== LotPriceApprovalStatus.Required;

export default Wrapper;
