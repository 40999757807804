import { Chip } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import {
    BooleanField, Datagrid, DateField,
    NumberField, ReferenceField, SelectField,
    TextField, FunctionField
} from "react-admin";
import { connect } from 'react-redux';
import {
    IsActualStatusChoices, LotTypeChoices, MappedAreaStatusChoices,
    PurchaseOrderPriceApprovalChoices, PurchaseOrderProcessingManagerStatusChoices, PurchaseOrderProcessingStatusChoices,
    PurchaseOrderResultStatusChoices, YesOrNoChoices
} from "../../common/choices";
import { FactoryComponents, MappedAreaStatus } from '../../common/enums';
import { isAdmin, Roles } from '../../common/helpers';
import Texts from "../../common/tooltipCalculatedTexts";
import CurrencyField from "../../components/CurrencyField";
import Pagination from "../../components/DefaultPagination";
import Filter from '../../components/GphcUIKit/Filter';
import List from "../../components/List";
import { AListActions } from "../../components/ListActions";
import PercentField from "../../components/PercentField";
import Tooltip from '../../components/WrapperTooltip';
import { entities } from "../../configuration";
import OrderShow from "./OrderShow/";

const OrderList = props => {
    const { permissions } = props;
    if (!permissions)
        return null;

    const defaultFilters = isAdmin(permissions) ? { mappedAreaStatus: MappedAreaStatus.Mapped } : null;

    const searchComponentOptions = [
        { type: FactoryComponents.CustomFilterTopBar, source: "q", alwaysOn: true }
    ]

    const dateComponentsOptions = [
        { type: FactoryComponents.Date, source: "tenderDateFrom", label: "Дата проведения с", alwaysOn: true },
        { type: FactoryComponents.Date, source: "tenderDateTo", label: "Дата проведения по", alwaysOn: true },
        { type: FactoryComponents.Date, source: "filingDateEndFrom", label: "Дата ок.подачи с", alwaysOn: true },
        { type: FactoryComponents.Date, source: "filingDateEndTo", label: "Дата ок.подачи по", alwaysOn: true },
        { type: FactoryComponents.Date, source: "importDate", label: "Дата импорта", alwaysOn: true },
        { type: FactoryComponents.Date, source: "importUpdateDate", label: "Дата пос. обновления", alwaysOn: true },
    ]

    const filtersComponentsOptions = [
        { type: FactoryComponents.Select, source: "purchaseOrderManagerStatus", label: "Статус аукциона КАМ", choices: PurchaseOrderProcessingManagerStatusChoices, alwaysOn: true, isMultiple: false },
        { type: FactoryComponents.Select, source: "PurchaseOrderProcessingStatus", label: "Статус аукциона МЕМО", choices: PurchaseOrderProcessingStatusChoices, alwaysOn: true, renderOrderWithCtrl: [0, 10, 11, "Устаревшие статусы", 1, 2, 3, 4, 5, 6, 7, 8, 9], currentRenderOrder: [0, 10, 11] },
        { type: FactoryComponents.Select, source: "resultStatus", choices: PurchaseOrderResultStatusChoices, label: "Результат аукциона", alwaysOn: true, isMultiple: true, renderOrderWithCtrl: [7, 8, 9, 10, 11, 12, 13, 14, "Устаревшие статусы", 0, 1, 2, 3, 4, 5, 6], currentRenderOrder: [7, 8, 9, 10, 11, 12, 13, 14] },
        { type: FactoryComponents.Select, source: "priceAgreementStatus", label: "Статус согласования цен", choices: PurchaseOrderPriceApprovalChoices, alwaysOn: true, },
        { type: FactoryComponents.Select, source: "lotType", label: "Монолот", choices: LotTypeChoices, alwaysOn: true, isMultiple: false },
        { type: FactoryComponents.ReferenceInputSelect, source: "innId", label: "МНН", reference: entities.inns, inputSource: "name", alwaysOn: true, filter: { actualBySkuPrice: true }, sort:{ field: 'name', order: 'ASC' }, perPage: 300  },
        { type: FactoryComponents.Customer, alwaysOn: true },
        { type: FactoryComponents.FederalDistrict, alwaysOn: true },
        { type: FactoryComponents.Region, alwaysOn: true, perPage: 300 },
        { type: FactoryComponents.AreaOfResponsibility, alwaysOn: true },
        { type: FactoryComponents.ReferenceAutocomplete, source: "firstParticipantId", label: "Участник", reference: entities.suppliers, inputSource: "name", alwaysOn: true, filter: { isSpecial: true } },
        { type: FactoryComponents.ReferenceAutocomplete, source: "participantWinnerId", label: "Победитель", reference: entities.suppliers, inputSource: "name", alwaysOn: true },
        { type: FactoryComponents.ReferenceAutocomplete, source: "participantLogisticianId", label: "Логист", reference: entities.suppliers, inputSource: "name", alwaysOn: true, filter: { isSpecial: true } },
        { type: FactoryComponents.Select, roles: [Roles.Admin], source: "mappedAreaStatus", label: "Cопоставленные зоне", choices: MappedAreaStatusChoices, alwaysOn: true },
        { type: FactoryComponents.Select, source: "isActual", label: "Актуальность", choices: IsActualStatusChoices, alwaysOn: true, isMultiple: false },
        { type: FactoryComponents.ReferenceAutocomplete, source: "managerId", label: "КАМ", reference: entities.users, inputSource: "name", optionText: choice => choice?.fullName ? choice.fullName : '', alwaysOn: true, filter: { isManager: true, isBlocked: false} },
        { type: FactoryComponents.Select, source: "sendedCommercialOffer", choices: YesOrNoChoices, label: "Есть отправленые КП", alwaysOn: true, isMultiple: false },
        { type: FactoryComponents.Select, source: "canSendCOStatus", label: "КП готово к отправке", choices: YesOrNoChoices, alwaysOn: true, isMultiple: false },
        { type: FactoryComponents.Select, source: "hasAdditionalLots", label: "Доп. лоты", choices: YesOrNoChoices, alwaysOn: true, isMultiple: false },
    ]

    return (
        <List
            {...props}
            bulkActionButtons={false}
            filters={
                <Filter permissions={permissions}
                    searchComponentOptions={searchComponentOptions}
                    dateComponentsOptions={dateComponentsOptions}
                    filtersComponentsOptions={filtersComponentsOptions}
                    defaultFilters={defaultFilters}
                    {...props}
                />
            }
            pagination={<Pagination />}
            perPage={25}
            filter={{ onApproval: props.options?.onApproval }}
            title={props.options.label}
            actions={<AListActions {...props} />}
            sort={{ field: 'importDateAndActualLotPriceSum', order: 'DESC' }}
        >
            <Datagrid rowClick="show">
                <OrderManagerStatus label="Статус аукциона КАМ" source="purchaseOrderManagerStatus" />
                <OrderStatus label="Статус аукциона МЕМО" source="processingStatus" />
                <SelectField label="Результат аукциона" source="resultStatus" choices={PurchaseOrderResultStatusChoices} />

                {/* <FunctionField 
                    sortBy="comments" 
                    label="Комментарий" 
                    render={(record: any) => record.comments?.length > 10 ? `${record.comments.slice(0, 10)}...` : record.comments} 
                /> */}
                <DateFieldImport label="Дата импорта" />
                <DateFieldImportUpdate label="Дата последнего обновления"/>
                <NumberField label="Номер аукциона" source="orderNumber" />
                <DateField label="Дата окончания подачи" source="filingDateEnd" />
                <DateField label="Дата проведения аукциона" source="tenderDate" />
                <ReferenceField source="areaOfResponsibilityId" label="Зона ответственности" reference={entities.areasOfResponsibility} link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="regionId" label="Регион" reference={entities.regions} link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField label="Актуальность" source="isActual" sortable={false} />
                <ReferenceField source="customerId" label="Заказчик" reference={entities.customers} link="show">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="taxpayerIdentificationNumber" label="ИНН Заказчика"/>
                <CurrencyField label="Сумма НМЦ всего аукциона (руб. с НДС)" source="totalPrice" />
                <CurrencyField
                    label={<Tooltip text={Texts.actualLotPriceSum} label="Сумма НМЦ позиций GPHC (руб. с НДС)" />}
                    source="actualLotPriceSum"
                />
                <PercentField
                    label={<Tooltip text={Texts.actualLotPriceSumPercentage} label="Доля позиций компании в аукционе, % (от НМЦ)" />}
                    source="actualLotPriceSumPercentage" />
                <CurrencyField
                    label={<Tooltip text={Texts.totalPriceFromCommercialOffer} label="Сумма в ценах КП 1, руб. с НДС" />}
                    source="totalPriceFromFirstCommercialOffer"
                />
                <ReferenceField source="firstParticipantId" label="Участник 1" reference={entities.suppliers} link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="participantWinnerId" label="Дистрибьютор-победитель" reference={entities.suppliers} link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="managerId" label="КАМ" reference={entities.users} link={false}>
                    <TextField source="fullName" />
                </ReferenceField>
                <NumberField label="Кол-во позиций" source="lotCount" sortable={false} />
            </Datagrid>
        </List>
    );
}


const useUpdatesMarkerStyles = makeStyles(theme => ({
    chip: {
        fontSize: '10px',
        marginLeft: '4px',
        color: 'white',
        backgroundColor: '#009BC3',
        width: '34px',
        height: '16px',
        borderRadius: '12px',
        '& .MuiChip-label': {
            padding: 0
        }
    },
}));

const OrderStatus = props => {
    const { record: { hasUpdates, updatesShown } } = props;
    const classes = useUpdatesMarkerStyles();

    return <React.Fragment>
        <SelectField {...props} choices={PurchaseOrderProcessingStatusChoices} />
    </React.Fragment>;
}

const OrderManagerStatus = props => {
    return <React.Fragment>
        <SelectField {...props} choices={PurchaseOrderProcessingManagerStatusChoices} />
    </React.Fragment>;
}

const DateFieldImportUpdate = props => {
    const { record: { hasUpdates, updatesShown } } = props;
    const classes = useUpdatesMarkerStyles();

    return <React.Fragment>
        <DateField source="importUpdateDate" {...props}/>
        {hasUpdates && !updatesShown && <Chip className={classes.chip} label="UPD!" />}
    </React.Fragment>;
}

const DateFieldImport = props => {
    const { record: { hasAdditionalLots } } = props;
    const classes = useUpdatesMarkerStyles();

    return <React.Fragment>
        <DateField source="importDate" {...props}/>
        {hasAdditionalLots && <Chip className={classes.chip} label="ADD!" />}
    </React.Fragment>;
}

OrderStatus.defaultProps = {
    addLabel: true,
};

function mapStateToProps(state) {
    const { router, toggler, filtersHeight } = state;
    // pathname === '/myPath' --> 'myPath'
    return { pathname: router.location.pathname.split('/')[1], toggler: toggler, filtersHeight: filtersHeight }
}

export default {
    list: connect(mapStateToProps)(OrderList),
    show: OrderShow,
};