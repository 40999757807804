import api from '../../../dataProvider/api';


const setCommercialOffer = async (valuSetter, value, p) => {
    await valuSetter(
        () => api.setCommercialOffer(p.record.id, value),
        "Успешно установлено КП 1"
    );
}

const setSecondCommercialOffer = async (valuSetter, value, p) => {
    await valuSetter(
        () => api.setSecondCommercialOffer(p.record.id, value),
        "Успешно установлено КП 2"
    );
}

const setGovernmentContractPrice = async (valuSetter, value, p) => {
    await valuSetter(
        () => api.setGovernmentContractPrice(p.record.id, value),
        "Успешно установлено ЦК"
    );
}

const setInvoicePrice = async (valuSetter, value, p) => {
    await valuSetter(
        () => api.setInvoicePrice(p.record.id, value),
        "Успешно установлено ЦН"
    );
}

const setComments = async (valuSetter, value, p) => {
    await valuSetter(
        () => api.setLotComments(p.record.lotId, value),
        "Комментарий лота обновлен"
    );
}

const setManualInput = async (valueSetter, value, p) => {
    await valueSetter(
        () => api.setManulTotalQuantity(p.record.id, value),
        "Формат ввода изменён на ручной"
    )
}

export default {
    setCommercialOffer,
    setSecondCommercialOffer,
    setGovernmentContractPrice,
    setInvoicePrice,
    setComments,
    setManualInput,
}