import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from "react";
import {
    Datagrid, DateField,
    NumberField,
    ReferenceField, ReferenceManyField, Show, Tab, TabbedShowLayout, TextField, 
} from "react-admin";
import { FactoryComponents } from '../../common/enums';
import CurrencyField from '../../components/CurrencyField';
import Pagination from "../../components/DefaultPagination";
import FullNameTextField from "../../components/FullNameTextField";
import Filter from '../../components/GphcUIKit/Filter';
import List from "../../components/List";
import { AListActions } from "../../components/ListActions";
import { entities } from "../../configuration";

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const firstColumnStyle = makeStyles(theme => {
    return {
        firstColumn: {
            maxWidth: "100%"
        },
    }
});

const Empty = () => {
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                Отсутствуют коммерческие предложения
            </Typography>
        </Box>
    );
};

const CommercialOfferList = (props) => {
    const classes = firstColumnStyle();
    return (
        <List
            {...props}
            filters={
                <Filter
                    searchComponentOptions={[
                        { type: FactoryComponents.Search, source: "q", alwaysOn: true }
                    ]}
                    dateComponentsOptions={[
                        { type: FactoryComponents.Date, source: "dateCreatedFrom", label: "Дата отправки c", alwaysOn: true }, { type: FactoryComponents.Date, source: "dateCreatedTo", label: "Дата отправки по", alwaysOn: true }
                    ]}
                    filtersComponentsOptions={[
                        { type: FactoryComponents.ReferenceAutocomplete, source: "supplierId", label: "Кому", reference: entities.suppliers, alwaysOn: true },
                        { type: FactoryComponents.Region, alwaysOn: true, perPage: 300 },
                        { type: FactoryComponents.Customer, alwaysOn: true },
                        { type: FactoryComponents.ReferenceAutocomplete, source: "orderId", label: "Номер аукциона", reference: entities.orders, alwaysOn: true }
                    ]}
                    {...props}
                />
            }
            empty={<Empty />}
            pagination={<Pagination />}
            perPage={25}
            title={props.options.label}
            bulkActionButtons={false}
            sort={{ field: 'dateCreated', order: 'DESC' }}
            actions={<AListActions {...props} />}
        >
            <Datagrid rowClick="show">
                <EmailChipListField
                    label="E-mails"
                    source="supplierContacts"
                    toValue={val => val.email}
                    cellClassName={classes.firstColumn}
                    headerClassName={classes.firstColumn}
                    isLink={false}
                />
                <DateField source="dateCreated" label="Дата и время отправки письма" showTime />
                <ReferenceField source="issuerId" reference={entities.users} label="Источник" link={false} >
                    <FullNameTextField />
                </ReferenceField>
                <ReferenceField label="Дистрибьютор" source="supplierId" reference={entities.suppliers} link={false} >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="orderId" reference={entities.orders} label="№ Аукциона" link="show">
                    <TextField source="orderNumber" />
                </ReferenceField>
                <DateField source="tenderDate" label="Дата проведения аукциона" />
                <ReferenceField source="regionId" reference={entities.regions} label="Регион" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="customerId" reference={entities.customers} label="ЛПУ" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <CurrencyField source="totalPrice" label="Сумма НМЦ всего аукциона" />
                <CurrencyField source="actualLotPriceSum" label="Сумма НМЦ позиций компании в аукционе" />
                <CurrencyField source="totalPriceFromCommercialOffer" label="Сумма в ценах КП" />
            </Datagrid>
        </List>
    );
}

const Title = (props) => {
    return (<div>Коммерческое предложение</div>)
};


const CommercialOfferShow = props => {
    const classes = firstColumnStyle();

    return (
        <Show {...props} title={<Title />} >
            <TabbedShowLayout >
                <Tab label="Основная информация">
                    <DateField source="dateCreated" label="Дата" showTime />
                    <ReferenceField label="Дистрибьютор" source="supplierId" reference={entities.suppliers} link={false} >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="orderId" reference={entities.orders} label="Аукцион" link="show">
                        <TextField source="orderNumber" />
                    </ReferenceField>
                    <ReferenceField source="issuerId" reference={entities.users} label="КАМ" link={false} >
                        <FullNameTextField />
                    </ReferenceField>
                    <ReferenceField source="regionId" reference={entities.regions} label="Регион" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="customerId" reference={entities.customers} label="ЛПУ" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <EmailChipListField
                        addLabel={true}
                        label="E-mails"
                        source="supplierContacts"
                        toValue={val => val.email}
                    />
                    <ReferenceManyField label="" reference={entities.commercialOfferLotsPrices} target="commercialOfferId">
                        <Datagrid rowClick={null}>
                            <TextField source="externalId" label="№" />
                            <CurrencyField source="price" label="Цена" />
                            <ReferenceField source="skuId" reference={entities.skus} label="SKU" link={false}>
                                <TextField source="nomenclature" />
                            </ReferenceField>
                            <NumberField source="quantity" label="Количество" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Письмо">
                    <TextField source="mailSubject" label="Тема письма" />
                    <MailShower addLabel label="Письмо" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}

const emailChipListFieldStyle = makeStyles(theme => ({
    chip: {
        marginLeft: '7px',
        padding: '1px',
        color: 'white',
        backgroundColor: '#009BC3',
    },
}));

const EmailChipListField = (props) => {
    const { record, source, toValue, isLink = true } = props;
    const classes = emailChipListFieldStyle();

    if (record == null || source == null)
        return null;

    const value = record[source];
    if (value == null)
        return null;

    return <React.Fragment >
        {(toValue ? value.map(i => toValue(i)) : value).map(i => <Chip className={classes.chip} label={
            isLink ? <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary" href={"mailto:" + i} >{i}</a> : i
        } />)}
    </React.Fragment>;
}

EmailChipListField.addLabel = {
    addLabel: true,
};


const MailShower = (props) => {
    const { record: { mailText } } = props;
    if (mailText == null)
        return null;

    return <div dangerouslySetInnerHTML={{ __html: mailText }}></div>;
}

export default {
    list: CommercialOfferList,
    show: CommercialOfferShow
};