import React, { Fragment } from "react";
import {
    Datagrid, DateField, ReferenceField,
    ReferenceManyField, Show,
    SimpleShowLayout, Tab, TabbedShowLayout, TextField, useNotify, useQuery,
    useRefresh, useUnselectAll
} from "react-admin";
import {
    MappedAreaStatusChoices
} from "../../common/choices";
import { MappedAreaStatus } from '../../common/enums';
import { isManager } from '../../common/helpers';
import Pagination from "../../components/DefaultPagination";
import Filter from '../../components/GphcUIKit/Filter';
import List from "../../components/List";
import { AListActions } from "../../components/ListActions";
import { entities } from "../../configuration";
import api from "../../dataProvider/api";
import MapBulkButton from "../../layouts/bulkButtons/map/MapBulkButton";
import MapBulkWithAddAreaButton from "../../layouts/bulkButtons/map/MapBulkWithAddAreaButton";
import ExpandShow from "./ExpandShow";
import { FactoryComponents } from '../../common/enums';

const RegionList = (props) => {
    const { permissions, ...rest } = props;
    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: entities.areasOfResponsibility,
        payload: { pagination: {}, sort: {} }
    });

    const refresh = useRefresh();
    const notify = useNotify();
    const unSelect = useUnselectAll();

    const bulkRegionsMapToArea = (regionIds, areaId) => async () => {
        const result = await api.bulkRegionsMapToArea(regionIds, areaId);
        checkResult(result);
    };

    const bulkRegionsMapToNewArea = (regionIds, areaName) => async () => {
        const result = await api.bulkRegionsMapToNewArea(regionIds, areaName);
        checkResult(result);
    };

    const checkResult = (result: any) => {
        if (result.succeeded) {
            refresh();
            notify("Регионы сопоставлены");
            unSelect(entities.regions);
        } else notify("Ошибка: не удалось сопоставить регионы", "warning");
    };

    const ABulkActions = (props) => (
        <Fragment>
            <MapBulkButton
                data={data}
                handleClick={bulkRegionsMapToArea}
                {...props}
            />
            <MapBulkWithAddAreaButton
                data={data}
                handleClick={bulkRegionsMapToNewArea}
                {...props}
            />
        </Fragment>
    );

    if (isManager(permissions)){
        return (
            <List
                {...props}
                filters={
                    <Filter
                        searchComponentOptions={[
                            { type: FactoryComponents.Search, source: "q", alwaysOn: true }
                        ]} 
                        filtersComponentsOptions={[
                            { type: FactoryComponents.Region, alwaysOn: true, perPage: 300 },
                            { type: FactoryComponents.FederalDistrict, alwaysOn: true },
                            { type: FactoryComponents.AreaOfResponsibility, alwaysOn: true },
                            { type: FactoryComponents.Select, source: "mappedAreaStatus", label: "Cопоставленные зоне", choices: MappedAreaStatusChoices, alwaysOn: true, isMultiple: false },
                        ]} 
                        defaultFilters={{ mappedAreaStatus: [MappedAreaStatus.Mapped] }}
                        {...props}
                    />
                }
                perPage={25}
                pagination={<Pagination />}
                title={props?.options?.label}
                actions={<AListActions {...props} />}
                bulkActionButtons={false}
            >
                <Datagrid rowClick="show">
                    <TextField source="name" label="Название" />
                    <ReferenceField
                        source="federalDistrictId"
                        label="Федеральный округ"
                        reference={entities.federalDistricts}
                        link={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        source="areaOfResponsibilityId"
                        label="Зона ответственности"
                        reference={entities.areasOfResponsibility}
                        link="show"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                </Datagrid>
            </List>
        );
    }

    return (
        <List
            {...props}
            bulkActionButtons={<ABulkActions />}
            filters={
                <Filter
                    searchComponentOptions={[
                        { type: FactoryComponents.Search, source: "q", alwaysOn: true }
                    ]} 
                    filtersComponentsOptions={[
                        { type: FactoryComponents.Region, alwaysOn: true, perPage: 300 },
                        { type: FactoryComponents.FederalDistrict, alwaysOn: true },
                        { type: FactoryComponents.AreaOfResponsibility, alwaysOn: true },
                        { type: FactoryComponents.Select, source: "mappedAreaStatus", label: "Cопоставленные зоне", choices: MappedAreaStatusChoices, alwaysOn: true, isMultiple: false },
                    ]}
                    defaultFilters={{ mappedAreaStatus: [MappedAreaStatus.Mapped] }}
                    {...props}
                />
            }
            perPage={25}
            pagination={<Pagination />}
            title={props?.options?.label}
            actions={<AListActions {...props} />}
        >
            <Datagrid rowClick="show" expand={<ExpandShow data={data} />}>
                <TextField source="name" label="Название" />
                <ReferenceField
                    source="federalDistrictId"
                    label="Федеральный округ"
                    reference={entities.federalDistricts}
                    link={false}
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                    source="areaOfResponsibilityId"
                    label="Зона ответственности"
                    reference={entities.areasOfResponsibility}
                    link="show"
                >
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};


const Title = (props) => {
    return (<div>Регион "{props.record?.name ?? ""}"</div>)
};

const RegionShow = (props) => {
    return (
        <Show {...props} title={<Title />}>
            <TabbedShowLayout>
                <Tab label="Регион">
                    <SimpleShowLayout>
                        <TextField source="id" />
                        <TextField source="name" />
                        <TextField source="federalDistrict" />
                        <ReferenceField link="show" source="areaOfResponsibilityId" reference={entities.areasOfResponsibility}>
                            <TextField source="name" />
                        </ReferenceField>
                    </SimpleShowLayout>
                </Tab>
                <Tab label="История сопоставлений">
                    <ReferenceManyField
                        perPage={10}
                        pagination={<Pagination />}
                        reference={entities.areasOfResponsibilityRegions}
                        target="regionId"
                        label="История сопоставлений"
                    >
                        <Datagrid>
                            <ReferenceField
                                link="show"
                                label="Зона ответственности"
                                source="areaOfResponsibilityId"
                                reference={entities.areasOfResponsibility}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <DateField label="Начало периода" source="validFrom" />
                            <DateField label="Конец периода" source="validTo" />
                            <ReferenceField
                                link="show"
                                label="Кто назначил"
                                source="issuerId"
                                reference={entities.users}
                            >
                                <TextField source="firstName" />
                            </ReferenceField>
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Предельный размер оптовой надбавки">
                    <ReferenceField source="id" reference={entities.maxWholesaleMarkups} addLabel={false} link={false}>
                        <SimpleShowLayout>
                            <TextField
                                source="lessOrEqual50MaxMarkup"
                                label="Размер для товаров ценой ≤ 50р"
                            />
                            <TextField
                                source="between50And500MaxMarkup"
                                label="Размер для товаров ценой  > 50р ≤ 500р"
                            />
                            <TextField
                                source="greater500MaxMarkup"
                                label="Размер для товаров ценой > 500р"
                            />
                        </SimpleShowLayout>
                    </ReferenceField >
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};


export default {
    list: RegionList,
    show: RegionShow,
};
