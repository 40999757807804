import React from 'react';
import SuccessFailureTextField from '../components/GphcUIKit/SuccessFailureTextField';
import { MakeCommercialOfferButton } from '../entities/orders/makeCommercialOffer/';
import { PurchaseOrderPriceApprovalStatus, PurchaseOrderPriceApprovalCommonStatus } from '../common/enums';
import { PurchaseOrderPriceApprovalChoices, PurchaseOrderPriceApprovalCommonChoices } from '../common/choices';
import { FinishPriceApprovalButton, StartPriceApprovalButton } from '../entities/orders/PriceApprovalsButtons';
import { useCommercialOfferStyles } from '../entities/orders/styles';

const greenStatuses = [
    PurchaseOrderPriceApprovalStatus.NoNeed,
    PurchaseOrderPriceApprovalStatus.Approved,
    PurchaseOrderPriceApprovalStatus.PartiallyApproved,
];

const greenCommonStatuses = [
    PurchaseOrderPriceApprovalCommonStatus.NoNeed,
    PurchaseOrderPriceApprovalCommonStatus.Approved,
    PurchaseOrderPriceApprovalCommonStatus.FirstCommercialOfferApproved,
    PurchaseOrderPriceApprovalCommonStatus.SecondCommercialOfferApproved,
    PurchaseOrderPriceApprovalCommonStatus.PartiallyApproved,
];

export const CommercialOfferStatus = (props) => {
    const { record, source } = props;

    if (record == null)
        return null;
        
    const status = record[source] as PurchaseOrderPriceApprovalStatus;
    const isSuccess = greenStatuses.includes(status);
    const statusText = PurchaseOrderPriceApprovalChoices.find(c => c.id === status)?.name;

    return <StatusTemplate button={<MakeCommercialOfferButton {...props} />} 
        isSuccess={isSuccess} statusText={statusText} />;
}

export const CommercialOfferTotalStatus = (props) => {
    const { record, source } = props;

    if (record == null)
        return null;
        
    const status = record[source] as PurchaseOrderPriceApprovalCommonStatus;
    const isSuccess = greenCommonStatuses.includes(status);
    const statusText = PurchaseOrderPriceApprovalCommonChoices.find(c => c.id === status)?.name;

    const buttons = <React.Fragment>
        <StartPriceApprovalButton {...props} />
        <FinishPriceApprovalButton {...props} />
    </React.Fragment>;

    return <StatusTemplate button={buttons} isSuccess={isSuccess} statusText={statusText} />;
}

const StatusTemplate = (props) => {
    const { isSuccess, statusText, button } = props;

    const classes = useCommercialOfferStyles();

    return (
        <div>            
            <div className={classes.COStatus}>
                <SuccessFailureTextField success={isSuccess}/>
                <span>{statusText}</span>
            </div>
            {button}
        </div>
    );
}
