import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import api from '../../../dataProvider/api';
import { useNotify } from 'react-admin';
import * as downloadFile from 'downloadjs';
import { getFileName } from "../../../common/fileHelper";
import { CommercialOfferType } from "../../../common/enums";

const DownloadCommercialOfferPdf = ({ 
        orderId,
        commercialOfferType,
    } : 
    { 
        orderId: number,
        commercialOfferType: CommercialOfferType,
    }) => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const downloadPdf = async () => {
        setLoading(true);
        const result = await api.downloadCommercialOfferPdf(orderId, commercialOfferType);
        setLoading(false);
        if (result.ok) {
            const name = getFileName(result);
            
            const clone = result.clone();
            const file = await result.blob();

            if (file.type === "application/json") {
                var data = await clone.json();
                notify(data.errorMessage ? data.errorMessage : 'Ошибка при загрузке файла', 'warning');
                return;
            }
            downloadFile(file, name, 'text/pdf');
        }
        else {
            notify('Ошибка при загрузке файла', 'warning');
        }
    }


    return (
        <Button 
            onClick={downloadPdf}
            disabled={orderId === undefined || commercialOfferType === undefined || loading}
            color="secondary"
            variant="contained"
            endIcon={loading ? <CircularProgress size={20} /> : <div></div>} 
        >
            {loading ? "Скачивание" : "Скачать PDF" }
        </Button>
    );
}

export default DownloadCommercialOfferPdf;