import React, { useEffect, useRef, useState, createRef } from "react";
import { 
    TextField, Select, MenuItem, Button
} from '@material-ui/core'; 
import { QbParamType } from "../../../common/enums";
import { Parameter } from "./dtoInterfaces";


export const Parameters = ({parameters, setCanExecuteQuery, canExecuteQuery, setParameters} : 
    {
        parameters : Array<Parameter>, 
        canExecuteQuery : boolean,
        setCanExecuteQuery : React.Dispatch<React.SetStateAction<boolean>>,
        setParameters : React.Dispatch<React.SetStateAction<Parameter[] | null>>
    }) => {
    const [disableButton, setDisableButton] = useState(parameters.length !== 0);
    
    useEffect(() => {
        if (parameters.length !== 0 && parameters.every(p => p.value))
            setDisableButton(false);
        else if (parameters.length !== 0)
            setDisableButton(true);
    }, [parameters])

    return <div style={{display: "flex", flexDirection: "column", marginLeft: 8 }}>
                {parameters.map((param, i) =>
                    getParamComponent(param, i, setDisableButton, setParameters) 
                )}
                <ExecuteButton setCanExecuteQuery={setCanExecuteQuery} disableButton={disableButton}/>
            </div>
};

const ExecuteButton = ({setCanExecuteQuery, disableButton}) => {
    return (
        <Button
            style={{marginTop: 15}}
            variant="outlined" color="primary"
            onClick={async e => { 
                await setCanExecuteQuery(false);
                await setCanExecuteQuery(true);
            }}
            disabled={disableButton}
        >
            Выполнить
        </Button>
    )
};

const getParamComponent = (
        param: Parameter,
        i: number,
        setDisableButton: React.Dispatch<React.SetStateAction<boolean>>,
        setParameters : React.Dispatch<React.SetStateAction<Parameter[] | null>>
    ) => {

    const setValue = (paramName : string, paramValue : any) => {
        //@ts-ignore
        setParameters((prev : Parameter[]) => {
            let parameter = prev.find(p => p.name === paramName);
            if (parameter != null) {
                parameter.value = paramValue;
                setDisableButton(!prev.every(p => p.value));
            }
            return prev;
        })
    };

    const setValueTextField = (e) => {
        const paramName = e.target.attributes["data-name"].value;
        setValue(paramName, e.target.value);
    };

    const setValueSelectField = (e) => {
        const paramName : any = e.target.name;
        setValue(paramName, e.target.value);
    };

    const propsObj = {
        key: i,
        label: param.name + " " + param.compareOperator,
        inputProps: {'data-name': param.name },
        type: "number",
        onChange: setValueTextField,
        defaultValue: param.value,
    }

    const dateNow = new Date();
    const createFormateDate = (date : Date) => {
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}`; 
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    const createByType = (dataType) => {
        switch (dataType) {
            case QbParamType.Double:
                return (
                    <TextField {...propsObj}/> );
            case QbParamType.Int:
                return (  
                    <TextField {...propsObj}/> );
            case QbParamType.Int64:
                return (  
                    <TextField {...propsObj}/> ); 
            case QbParamType.Decimal:
                return (  
                    <TextField {...propsObj} /> );
            case QbParamType.Boolean:
                return (
                    <Select
                        key={i}
                        label={param.name + " " + param.compareOperator} 
                        inputProps={{name: param.name }}
                        onChange={setValueSelectField}
                        defaultValue={false}
                    >
                        <MenuItem value="true">Да</MenuItem>
                        <MenuItem value="false">Нет</MenuItem>
                    </Select> );
            case QbParamType.DateTime:
                return (
                    <TextField
                        {...propsObj}  
                        type="datetime-local"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={createFormateDate(dateNow)}
                        onFocus={e => {
                            const paramName = e.target.attributes["data-name"].value;
                            setValue(paramName, e.target.value);
                        }}
                    /> );
            case QbParamType.String:
                return ( 
                    <TextField {...propsObj}  type="text"/> );
            case QbParamType.AnsiString:
                return ( 
                    <TextField {...propsObj}  type="text"/> );
            default:
                return <TextField {...propsObj}  type="text"/>;
        };
    };

    return createByType(param.dataType);
}