import * as React from "react";
import { DateField, SimpleShowLayout, TextField} from "react-admin";

const AddContract = (props) =>{

    return(
        <SimpleShowLayout {...props}>
            <TextField source="additionalContractNumber" label="Номер ДС" />
            <DateField source="additionalContractValidFrom" label="Дата начала" showTime/>
            <DateField source="additionalContractValidTo" label="Дата окончания" showTime/>
        </SimpleShowLayout>
    );
};

export default AddContract;