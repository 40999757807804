import * as React from 'react';
import { NumberField } from 'react-admin'

const CurrencyField = (props) => {
    return <NumberField addLabel={true} {...props} locales={'ru-RU'} options={{ style: 'currency', currency: 'RUB' }} />
};

CurrencyField.defaultProps = {
    addLabel: true,
};

export default CurrencyField;
