import { Button } from "@material-ui/core";
import * as React from 'react';
import {
    Datagrid, DateField, EditButton,
    Pagination, ReferenceField, ReferenceInput, ReferenceManyField, SelectInput, Show,
    SimpleForm, SimpleShowLayout,
    Tab, TabbedShowLayout, TextField,
    TopToolbar,

    useNotify, useRefresh
} from 'react-admin';
import { entities } from '../../configuration';
import api from "../../dataProvider/api";
import MapBulkButtonLayout from "../../layouts/bulkButtons/map/MapBulkButtonLayout";

const Title = (props) => {
    return (<div>Зона ответственности: "{props.record?.name ?? ""}"</div>)
}

const AreaShow = props => {
    return (
        <Show {...props} title={<Title/>} actions={<AreaActions />} >
            <TabbedShowLayout>
                <Tab label="Зона ответственности" >
                    <SimpleShowLayout>
                        <TextField source="name" label="Название" />
                    </SimpleShowLayout>
                </Tab>
                <Tab label="ЛПУ в зоне ответсвенности">
                    <ReferenceManyField perPage={10} pagination={<Pagination />} reference={entities.customers} target="areaOfResponsibilityId" label="ЛПУ">
                        <Datagrid>
                            <TextField source="name" label="Имя" />
                            <TextField source="inn" label="МНН"/>
                            <TextField source="kpp" label="КПП" />
                            <ReferenceField source="regionId" reference="regions" label="Регион"><TextField source="name" /></ReferenceField>
                            <TextField source="location" label="Местоположение" />
                            <TextField source="level" label="Уровень" />
                            <TextField source="type" label="Тип" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Регионы в зоне ответсвенности" >
                    <ReferenceManyField perPage={10} pagination={<Pagination />} reference={entities.regions} target="areaOfResponsibilityId" label="Регионы">
                        <Datagrid>
                            <TextField source="name" label="Название" />
                            <TextField source="federalDistrict" label="Федеральный округ" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="История сопоставлений">
                    <ReferenceManyField
                        perPage={10}
                        pagination={<Pagination />}
                        reference={entities.areasOfResponsibilityUsers}
                        target="areaOfResponsibilityId"
                        label="История сопоставлений"
                        sort={{ field: 'validFrom', order: 'ASC' }}
                    >
                        <Datagrid>
                            <DateField label="Начало периода" source="validFrom" />
                            <DateField label="Конец периода" source="validTo" />
                            <ReferenceField link="show" label="Пользователь" source="userId" reference={entities.users}>
                                <TextField source="fullName" />
                            </ReferenceField>
                            <ReferenceField link="show" label="Кто назначил" source="issuerId" reference={entities.users}>
                                <TextField source="fullName" />
                            </ReferenceField>
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Пользователи">
                    <ReferenceManyField
                        perPage={10}
                        pagination={<Pagination />}
                        reference={entities.areasOfResponsibilityUsers}
                        target="areaOfResponsibilityId"
                        label="Зоны ответственности"
                        filter={{ history: false }}
                    >
                        <Datagrid rowClick="show">
                            <TextField label="Имя" source="userName" />
                            <RemoveAreaFromUserButton />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};


const RemoveAreaFromUserButton = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const handleOnClick = async (e) => {
        e.stopPropagation();
        const result = await api.removeAreaFromUser(parseInt(props.record.userId), parseInt(props.record.areaOfResponsibilityId));
        const data = await result?.json();
        if (data?.succeeded) {
            notify('КАМ снят с зоны');
            refresh();
        }
        else
            notify(
                `Ошибка: не удалось снять КАМа с зоны.\n
                Причина: ${data ? data.errorMessage : `Статус ответа: ${result?.status}`}`, 'warning'
            );
    };


    return (
        <Button size="small" variant="outlined" color="primary" onClick={handleOnClick}>
            Снять
        </Button>
    );
}



const AreaActions = (props) => {
    const [id, setId] = React.useState(-1);
    const notify = useNotify();
    const refresh = useRefresh();

    const addAreaToUser = async (userId, areaId) => {
        const result = await api.addAreaToUser(userId, areaId);
        if (result) {
            notify('Зона добавлена');
            refresh();
        }
        else
            notify('Ошибка: не удалось добавить зону', 'warning');
    }

    return <TopToolbar>
        <EditButton basePath={props.basePath} record={props.data} />
        <MapBulkButtonLayout
            handleClick={async () => await addAreaToUser(id, props.data.id)}
            header="Добавить пользователя"
            cancelButtonText="Отмена"
            acceptButtonOptions={{ autoFocus: true }}
            acceptButtonText="Добавить"
            dialogContentText="Выберите пользователя"
            dialogTitle=""
            dialogContent={
                <SimpleForm toolbar={null}>
                    <ReferenceInput onChange={(e) => setId(e.target.value)}
                        label="Пользователи"
                        source="userId"
                        reference={entities.users}
                        filter={{ besidesIds: props.data?.ownerIdArray }}
                    >
                        <SelectInput optionText={choice => `${choice.firstName} ${choice.lastName}`} />
                    </ReferenceInput>
                </SimpleForm>
            }
        />
    </TopToolbar>
}

export default AreaShow;