import * as React from "react";
import {
    Datagrid, DateField,
    NumberField, ReferenceField, TextField
} from "react-admin";
import { FactoryComponents } from '../../common/enums';
import { useGridFixedColumnStyles } from "../../common/helpers";
import useAdditionalFilterValues from "../../common/useAdditionalFilterValues";
import CurrencyField from "../../components/CurrencyField";
import Pagination from "../../components/DefaultPagination";
import Filter from '../../components/GphcUIKit/Filter';
import List from "../../components/List";
import { AListActions } from "../../components/ListActions";
import ListWithSideBar from "../../components/ListWithSideBar";
import { entities } from "../../configuration";
import api from "../../dataProvider/api";
import Show from "./LogisticsPriceShow";

const LogisticsPriceList = props => {
    const filterValues = useAdditionalFilterValues(api.getLogisticsPriceFilterValues);
    const classes = useGridFixedColumnStyles(1);

    return (
        <ListWithSideBar
            {...props}
            show={<Show />}
            list={
                <List
                    {...props}
                    filters={<Filter
                        searchComponentOptions={[{ type: FactoryComponents.Search, source: "q", alwaysOn: true }]}
                        dateComponentsOptions={[{ type: FactoryComponents.Date, source: "dateActualTo", label: "Актуальность на", alwaysOn: true }]}
                        filtersComponentsOptions={[
                            { type: FactoryComponents.Region, alwaysOn: true, perPage: 200 },
                            { type: FactoryComponents.Sku, alwaysOn: true, sort:{ field: 'nomenclature', order: 'ASC' }, perPage: 300 },
                            { type: FactoryComponents.ReferenceInputSelect, source: "innId", label: "МНН", reference: entities.inns, inputSource: "name", alwaysOn: true, filter: { actualBySkuPrice: true }, sort:{ field: 'name', order: 'ASC' }, perPage: 300  },
                            { type: FactoryComponents.FederalDistrict, alwaysOn: true },
                            ...filterValues
                        ]}
                        {...props}
                    />}
                    pagination={<Pagination />}
                    actions={<AListActions {...props} />}
                    perPage={25} filter={{ history: false }}
                    title={props.options.label}
                    bulkActionButtons={false}
                >
                    <Datagrid rowClick="show" className={classes.dataGrid}>
                        <ReferenceField source="skuId" reference={entities.skus} label="SKU">
                            <TextField source="nomenclature" />
                        </ReferenceField>
                        <TextField source="innName" label="МНН" />
                        <TextField source="temperatureRegime" label="Температурный режим" />
                        <TextField source="boxUnitsCount" label="Коробка: Кол-во упаковок" />
                        <TextField source="boxWeight" label="Коробка: Вес (кг)" />
                        <NumberField source="boxVolume" label="Коробка: Объем" options={{ maximumFractionDigits: 2 }} />
                        <TextField source="palletUnitsCount" label="Паллет: Кол-во упаковок" />
                        <TextField source="palletWeight" label="Паллет: Вес (кг)" />
                        <NumberField source="palletVolume" label="Паллет: Объем" options={{ maximumFractionDigits: 2 }} />
                        <ReferenceField source="regionId" label="Регион" reference={entities.regions} link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField source="federalDistrictId" label="ФО" reference={entities.federalDistricts} link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                        <CurrencyField source="price" label="Ст-ть логистики 1 м3 в регион" />
                        <TextField source="comments" label="Комментарий" />
                        <DateField label="Дата загрузки" source="validFrom" />
                    </Datagrid>
                </List>
            }
        />
    );
}

export default {
    list: LogisticsPriceList
}