import { HttpError } from 'react-admin';
import { getToken } from "../utils";

type OptionsType = {
  headers: Headers,
  body: FormData,
}

function isOptionsType(request: OptionsType | {}): request is OptionsType {
  return (request as OptionsType).headers !== undefined;
}

export const fetchAdminJson = async (url, options: OptionsType | {} = {}) => {
  const requestHeaders = ((isOptionsType(options) && options.headers) ||
    new Headers({
      Accept: 'application/json',
    })
  );
  if (!requestHeaders.has('Content-Type') &&
    !(isOptionsType(options) && options.body && options.body instanceof FormData)) {
      requestHeaders.set('Content-Type', 'application/json');
    }

  const token = getToken();
  if (token != null)
    requestHeaders.set('Authorization', `Bearer ${token}`);

  const response = await fetch(url, { ...options, headers: requestHeaders })
  const text = await response.text()
  const o = {
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    body: text,
  };
  let status = o.status, statusText = o.statusText, headers = o.headers, body = o.body;
  let json;
  try {
    json = JSON.parse(body);
  } catch (e) {
    // not json, no big deal
  }
  if (status < 200 || status >= 300) {
    const showError = CheckLastError(status);
    
    if (showError) {
      return Promise.reject(new HttpError(statusText, status, json));
    }
    return Promise.reject();
  }
  return Promise.resolve({ status: status, headers: headers, body: body, json: json });
};

function CheckLastError(code: number) {
  const codeString = code.toString();
  const dateOffsetInMS = 1000;

  const dateString = localStorage.getItem(codeString);

  if (dateString === null) {
    localStorage.setItem(codeString, new Date().toISOString());
    return true;
  }

  const date = new Date(dateString);

  if (new Date().getTime() - date.getTime() <= dateOffsetInMS) {
    return false;
  } else {
    localStorage.setItem(codeString, new Date().toISOString());
    return true;
  }
}