import * as React from "react";
import { Datagrid, DateField, ReferenceManyField, TextField } from "react-admin";
import { entities } from "../../../configuration";
import CurrencyField from "../../../components/CurrencyField";
import PercentField from "../../../components/PercentField";
import { Pagination } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    // задаем стили для родительского элемента, чтобы работал overflow-x и таблица не выезжала за пределы таба
    wrapper_for_table: {
        overflowX: 'auto'
    }
})

const HistoryPrice = (props) =>{
    const classes = useStyles();

    return(
        <ReferenceManyField perPage={10} pagination={<Pagination />} reference={entities.skuPrices} target="skuId" addLabel={false}  {...props}>
            <div className={classes.wrapper_for_table}>
                <Datagrid rowClick="toggleSelection">
                    <DateField source="validFrom" label="Дата"/>
                    <CurrencyField source="vitalDrugsPrice" label="Цена ЖНВЛП без НДС" />
                    <PercentField source="vatAmount" label="% НДС" />
                    <CurrencyField source="purchasingPriceWithMarkupRatio" label="Закупочная цена с НДС (до вычета КН)" />
                    <CurrencyField source="purchasingPriceWithoutMarkupRatio" label="Закупочная цена с НДС (после вычета КН)" />
                    <TextField source="markupRatio" label="Размер КН, %" />
                    <CurrencyField source="planningSellingPrice" label="Плановая продажная цена с НДС" />
                    <PercentField source="planningProfitability" label="Плановая рентабельность, %" />
                    <CurrencyField source="minPrice" label="Мин. цена дна с НДС" />
                    <PercentField source="minPriceProfitability" label="Плановая рентабель-ность при min цене, %" />
                </Datagrid>
            </div>
        </ReferenceManyField>
    );
};

export default HistoryPrice;