import * as React from "react";
import { Show, TopToolbar, ListButton } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import LotsPart from './LotsPart';
import OrderPart from './OrderPart';
import Typography from '@material-ui/core/Typography';
import { Chip } from '@material-ui/core';

import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import PricesApprovalsHistoryButton from "../PricesApprovalsHistoryButton";
import HistoryButton from "../../../components/History/HistoryButton";



const useStyles = makeStyles({
    listBtn: {
        margin: '0 !important',
        marginRight: 'auto !important',
        paddingLeft: '0 !important',
        textTransform: 'none',
        background: 'transparent !important',
        
        "& span":{
            fontSize: '14px !important',
        }
    },

    Buttons: {
        paddingTop: 0,
        marginBottom: 35,
        "& .MuiButton-root": {
            padding: "8px 20px",
            marginRight: '16px'
        },

        '& .MuiButton-contained': {
            color: '#646469',
            borderRadius: '8px',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            textTransform: 'none',
            background: '#F7F7F8'
        }
    },
    
    orderTitle: {
        marginBottom: 28,
        fontWeight: 'bold',
        fontSize: 24,
        lineHeight: '24px',
        display: 'flex',
        marginRight: 10,
        alignItems: 'center',
        
        '& a': {
            color: '#009BC3',
        }

    },

    orderPaperBg : {
        '& .MuiPaper-root.MuiCard-root' :{
            background : 'transparent',
            border: 'none'

        }
    },

    lotsPart: {
       '& .MuiCardContent-root': {
           padding: 0
       },
       '& .MuiTypography-body2': {
           fontSize : 16
       },
       "& .MuiToolbar-root": {
            left: 0,
            position: "sticky",
            bottom: 0,
            zIndex: 2,
            width: "calc(100% - 92%)"
        }
    },  
    chip: {
        fontSize: '10px',
        marginLeft: '4px',
        color: 'white',
        backgroundColor: '#009BC3',
        width: '34px',
        height: '16px',
        borderRadius: '12px',
        '& .MuiChip-label': {
            padding: 0
        }
    },
    
});

const OrderHeader = (props) => {
    if (props.record == null)
        return null;
        
    const { orderNumber: number } = props.record;
    if (number == null)
    return null;
    
    return <span>Паспорт аукциона №{number}</span>;
}

const OrderShow = (props) => {
    const classes = useStyles();
    return (
        <Show className={classes.orderPaperBg}  title={<OrderHeader />} {...props} actions={<OrderShowActions />}>
            <OrderShowInner />
        </Show>
    );
};

const OrderTitle = (props) => {
    const classes = useStyles();

    if (props.record == null)
        return null;

    const { orderNumber: number, oosUrl: url, hasAdditionalLots } = props.record;
    if (number == null)
        return null;

    
    return <div className={classes.orderTitle}>
                    Аукцион&nbsp;<a rel="noopener noreferrer" target="_blank" href={url}>№{number}</a>
                    {hasAdditionalLots ? <Chip className={classes.chip}  label="ADD!" /> : null}
            </div>;
}



const OrderShowInner = (props) => {
    const classes = useStyles();
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                <OrderTitle {...props} />
            </Typography>     
            <OrderPart {...props} />           
            {/* <Divider /> */}
            <div className={classes.lotsPart}>
                <LotsPart {...props} />
            </div>
        </div>
    );
};

const OrderShowActions = (props) => {
    const classes = useStyles();
    return (
        <TopToolbar className={classes.Buttons}>
            <ListButton icon={<ArrowBackRoundedIcon />} label='Вернуться к списку аукционов' className={classes.listBtn} />
            <HistoryButton buttonText="История аукциона" dialogTitle="История аукциона" parentEntityKind={0}  {...props} />
            <PricesApprovalsHistoryButton  {...props} />
        </TopToolbar>
    );
};

export default OrderShow;
