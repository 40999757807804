
export default {
    lotPercentageInOrder: "( Стоимость НМЦ SKU, руб. с НДС / Сумма НМЦ всего аукциона ) * 100%",
    totalUnitQuantity: "( Кол-во упаковок * мг/мл в ИАС * кол-во упак в ИАС ) / ( мг/мл в SKU * кол-во в 1 упак SKU )",
    startingMinimalUnitPrice: "Стоимость НМЦ SKU, руб. с НДС / Кол-во уп. (уже с учетом всех пересчетов)",
    totalDrugVolume: "( Кол-во уп. (уже с учетом всех пересчетов) * Объем (м3) ) / Кол-во уп.",
    logisticsAmount: "Объем препаратов, м3 (по каждой SKU) * Стоимость логистики в регион 1 м3",
    maxPriceForRegion: "( цена ЖНВЛП, руб. без НДС * % региональной надбавки того региона в котором аукцион опубликован и в зависимости от диапазона цен ЖВНЛП ) * НДС 10%.",
    startingMinimalSkuPrice: "Кол-во уп. (уже с учетом всех пересчетов) * Плановая цена продажи (бюджетного прайса)",
    grossMargin: "( КП 1, руб. с НДС - Закупочная цена, руб. с НДС (после вычета КН)) * Кол-во уп. (уже с учетом всех пересчетов)",
    marginalityLot: "( Валовая маржа_1, руб. с НДС / ( Кол-во уп. (уже с учетом всех пересчетов) * КП 1, руб. с НДС) ) * 100%",
    logisticsRatioFromCommercialOfferPrices: "( Сумма логистики, руб. с НДС / Сумма в ценах КП1, руб. с НДС ) * 100%",
    marginRatio: "Две формулы через дробь ( ЦН, руб. с НДС – ( Закупочная цена, руб. с НДС (после вычета КН) )/( ЦН, руб. с НДС * Кол-во уп. (уже с учетом всех пересчетов) )*100% ( ЦК, руб. с НДС – ЦН, руб. с НДС ) / (ЦК, руб. с НДС * Кол-во уп. (уже с учетом всех пересчетов) )*100%",
    actualLotPriceSum: "∑ НМЦ всех SKU GPHC, которые включены в аукцион",
    actualLotPriceSumPercentage: "( Сумма НМЦ позиций GPHC, руб. с НДС / НМЦ всего аукциона *100% ",
    totalPriceFromPriceList: "∑ ( Кол-во уп. (уже с учетом всех пересчетов) )* Цена прайса, (руб. с НДС)",
    marginAmount: "∑ ( КП 1, руб. с НДС - Закупочная цена, руб. с НДС (после вычета КН) ) * Кол-во уп. (уже с учетом всех пересчетов)",
    totalPriceFromCommercialOffer: "∑ ( Кол-во уп. (уже с учетом всех пересчетов) )* КП 1, руб. с НДС",
    marginalityOrder: "( Сумма маржи, руб. с НДС / Сумма в ценах КП1, руб. с НДС ) * 100%",
}