import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { List as RaList } from "react-admin";
import { connect } from 'react-redux';

const useStyles = makeStyles({
    content: ({ isToggledOn, filtersBlockHeight }: any) => ({
          // 300px - минус высота тулбара дважды, чтобы компенсировать высоту сверху и снизу
          height: `calc(100vh - 124px - ${filtersBlockHeight ?? 64}px)`,
          minHeight: '300px',
          overflow: 'auto'
    }),
  });
  
  const List = props => {
      const { toggler, pathname, filtersHeight, classes } = props;
      const classesContent = useStyles({ isToggledOn: toggler.isToggled[pathname], filtersBlockHeight: filtersHeight[pathname] });
    return (
        <RaList {...props} classes={{ content: classesContent.content, ...classes }} />
    );
}

function mapStateToProps(state) {
    const { toggler, filtersHeight, router } = state;
    // pathname === '/myPath' --> 'myPath'
    return { toggler: toggler, filtersHeight: filtersHeight, pathname: router.location.pathname.split('/')[1] }
}

export default connect(mapStateToProps)(List);