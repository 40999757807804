import React from 'react'
import { Pagination, PaginationActions as RaPaginationActions } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        // set z-index: 2 because table thead tr th has z-index: 2 too
        '& .MuiToolbar-root': {
            left: '0',
            bottom: '0', 
            position: 'sticky', 
            backgroundColor: 'white',
            zIndex: '2' 
        },
    }
}));

const PaginationActions = props => <RaPaginationActions {...props} color="secondary" />;

export default (props) => { 
  const classes = useStyles()
  return <Pagination className={classes.root} 
            // style={{ left: '0', bottom: '0', position: 'sticky', backgroundColor: 'white', zIndex: '2' }} 
            rowsPerPageOptions={[5, 10, 25, 50]} 
            ActionsComponent={props.actions ? props.actions : PaginationActions}  {...props} />;
}