import React from "react"
import { SelectInput, SaveButton, Toolbar, SimpleForm, useRefresh, useNotify, LinearProgress } from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, CircularProgress } from '@material-ui/core';
import { config } from '../../configuration';
import api from "../../dataProvider/api";

const useStyles = makeStyles({
    flexElem: { display: 'flex' },
    simpleform: { width: '100%' },
    gridContainer: { 
        width: 'auto',
        "& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3": {
            display: 'flex', 
            justifyContent: 'center'
        } 
    },
    tollBar: { display: 'flex', margin: 'auto', justifyContent: 'space-around' }
});


const ExpandShow = (props) =>{
    const refresh = useRefresh();
    const notify = useNotify();
    const {basePath, record, data} = props;

    const handleSubmit = async event => {
        const result = await api.bulkRegionsMapToArea([event.id], event.areaId);
        checkResult(result);
    };

    const checkResult = (result: any) => {
        if (result.succeeded) {
            refresh();
            notify("Регион сопоставлен");
        } else notify("Ошибка: не удалось сопоставить регион", "warning");
    };

    const classes = useStyles();
 
    return(
        <Paper >
            <SimpleForm basePath={basePath} toolbar={<CustomToolbar />} save={handleSubmit} record={record}>
                <Grid container justify="center" className={classes.gridContainer}>
                    <Grid item xs={3}>
                        {data ? <CompareComp props={props} data={data} /> : <CircularProgress />}
                    </Grid>
                </Grid>
            </SimpleForm>
        </Paper>
    );
}

const CompareComp = ({record, data} :any) => {
    const classes = useStyles();

    return(
        <div>
            <SelectInput
                allowEmpty
                label="Зона ответсвенности"
                className={classes.simpleform}
                optionValue="id"
                source="areaId"
                choices={
                    data?.map(elm => {
                        return {
                            id: elm['id'], 
                            name: elm['name'] 
                        } 
                    })} />
        </div>
    );
}

const CustomToolbar = (props, basePath) => {
    const classes = useStyles();
    return(
        <Toolbar {...props} className={classes.tollBar}>
            <SaveButton label="Сопоставить"/>
        </Toolbar>
    );
};

export default ExpandShow;