import { Button } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import * as React from "react";
import {
    BooleanField, BooleanInput,
    Create, Datagrid, DateField,
    ReferenceField, ReferenceInput,
    ReferenceManyField, SimpleForm,
    SimpleShowLayout, TextField,
    useNotify, ChipField,
    useRefresh, useShowController,
    useUnselectAll, ReferenceArrayField, SingleFieldList
} from "react-admin";
import { IsActualStatusChoices, YesOrNoChoices } from "../../common/choices";
import { useGridFixedColumnStyles } from "../../common/helpers";
import useAdditionalFilterValues from "../../common/useAdditionalFilterValues";
import Pagination from "../../components/DefaultPagination";
import AutocompleteInputSingle from "../../components/GphcUIKit/AutocompleteInputSingle";
import Filter from "../../components/GphcUIKit/Filter";
import List from "../../components/List";
import { AListActions } from '../../components/ListActions';
import ListWithSideBar from "../../components/ListWithSideBar";
import { entities } from "../../configuration";
import api from "../../dataProvider/api";
import { FactoryComponents } from '../../common/enums';
import ChipListField from "../../components/GphcUIKit/ChipListField";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 92,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    }
}));

const AShow = ({ ...props }) => {
    const classes = useStyles();
    const controllerProps = useShowController(props);

    if (!controllerProps.record && controllerProps.loading && !controllerProps.loaded) {
        return null;
    }

    return (
        <div className={classes.root}>
        </div>
        //     <div className={classes.title}>
        //         <Typography variant="h6">
        //             История
        //         </Typography>
        //         <IconButton onClick={props.onCancel}>
        //             <CloseIcon />
        //         </IconButton>
        //     </div>
        //     <SimpleShowLayout
        //         className={classes.form}
        //         basePath={controllerProps.basePath}
        //         record={controllerProps.record}
        //     >
        //         <ReferenceManyField
        //             perPage={10}
        //             pagination={<Pagination />}
        //             reference={entities.innHistoryPeriods}
        //             label=""
        //             source="innHistoryPeriodId"
        //             //@ts-ignore
        //             filter={{ mainInnId: controllerProps.record.id }}
        //             target=""
        //             sort={{ field: 'validFrom', order: 'ASC' }}
        //         >
        //             <Datagrid >
        //                 <DateField source="validFrom" label="Дата загрузки" showTime />
        //             </Datagrid>
        //         </ReferenceManyField>
        //     </SimpleShowLayout>
        // </div>
    );
};


const InnList = props => {
    const classes = useGridFixedColumnStyles(1);
    const filterValues = useAdditionalFilterValues(api.getInnFilterValues);

    return (
            <List {...props}
                filters={
                    <Filter
                        searchComponentOptions={[
                            { type: FactoryComponents.Search, source: "q", alwaysOn: true }
                        ]}
                        dateComponentsOptions={[
                            { type: FactoryComponents.Date, source: "dateActualTo", label: "Актуальность на", alwaysOn: true },
                        ]}
                        filtersComponentsOptions={[
                            { type: FactoryComponents.Inn, alwaysOn: true, sort:{ field: 'name', order: 'ASC' } },
                            { type: FactoryComponents.Select, source: "isAdditional", label: "Доп. МНН", choices: YesOrNoChoices, alwaysOn: true, isMultiple: false },
                            ...filterValues
                        ]}
                        {...props}
                    />
                }
                filter={{ actual: true }}
                pagination={<Pagination />}
                perPage={25}
                bulkActionButtons={false}
                title={props.options.label}
                actions={<AListActions {...props} />}
            >
                <Datagrid rowClick="show" className={classes.dataGrid}>
                    <TextField source="name" label="МНН" />
                    <BooleanField source="isInnAdditional" label="Дополнительное SKU" />
                    <ReferenceArrayField label="Привязка к основному МНН" reference={entities.inns} source="mainInns">
                        <SingleFieldList>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                </Datagrid>
            </List>
    );
};

export default {
    list: InnList,
};