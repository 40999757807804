import * as React from "react";
import { ReferenceField, SimpleShowLayout, TextField, NumberField} from "react-admin";
import { entities } from "../../../configuration";
import CurrencyField from "../../../components/CurrencyField";

const Regional = (props) =>{

    return(
        <SimpleShowLayout {...props}>
            <ReferenceField source="regionId" label="Регион" reference={entities.regions}>
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="temperatureRegime" label="Температурный режим" />
            <TextField source="boxUnitsCount" label="Кол-во упаковок" />
            <TextField source="boxWeight" label="Вес (кг)" />
            <NumberField source="boxVolume" label="Объем" options={{ maximumFractionDigits: 2 }} />
            <TextField source="maxMarkup" label="Предельная опт. надбавка в регион" />
            <CurrencyField source="logisticsPrice" label="Ст-ть логистики 1 м3 в регион" />
            <TextField source="palletUnitsCount" label="Кол-во упаковок" />
            <TextField source="palletWeight" label="Вес (кг)" />
            <NumberField source="palletVolume" label="Объем" options={{ maximumFractionDigits: 2 }} />
        </SimpleShowLayout>
    );
};

export default Regional;