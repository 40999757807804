import { createMuiTheme } from '@material-ui/core/styles';

import ClarikaOfficeGeo2 from './../fonts/ClarikaOfficeGeo-Rg.woff2';
import ClarikaOfficeGeo from './../fonts/ClarikaOfficeGeo-Rg.woff';
import ClarikaOfficeGeoSVG from '../fonts/ClarikaOfficeGeo-Rg.svg';

const regular = {
  fontFamily: 'Clarika Office Geometric',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${ClarikaOfficeGeo2}) format('woff2')
    url(${ClarikaOfficeGeo}) format('woff'),
    url(${ClarikaOfficeGeoSVG}) format('svg');
    `,
};

const NotListToolBar = {
    "& .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters": {
        alignItems: 'center',
        paddingTop: 0,
        marginBottom: 35,
        "& .MuiButton-root": {
            padding: "8px 20px",
            marginRight: 16,
            whiteSpace: "nowrap"
        },

        '& .MuiButton-contained': {
            color: '#646469',
            borderRadius: '8px',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            textTransform: 'none',
            background: '#F7F7F8'
        },
        '& .MuiButton-contained:hover' : {
            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
        },
        "& .MuiButton-text": {
            color: '#646469',
            borderRadius: '8px',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            textTransform: 'none',
            background: '#F7F7F8'
        },
        '& .MuiButton-text:hover' : {
            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
        },
    }
}

const customTheme = createMuiTheme({
    typography: {
        fontFamily: 
        // [
            'Clarika Office Geometric',
            // 'Arial',
        // ].join(','),
    },
    palette: {
        primary: {
            main : '#009BC3',
            dark : '#009BC3'
        },
        secondary: {
            main: '#F7F7F8',
        },
        
    },
    // :{
    //     maappDrawerrginTop: '200px',
    //     display: 'block',
    // },
    // contentWithSidebar: {
    //     // flexGrow: 1,
    //     marginTop: '200px',
    // },
    sidebar: {
        width: 280, // The default value is 240
        closedWidth: 55, // The default value is 55
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
              '@font-face': [regular],
            },
        },
        MuiTypography: {
            body2: {
                fontSize: '14px',
            }
        },

        RaLinearProgress:{
            root:{
                width:100
            }
        },

        RaLayout: {
            root: {
                backgroundColor: 'white',
                minWidth: 'auto'
            },
            appFrame: {
                marginTop: '92px !important',
                     
            },
            content: {
                //maxWidth: '1200px',
                padding: '0 40px !important',
                marginTop: 30,
                maxWidth: 'calc(100% - 55px)',
                boxSizing:'border-box',
                minWidth: 980

            },
            appFrame: {
                marginTop: '92px !important'
            }

        },
        
        
        MuiAppBar:{
            root: {
                boxShadow:'0px 4px 8px rgba(0, 0, 0, 0.08);',
            },
            colorSecondary : {
                color: '#009BC3',
                backgroundColor: '#fff'
            },
            title : {
            }
        },
        RaMenu: {
            main: {
                marginTop: '0 !important',
            }
        },

        RaPaginationActions:{
            actions:{
                '& button':{
                    color: '#212121;'
                },
            }
        },

        
        
        
        MuiToolbar: {
            regular: {
                //maxWidth: 1200

            },
            dense : {
                minHeight: '92px'
            }
        },
        RaShow: {
            root: NotListToolBar
        },
        RaEdit: {
            root: NotListToolBar
        },
        RaCreate: {
            root: NotListToolBar
        },

        RaTopToolbar: {
            root: {
                alignItems: 'center',
            },

            

        },
        // MuiTable : {
            //     root: {
        //         background: 'red',
        //     },
        
        // }
        RaSidebar: {

            // drawerPaper: {
            //     width: 256
            // },
            // MuiListItem: {
    
            // button: {
            //     '&:hover':{
            //         backgroundColor: 'rgba(0, 155, 195, .12)',
            //     }
            // }
    
        
        },

        MuiMenuItem: {
            root: {
                // backgroundColor: '#f0f'
                fontSize: '16px',
                paddingLeft: '24px',
            }
        },
        RaMenuItemLink: {
            root: {
                color: '#009BC3'
            },
            active: {
                backgroundColor: 'rgba(0, 155, 195, .24)',
                color: '#009BC3'
            },

        },
        MuiPopover: {
            paper: {
                borderRadius: 8,
                boxShadow: '0px 2px 6px rgba(34, 43, 85, 0.06), 0px 10px 16px rgba(52, 53, 70, 0.06)'
            }
        },

        MuiList :{
            padding: {
                padding: '0 !important'
            }
            
        },
        MuiListItem: {
            button: {
                '&:hover':{
                    backgroundColor: 'rgba(0, 155, 195, .12)',
                }
            }
        },

        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
                border: '.5px solid #F7F7F8'

            }
        },
        
        MuiListItemIcon: {
            root: {
                // display: 'none'
                color: '#009BC3'
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#009BC3'
            }
        },
        RaButton: {
            button: {
                background :'rgba(0, 155, 195, 0.12)',
                borderRadius: '8px',
                padding: '8px 24px',
                margin: '0 8px'
            }
        },

        RaListToolbar: {
            toolbar: {
                width: 'calc(100% + 8px)'

            }
        },
        
        RaList: {
            main: {
            },
            content: {
                // overflow: "auto",
                // minHeight: "600px",
                // // минус высота тулбара дважды, чтобы компенсировать высоту сверху и снизу
                // height: "calc(100vh - 388px)"
            },
        },
        MuiTableCell: {
            sizeSmall: {
                fontSize: '14px',
                padding: '9px 16px 11px 16px',
                lineHeight: '100%',
                border: '.5px solid #F7F7F8',
                overflow: "hidden",
                maxWidth: "15em",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
            },
        },
        


        RaDatagrid : {
            headerCell: {
                fontSize: '14px',
                color: '#646469',
                backgroundColor: '#F7F7F8',
                maxWidth: 120,
                fontWeight: 'bold',
                whiteSpace: "normal",
                borderTop: 0
            },

            expandIcon: {
                '& .MuiSvgIcon-root': {
                    
                        // width: '12px',
                    
                }
            }
        },
        MuiFormLabel: {
            root:{
                color: '#646469',
                   
                '&.Mui-focused': {
                    color: '#646469 !important',
                }
            },
        },
        // в фильтрах - этот property меняет underline у DateInputKeyboard компонента
        MuiInput: {
            underline: {
                '&:hover:before': {
                    borderBottom: '2px solid #009BC3 !important'
                },
                '&:before' : {
                    borderBottom: '2px solid #DCDCE1'
                },
                '&:after' : {
                    borderBottom: '2px solid #009BC3'
                },
            }
        },
        // в фильтрах - этот property меняет underline у всех остальных компонентах
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0)',

                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                },

                '&.Mui-focused' : {
                    background: 'none'
                }
            },
            underline: {
                '&:hover:before': {
                    borderBottom: '2px solid #009BC3'
                },
                '&:before' : {
                    borderBottom: '2px solid #DCDCE1'
                },
                '&:after' : {
                    borderBottom: '2px solid #009BC3'
                }
            },
        },
        MuiInputLabel:{
            root: {
                color: '#aaa',
            },
            filled: {
                display: 'none',
            }
            // .MuiInputLabel-shrink.MuiInputLabel-marginDense

        },
        MuiSelect: {
            filled: {
                '&&' : {
                    paddingTop : 7,
                    paddingLeft : 0,
                    paddingRight: 24,
                    background: 'none'
                }
                // -filled.MuiSelect-filled
            },
            icon: {
                top: 'calc(50% - 12px)'
            },
            iconFilled: {
                right: -5
            }
        },

        RaFilterForm: {
            form: {
                // only for first block in filters component (customTopBar)
                '& .filter-field[data-source="q"]': {
                    // background: '#f9f',
                    width: '100%'
                }
            }
        },

        filter : {
            field: {
            }
        },
        RaFilter:{
            form: {
                marginBottom: 7
            }
        },
        
        MuiGrid: {
            container: {
                //maxWidth: 1200
            }
        },
        // orderPaperBg : {
        //     '& .RaShow-card' :{
        //         background : '#f0f',
        //         border: 'none'
    
        //     }
        //     // background : 'none',
        // }

        MuiIconButton: {
            colorPrimary: {
                color: '#009BC3',

                '&.Mui-checked': {
                    color: '#009BC3 !important'
                }
            }
        }
    },

});


export default customTheme