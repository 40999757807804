import * as React from "react";
import { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
    ReferenceManyField, Datagrid, Pagination, TextField, DateField, SelectField,
    fetchStart, fetchEnd, useNotify
} from 'react-admin';
import api from "../../dataProvider/api";
import { entities } from "../../configuration";
import { PurchaseOrderPriceApprovalCommonChoices } from "../../common/choices";
import PriceApprovalResponseShortDescription from "../../components/PriceApprovalResponseShortDescription";

export default (props) => {
    const { data: record, basePath, resource } = props;

    const [dialogOpen, setDialogOpen] = useState(false);
    const closeDialog = () => setDialogOpen(false);
    const openDialog = () => setDialogOpen(true);

    const innerProps = { record, basePath, resource };

    return <React.Fragment>
        <Button onClick={openDialog} variant="contained" color="primary" disableElevation>
            История согласований
        </Button>
        <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="lg" fullWidth={true}>
            <DialogTitle>
                История согласований
            </DialogTitle>
            <DialogContent>
                <ReferenceManyField {...innerProps} perPage={10} pagination={<Pagination />} reference={entities.pricesApprovals} target="orderId" label="История согласований">
                    <Datagrid expand={<ApprovalDetails />}>
                        <DateField showTime label="Создано" source="dateCreated" />
                        <DateField showTime label="Завершено" source="dateCompleted" />
                        <SelectField label="Результат" source="status" choices={PurchaseOrderPriceApprovalCommonChoices} />
                        <TextField label="Менеджер" source="initiator" />
                        <TextField label="Фед. менеджер" source="director" />
                        <TextField label="Обоснование" source="justification" />
                        <TextField label="Ответ" source="response" />
                    </Datagrid>
                </ReferenceManyField>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>;
}

const ApprovalDetails = (props) => {
    const { record: { id } } = props;
    const [model, setModel] = useState<any | null>(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const notify = useNotify();

    useEffect(() => {
        const fetchData = async () => {
            dispatch(fetchStart());
            setLoading(true);
            try {
                const result = await api.getPriceApprovalDescription(id);
                if (result == null || result.succeeded === false) {
                    if (result?.errorMessage != null)
                        notify('Произошла ошибка: ' + result.errorMessage, 'warning');
                    else 
                        notify('Произошла ошибка', 'warning');        
                } else
                    setModel(result);
            }
            finally {
                dispatch(fetchEnd());
                setLoading(false);
            }
        }

        fetchData();
    }, [id]);

    if (loading)
        return <CircularProgress />;

    const title = <React.Fragment>Статус согласования: <br /></React.Fragment>
    return model == null ? null : <PriceApprovalResponseShortDescription title={title} model={model} />;
}
