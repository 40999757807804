import * as React from "react";
import {
    Datagrid, TextField, Toolbar, SaveButton, useRefresh,
    Edit, ReferenceArrayField, SingleFieldList, ChipField,
    SimpleForm, TextInput, required, ReferenceField, useNotify
} from "react-admin";
import AreaShow from './areaShow'; 
import Pagination from "../../components/DefaultPagination";
import {entities} from "../../configuration";
import {AListActions} from '../../components/ListActions';
import List from "../../components/List";

const AreaList = props => (
    <List 
        {...props} 
        sort={{ field: 'name', order: 'ASC' }} 
        perPage={25} pagination={<Pagination />} 
        title={props.options.label} 
        actions={<AListActions {...props}/>}
        bulkActionButtons={false}
    >
        <Datagrid rowClick="show">
            <TextField label="Имя" source="name"/>
            <ReferenceArrayField label="КАМы" reference={entities.users} source="ownerIdArray" sortable={false}>
                <SingleFieldList >
                    <CustomChipField />
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    </List>
);

const CustomChipField = (props) =>{

    let newProps = props;
    if(!props?.record){
        return null;
    }
    newProps.record.newName = `${props?.record?.firstName} ${props?.record?.lastName}`

    return <ChipField source="newName" {...newProps}/>
}


const AreaEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const AreaEdit = (props) => {
    return <Edit {...props}>
        <SimpleForm toolbar={<AreaEditToolbar />}>
            <TextInput label="Имя" source="name" validate={[required()]} />
        </SimpleForm>
    </Edit>
}

export default {
    list: AreaList,
    show: AreaShow,
    edit: AreaEdit
}