import React, { useState, useEffect, useRef } from "react";
import { Filter as FilterCustom } from "react-admin";
import FilterFactory from '../../components/filtersFactory/FiltersFactory';
import { entities } from "../../configuration";
import { isAdmin, Roles } from '../../common/helpers';
// styles
import { makeStyles } from "@material-ui/core/styles";
// redux connect
import { connect } from 'react-redux';
// actionTypes
import { CHANGE_TOGGLER_STATE } from '../../store/modules/toggler/types';
import { CHANGE_FILTERS_HEIGHT_STATE } from '../../store/modules/filtersHeight/types';
import FilterBubbles from '../../components/FilterBubbles';


const useStyles = makeStyles((theme) => ({
    // передаем параметр для отображения
    toggled: isToggledOn => {
        return ({
            display: isToggledOn ? 'flex' : 'none',
            minHeight: 45,
            marginBottom: 15
        })
    },
    
    root: {
        // делаем пэддинг у всех фильтров кроме первого (первым стоит поиск)
        '& .MuiFilledInput-inputMarginDense': {
            paddingTop: '11px'
        },
        // все фильтры кроме DateInputKeyboard
        '& .MuiFormControl-root': {
            width: '200px',
        },
        '& .MuiFormControl-marginDense': {
            marginBottom: 0
        },
        '& .MuiFormHelperText-root.MuiFormHelperText-contained.MuiFormHelperText-marginDense': {
            marginTop: 0
        },
        '& .filter-field': {
            minHeight: 45
        }
    }
}));

const Filter = (props) => {
    const { toggler, changeTogglerState, defaultFilters, pathname, permissions, resources, filterValues, changeFiltersHeightState } = props;
    const classes = useStyles(toggler.isToggled[pathname]);
    const [filterFactory, setFilterFactory] = useState(new FilterFactory());
    const { setFilters } = props;
    const filtersBlockRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        changeFiltersHeightState({ key: pathname, height: filtersBlockRef.current?.clientHeight })
        //@ts-ignore
    }, [filtersBlockRef.current?.clientHeight, toggler.isToggled[pathname]])

    const { searchComponentOptions, dateComponentsOptions, filtersComponentsOptions } = props;

    const toggleComponentOptions = {
        pathname: pathname, isToggled: toggler.isToggled, changeTogglerState: changeTogglerState
    };

    useEffect(() => {
        const localResources = resources[pathname];
        const filters = localResources?.list?.params?.filter;

        if (!setFilters) {
            return;
        } else if (Object.keys(filters).length > 0) {
            setFilters(filters);
        } else if (Object.keys(filterValues).length > 0) {
            setFilters(filterValues);
        } else if (defaultFilters) {
            setFilters(defaultFilters);
        } else {
            setFilters({});
        }
    }, []);

    const customTopBarFilterComponent = searchComponentOptions?.map((filter, i) => filterFactory.create({ key: i, ...filter, permissions: permissions, ...toggleComponentOptions }));

    const dateComponents = dateComponentsOptions?.map((filter, i) => filterFactory.create({ key: i, ...filter, setFilters, permissions: permissions }));

    const filterComponents = filtersComponentsOptions.map((filter, i) => filterFactory.create({ key: i, ...filter, setFilters, permissions: permissions }));

    return (
        <div style={{ width: "100%" }} className={classes.root} ref={filtersBlockRef}>
            <FilterCustom {...props}>
                {customTopBarFilterComponent}
            </FilterCustom>
            {
                dateComponents ? 
                <FilterCustom {...props} className={classes.toggled} style={ { alignItems: 'flex-start' }}>
                    {dateComponents}
                </FilterCustom> : null
            }
            <FilterCustom {...props} className={classes.toggled}>
                {filterComponents}
            </FilterCustom>
            <FilterBubbles {...props} />
        </div>
    );
}

function mapStateToProps(state) {
    const { admin, router, toggler } = state;
    // pathname === '/myPath' --> 'myPath'
    return { resources: admin.resources, pathname: router.location.pathname.split('/')[1], toggler: toggler };
}

function mapDispatchToProps(dispatch) {
    return {
        changeTogglerState: (pathname) => dispatch({ type: CHANGE_TOGGLER_STATE, payload: pathname }),
        changeFiltersHeightState: (payload) => dispatch({ type: CHANGE_FILTERS_HEIGHT_STATE, payload: payload })
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Filter);