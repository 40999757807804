import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BooleanField } from "react-admin";

const useStyles = makeStyles((theme) => {
    return {
        booleanField: {
            // justifyContent: 'center',
            '&:hover .MuiSvgIcon-root': {
                color: 'inherit'
            },
            '& .MuiSvgIcon-root[title="Нет"]': {
                color: '#FE7C8F'
            },
            '& .MuiSvgIcon-root[title="Да"]': {
                color: '#49C088'
            }
        },
    }
});

const StyledBooleanField = (props) => {
    const classes = useStyles();
    return(
        <>
            {/* {props.label} */}
            <BooleanField className={classes.booleanField} {...props}  />
        </>
    )
}

export default StyledBooleanField