import React, {Fragment} from "react";
import { TopToolbar, ExportButton, CreateButton, toggleListItemExpand } from 'react-admin';
// import {hasRole, roles} from "./enums";
import { Button, } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/styles";
import { SecondaryButton } from "./GphcUIKit/Button";

const useStyles = makeStyles({
    root: {
        '& .MuiToolbar-root:nth-child(1)': {
            position: 'relative',
        }
    },
    resetButton: {
        background: 'rgba(0, 155, 195, .12)',
        '&:hover': {
            backgroundColor: 'rgba(0, 155, 195, 0.04)',
            background: 'rgba(0, 155, 195, 0.00)'
        }
    },
})

export const AListActions =
    (props) => {
        let {
            permissions,
            bulkActions,
            basePath,
            currentSort,
            displayedFilters,
            exporter,
            filters,
            filterValues,
            onUnselectItems,
            resource,
            selectedIds,
            showFilter,
            total,
            hasCreate,
            setFilters,
            hideFilter,
            ...rest
       } = props;

       const dispatch = useDispatch();

       const classes = useStyles();
        return(
            // по хорошему надо бы в classes перенести, но у меня чет не выходит
            <TopToolbar style={{ position: 'absolute', top: '0', right: '0' }}>
                <ResetFiltersButton setFilters={setFilters} />
                {bulkActions && React.cloneElement(bulkActions, {
                    basePath,
                    filterValues,
                    resource,
                    selectedIds,
                    onUnselectItems,
                })}
                {hasCreate &&
                    <CreateButton
                        basePath={basePath}
                    />
                }               
                <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    filter={filterValues}
                    sort={currentSort}
                    exporter={exporter}
                    maxResults={10000}
                />
            </TopToolbar>
    );
}


const ResetFiltersButton = (props) => {
    const { setFilters } = props;

    return <SecondaryButton onClick={() => setFilters({}, [])}>Сбросить фильтры</SecondaryButton>;
}
