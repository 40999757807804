import * as React from "react";
import {
    BooleanField,ReferenceField, SimpleShowLayout, 
    TextField, NumberField, SelectField
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { DosageUnitChoices } from "../../../common/choices";
import { entities } from "../../../configuration";



const useStyles = makeStyles((theme) => {
    return {
        booleanField: {
            // justifyContent: 'center',
            '&:hover .MuiSvgIcon-root': {
                color: 'inherit'
            },
            '& .MuiSvgIcon-root[title="Нет"]': {
                color: '#FE7C8F'
            },
            '& .MuiSvgIcon-root[title="Да"]': {
                color: '#49C088'
            }
        },
    }
});

const Info = (props) =>{

    const classes = useStyles();

    return(
        <SimpleShowLayout {...props}>
            <TextField source="key" label="Код SKU" />
            <TextField source="nomenclature" label="Номенклатура" />
            <TextField source="pharmaceuticalGroup" label="Фарм. группа" />
            <ReferenceField source="supplierId" label="Поставщик" reference={entities.suppliers}>
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="dosageVolume" label="Дозировка" />
            <SelectField source="dosageUnit" label="Единицы измерения дозировки" choices={DosageUnitChoices} />
            <NumberField source="quantity" label="Количество в упаковке" />
            <BooleanField className={classes.booleanField} source="isVeDrug" label="Тип ЖНВЛП" />
            <BooleanField className={classes.booleanField} source="isSt1Available" label="СТ-1" />
            <BooleanField className={classes.booleanField} source="isSpAvailable" label="СП" />
        </SimpleShowLayout>
    );
};

export default Info;