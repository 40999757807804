import * as React from 'react';
import { useState } from 'react';
import { SimpleForm, TextInput } from 'react-admin';
import MapBulkButtonLayout from './MapBulkButtonLayout'


const MapBulkWithAddAreaButton = ({selectedIds, handleClick} : MapBulkWithAddAreaButtonProps ) => {
    const [areaName, setAreaName] = useState("");

    const handle = handleClick(selectedIds, areaName);
    const header = "Добавить в новую зону ответственности";
    const cancelButtonText = "Отмена";
    const acceptButtonOptions = { autoFocus: true, disabled: areaName === "" };
    const acceptButtonText = "Сопоставить";
    const dialogContent =  <CustomTextField setAreaName={setAreaName} />;
    const dialogContentText = "Введите название зоны ответственности";
    const dialogTitle = "Добавить в новую зону ответственности";


    return(
        <MapBulkButtonLayout
            handleClick={handle}
            header = {header}
            cancelButtonText={cancelButtonText}
            acceptButtonOptions={acceptButtonOptions}
            acceptButtonText={acceptButtonText}
            dialogContentText={dialogContentText}
            dialogTitle={dialogTitle}
            dialogContent={dialogContent}
        />
    );
}


const CustomTextField = ({setAreaName}) =>{
    return(
    <SimpleForm toolbar={null} basePath="">
        <TextInput 
            source="Название" 
            fullWidth 
            resettable 
            onChange={event => setAreaName(event.target ? event.target.value : "")}/>
    </SimpleForm>
    );
};

type MapBulkWithAddAreaButtonProps = {
    selectedIds : number[], 
    data : object[],
    handleClick : (selectedIds: number[], areaName: string)=> ()=> Promise<void>;
  }

export default MapBulkWithAddAreaButton;